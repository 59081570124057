import { FormikValues } from 'formik/dist/types';
import { ZodSchema } from 'zod';

export type ValidationErrors = Record<
    string,
    string | Record<string, string | Record<any, any>>
>;

/**
 * Utility providing a  bridge between a Zod schema and Formik validate callback.
 * TODO: Investigate migration to zod-formik-adapter: https://www.npmjs.com/package/zod-formik-adapter
 * @param schema
 * @param values The values to validate.
 */
export const ZodValidate = (
    schema: ZodSchema,
    values: FormikValues
): ValidationErrors => {
    // Parse schema.
    const result = schema.safeParse(values);
    const errors = {};
    if (!result.success) {
        const issues = result.error.issues;

        issues.forEach(({ path, message }) => deepSet(errors, path, message));
    }
    return errors;
};

/**
 * Sets `values` on `obj` using a path of keys.
 * @param obj The object to set value on.
 * @param path The path as string[].
 * @param value The value to set.
 */
const deepSet = <T extends object>(obj: T, path: (string | number)[], value: any): T => {
    let current: { [index: string]: any } = obj;

    for (let i = 0; i < path.length - 1; i++) {
        const key = path[i];
        if (!Object.prototype.hasOwnProperty.call(current, key)) {
            current[key] = {};
        }
        current = current[key];
    }

    current[path[path.length - 1]] = value;
    return obj;
};
