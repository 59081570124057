import 'App.css';
import React, { useEffect } from 'react';
import {
    Route,
    createBrowserRouter,
    createRoutesFromElements,
    useLocation,
    useMatches,
    useNavigate,
    useOutlet,
} from 'react-router-dom';
import { ROUTES } from 'routes';
import useSWR from 'swr';

import { OrderContextProvider, useOrderContext } from 'lib/api/OrderContext';
import { StreetContextProvider, useStreetContext } from 'lib/api/StreetContext';
import { UserSettingsContextProvider } from 'lib/api/UserSettingsContext';
import { fetchStreet } from 'lib/api/api';
import { ModalContextProvider, useModalContext } from 'lib/notification/ModalContext';
import { RouteContext } from 'lib/routes/RouteContext';
import { useLanguage } from 'lib/routes/useLanguage';
import { useGtm } from 'lib/utils/useGtm';

import { Layout } from 'components/Layout/Layout/Layout';
import { Modal } from 'components/Modal/Modal';
import { H2 } from 'components/Typography/H2/H2';
import { Span } from 'components/Typography/Span/Span';

import { Content } from './components/Layout/Content/Content';
import { Spinner } from './components/Spinner/Spinner';
import {
    RouteId,
    getFormattedPath,
    getPreviousRoutesById,
    getRouteById,
} from './lib/routes/routes';
import { IStreetData } from './lib/types/api';
import Maintenance from './maintenance';

const AppLayout: React.FC<React.PropsWithChildren> = () => {
    const matches = useMatches();
    useGtm();
    const activeMatch = matches[matches.length - 1];
    const { id } = activeMatch;
    const { context } = getRouteById(id as RouteId);

    return (
        <RouteContext.Provider value={context}>
            <StreetContextProvider>
                <OrderContextProvider>
                    <UserSettingsContextProvider>
                        <ModalContextProvider>
                            <ModalService>
                                <RouteValidator />
                            </ModalService>
                        </ModalContextProvider>
                    </UserSettingsContextProvider>
                </OrderContextProvider>
            </StreetContextProvider>
        </RouteContext.Provider>
    );
};

/**
 * Automatically shows a <Modal/> component when `modalContext` is populated.
 * If `modalContext.type` is set to 503, the maintenance display is shown.
 */
export const ModalService: React.FC<React.PropsWithChildren> = ({ children }) => {
    const [modalContext, setModalContext] = useModalContext();

    // If `modalContext.type` is set to 503, the maintenance display is shown.
    if (modalContext?.type === 503) {
        return <Maintenance />;
    }

    return (
        <>
            {children}
            <Modal
                open={Boolean(modalContext)}
                onClose={() => setModalContext(null)}
                type={modalContext?.type}
            >
                <Content>
                    {modalContext?.title && (
                        <H2
                            align='center'
                            dangerouslySetInnerHTML={{ __html: modalContext?.title }}
                        />
                    )}
                    {modalContext?.body && (
                        <Span
                            align='center'
                            size='md'
                            wrap='pre-line'
                            dangerouslySetInnerHTML={{ __html: modalContext?.body }}
                        />
                    )}
                </Content>
            </Modal>
        </>
    );
};

/**
 * Renders the current route, or the first invalid previous route.
 * Makes sure no ancestor `IRoute.formSchema` can be invalid when visiting an `IRoute`.
 */
export const RouteValidator: React.FC = () => {
    // Generic route variables.
    const location = useLocation();
    const matches = useMatches();
    const activeMatch = matches[matches.length - 1];
    const { id } = activeMatch;
    const language = useLanguage();
    const { context } = getRouteById(id as RouteId);
    const outlet = useOutlet();

    // Context variables.
    const [, setStreetContext] = useStreetContext();
    const [orderContext] = useOrderContext();
    const [, setModalContext] = useModalContext();

    // Variables for handling previous (invalid?) route.
    const previousRoutes = getPreviousRoutesById(id as RouteId);
    const firstInvalidPreviousRoute = previousRoutes.find(
        (r) => r.formSchema?.safeParse(orderContext).success === false
    );
    const navigate = useNavigate();

    // Street variables.
    const { data, error, isLoading } = useSWR<IStreetData>(
        [language, 'default'],
        ([language, street]: [string, string]) => fetchStreet(language, street),
        {
            revalidateOnFocus: false,
        }
    );

    useEffect(() => {
        if (!data) {
            return;
        }
        setStreetContext(data);
    }, [data]);

    useEffect(() => {
        // No message to show, don't show modal.
        if (!error?.message && error?.response.status !== 503) {
            return;
        }
        setModalContext({
            body: error.message,
            type: error?.response.status === 503 ? 503 : 'danger', // 503 triggers maintenance mode display.
        });
    }, [error?.message]);

    // Loading, show spinner.
    if (isLoading) {
        return <Spinner />;
    }

    // 503 service unavailable (maintenance mode), show maintenance template.
    if (error?.response.status === 503) {
        return <Maintenance />;
    }

    // An invalid previous `IRoute` is found, navigate to it.
    if (firstInvalidPreviousRoute) {
        navigate(
            getFormattedPath(firstInvalidPreviousRoute.routeProps.path, { language })
        );
    }

    // Previous `IRoute`s are valid, show current route.
    return (
        <Layout locationKey={location.pathname} context={context}>
            {outlet}
        </Layout>
    );
};

export const router = createBrowserRouter(
    createRoutesFromElements(
        <Route path='/' element={<AppLayout />}>
            {ROUTES.map(({ routeProps }) => (
                <Route key={routeProps.id} {...routeProps} index={false} />
            ))}
        </Route>
    )
);
