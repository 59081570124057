import { FormikValues } from 'formik/dist/types';
import { useNavigate } from 'react-router-dom';
import { z } from 'zod';

import { useStreetContext } from 'lib/api/StreetContext';
import { useLanguage } from 'lib/routes/useLanguage';

import { Form } from '../../components/Form/Form';
import { InputField } from '../../components/Form/InputField/InputField';
import { IOption, SelectField } from '../../components/Form/SelectField/SelectField';
import { ValidationErrors, ZodValidate } from '../../components/Form/utils';
import { Content } from '../../components/Layout/Content/Content';
import { H2 } from '../../components/Typography/H2/H2';
import { HGroup } from '../../components/Typography/HGroup/HGroup';
import { useOrderContext } from '../../lib/api/OrderContext';
import { useZodI18nMap } from '../../lib/i18n/zodI18nMap';
import { getNextPathByRouteById } from '../../lib/routes/routes';
import { useRouteId } from '../../lib/routes/useRouteId';
import { ZSchoolSchema } from '../../lib/types/api';
import styles from './Info.module.css';
import { SchoolSelect } from './components/SchoolSelect/SchoolSelect';
import { handleGtmClickNext } from './datalayer';

// TODO: Translations
export const FORM_SCHEMA = z.object({
    school_details: ZSchoolSchema,
    school_type: z.string().nonempty(),
    school_level: z.string().nonempty(),
    group_name: z.string().nonempty(),
});

export const Info = () => {
    const navigate = useNavigate();
    const routeId = useRouteId();
    const language = useLanguage();
    const [street] = useStreetContext();
    const translations = street?.general_text;
    const schoolTypes = street?.school_types || [];
    const [orderContext, setOrderContext] = useOrderContext();
    useZodI18nMap(language);

    /**
     * Finds the school levels based on the selected school.
     */
    const getSchoolLevels = (): IOption[] => {
        if (!orderContext.school_type) {
            return [];
        }

        const schoolType = street?.school_types.find(
            (s) => s.guid === orderContext.school_type
        );

        if (!schoolType) {
            return [];
        }

        return schoolType.levels.map(({ guid, name }) => ({ label: name, value: guid }));
    };

    /**
     * Gets called when the form is being validated.
     * NOTE: This callback is debounced by the <Form/> component.
     */
    const onFormValidate = (values: FormikValues): ValidationErrors => {
        setOrderContext({ ...orderContext, ...values });
        return ZodValidate(FORM_SCHEMA, values);
    };

    /**
     * Gets called when the form is submitted.
     * @param values
     */
    const onFormSubmit = (values: FormikValues) => {
        setOrderContext({ ...orderContext, ...values });
        navigate(getNextPathByRouteById(routeId, { language }) || '');
        const schoolLevel = getSchoolLevels().find(
            (l) => l.value === values.school_level
        )?.label;
        const schoolType = schoolTypes.find((s) => s.guid === values.school_type)?.name;
        handleGtmClickNext({
            educationName: values.group_name,
            educationLevel: schoolLevel || '',
            educationClass: schoolType || '',
        });
    };

    return (
        <div className={styles.info}>
            <Form
                initialValues={orderContext}
                debounceValidate={true}
                labelSubmit={translations?.next_step || ''}
                validate={onFormValidate}
                onSubmit={onFormSubmit}
            >
                <Content>
                    <HGroup>
                        <H2 align='center'>{translations?.plan_visit}</H2>
                    </HGroup>
                    <SchoolSelect
                        countryOptions={Object.entries(street?.countries || []).map(
                            ([value, label]) => ({ label, value })
                        )}
                        labelAddressStreet={translations?.school_address || ''}
                        labelAddressNumber={translations?.address_number || ''}
                        labelAddressCity={translations?.school_city || ''}
                        labelAddressCountry={translations?.country || ''}
                        labelAddSchool={translations?.add_school || ''}
                        labelBack={translations?.go_back || ''}
                        labelPhone={translations?.phone_number || ''}
                        labelPostalCode={translations?.postal_code || ''}
                        labelSchoolName={translations?.school_name || ''}
                        labelSave={translations?.save_school || ''}
                        labelNoOptions={translations?.no_options || ''}
                        labelClear={translations?.clear || ''}
                        name='school_details'
                    ></SchoolSelect>

                    <SelectField
                        options={schoolTypes.map(({ guid, name }) => ({
                            label: name,
                            value: guid,
                        }))}
                        label={translations?.type_of_education || ''}
                        labelClear={translations?.clear || ''}
                        labelNoOptions={translations?.no_options || ''}
                        name='school_type'
                    />

                    <SelectField
                        disabled={!orderContext.school_type}
                        options={getSchoolLevels()}
                        label={translations?.school_class || ''}
                        labelClear={translations?.clear || ''}
                        labelNoOptions={translations?.no_options || ''}
                        name='school_level'
                    />

                    <InputField
                        label={translations?.group_name || ''}
                        name='group_name'
                    />
                </Content>
            </Form>
        </div>
    );
};
