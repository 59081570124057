import clsx from 'clsx';
import React, { ChangeEvent, useId } from 'react';

import { FormError } from '../FormError/FormError';
import { ILabeledFieldProps } from '../IFormFieldProps';
import styles from './InputField.module.css';

export interface IInputFieldProps
    extends ILabeledFieldProps<string, ChangeEvent<HTMLInputElement>> {
    /** The icon that's displayed on the right side of the field. */
    icon?: React.ReactNode;

    /** Same as `label`, but bold. If both `label` and `labelBold` are set: both are shown separated by a whitespace. (" "). */
    labelBold?: string;

    /** Remaining props get passed to `<input />`. */
    [index: string]: unknown;
}

/**
 * An Input field with a label and an icon.
 */
export const InputField: React.FC<IInputFieldProps> = ({
    disabled,
    errorMessage,
    icon,
    id,
    invalid,
    label,
    labelBold,
    name,
    required,
    value,
    ...props
}) => {
    const fieldId = id || useId();
    const errorId = invalid ? `${fieldId}-errormessage` : undefined;
    const labelId = `${fieldId}-label`;

    return (
        <div className={styles.inputField}>
            <div
                className={clsx(styles.innerContainer, {
                    [styles['innerContainer--disabled']]: disabled,
                })}
            >
                <input
                    className={clsx(styles.input, {
                        [styles['input--invalid']]: invalid,
                    })}
                    disabled={disabled}
                    id={fieldId}
                    name={name}
                    placeholder=' '
                    required={required}
                    value={value}
                    aria-describedby={invalid ? errorId : undefined}
                    aria-invalid={invalid}
                    aria-labelledby={labelId}
                    {...props}
                />
                <label className={clsx(styles.label)} htmlFor={fieldId} id={labelId}>
                    {label && <span>{label}</span>}
                    {label && labelBold && ' '}
                    {labelBold && <strong>{labelBold}</strong>}
                    {required ? '*' : ''}
                </label>
                {icon && <div className={styles.icon}>{icon}</div>}
            </div>
            {invalid && <FormError id={errorId as string}>{errorMessage}</FormError>}
        </div>
    );
};
