import { createStateContext } from 'react-use';

import { TFontSize } from 'components/Icons/FontsizeIcon/FontsizeIcon';

import { withSessionStorage } from './withSessionStorage';

interface IUserSettingsContext {
    fontSize: TFontSize;
}

/**
 * A context that is used to store any settings related to the user
 * And that we want to persist.
 */
const userSettingsContext = createStateContext<IUserSettingsContext>({
    fontSize: 'sm',
});

// Export the user settings context decorated with `withsessionStorage`.
export const [useUserSettingsContext, UserSettingsContextProvider] =
    withSessionStorage<IUserSettingsContext>(userSettingsContext, 'userSettings');
