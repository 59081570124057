import { ExpositionTimeSlotPicker } from 'containers/ExpositionTimeSlotPicker/ExpositionTimeSlotPicker';
import { useFormikContext } from 'formik';
import React, { useEffect, useState } from 'react';

import { useOrderContext } from 'lib/api/OrderContext';
import { useStreetContext } from 'lib/api/StreetContext';
import { fetchSoldoutDates } from 'lib/api/api';
import {
    filterOrderLinesByVenues,
    getExpositionGuidsByOrderLines,
    getVenueByExpositionGuid,
    mapOrderLinesByExposition,
    mergeOrderLine,
} from 'lib/api/context';
import { inputChangeEventFactory } from 'lib/events/eventFactory';
import { formatDate } from 'lib/format/date';
import { useLanguage } from 'lib/routes/useLanguage';
import {
    IIncompleteOrder,
    IIncompleteOrderLine,
    IOrderLine,
    IStreetData,
} from 'lib/types/api';

import { Datepicker } from 'components/Form/Datepicker/Datepicker';
import { IFieldProps } from 'components/Form/IFormFieldProps';
import { IOption } from 'components/Form/SelectField/SelectField';
import { Content } from 'components/Layout/Content/Content';
import { H2 } from 'components/Typography/H2/H2';

import styles from './VenueDateAndTimePicker.module.css';

export interface IVenueDateAndTimePickerProps
    extends IFieldProps<IIncompleteOrderLine[], CustomEvent> {
    /** The form field name. */
    name: string;
}

/**
 * Allows the user to select a date, and then a timeslot for each exposition found in
 * `orderContext.exposition_orderlines`.
 */
export const VenueDateAndTimePicker: React.FC<IVenueDateAndTimePickerProps> = ({
    name,
    onChange,
    value = [],
}) => {
    const [street] = useStreetContext() as [IStreetData, unknown];
    const [orderContext] = useOrderContext();
    const language = useLanguage();

    // The value of contains the selected `IOrderLine[]` as stored in the form context, which at best contains the
    // references to the timeslots, but not the raw date. We need tot obtain the (selected) date elsewhere.
    const { values } = useFormikContext<IIncompleteOrder>();
    const dateString = values.date;
    const selectedDate = dateString ? new Date(dateString) : new Date();

    // We can only fetch sold out dates in the future, so the "from date" for the `fetchSoldoutDates` call is the
    // highest of:
    //
    // - The current date.
    // - The first date of the viewed month (activeStartDateState).
    const firstDayOfSelectedMonthDate = new Date(selectedDate);
    firstDayOfSelectedMonthDate.setDate(1);
    const [activeStartDateState, setActiveStartDateState] = useState<Date>(
        firstDayOfSelectedMonthDate
    );
    const [disabledDatesState, setDisabledDatesState] = useState<Date[]>([]);

    // We only want to see timeslot pickers for venues here.
    const venueOrderLines = filterOrderLinesByVenues(value, street.venues);
    const venueOrderLinesByExposition = mapOrderLinesByExposition(venueOrderLines);

    /**
     * Receive the (future only) sold out dates whenever the value changes.
     */
    useEffect(() => {
        const abortController = new AbortController();
        const currentDate = new Date();
        const fromDate =
            activeStartDateState > currentDate ? activeStartDateState : currentDate;

        fetchSoldoutDates(
            language,
            formatDate(fromDate),
            getExpositionGuidsByOrderLines(value),
            abortController.signal
        )
            .then((disabledDates) => {
                setDisabledDatesState(disabledDates.map((d) => new Date(d)));
            })
            .catch(() => setDisabledDatesState([]));
        return () => abortController.abort();
    }, [activeStartDateState, dateString]);

    /**
     * Gets called when a date is selected.
     * @param e A `CustomEvent`, `target.value` is set to the selected date.
     */
    const onDateChange = (e: CustomEvent<{ value: Date }>) => {
        const orderLines = (orderContext?.exposition_orderlines || []).map((o) => ({
            ...o,
            exposition_period_guid: undefined,
        }));

        const event = inputChangeEventFactory(name, orderLines);
        onChange && onChange(event);

        const event2 = inputChangeEventFactory('date', e.detail.value.toDateString());
        onChange && onChange(event2);
    };

    /**
     * Gets called when a timeslot is selected.
     * @param e A `CustomEvent`, `target.value` is set to the selected date.
     * @param expositionGuid
     * @param label
     */
    const onTimeSlotChange = (
        e: CustomEvent<IOption | null>,
        expositionGuid: string,
        label?: string
    ) => {
        const startTime = label?.split('-')[0];
        const incompleteOrderLine: IIncompleteOrderLine = {
            exposition: expositionGuid,
            exposition_period_guid: e.detail ? e.detail.value : undefined,
            start_time: startTime,
            type: 'venue',
        };

        const newOrderlines = mergeOrderLine(value, incompleteOrderLine, 'exposition');
        const event = inputChangeEventFactory(name, newOrderlines as Record<string, any>);
        onChange && onChange(event);
    };

    return (
        <Content>
            <H2 align='center'>{street.general_text.choose_day}</H2>
            <div className={styles.datePickerContainer}>
                <Datepicker
                    disabledDates={disabledDatesState}
                    label='Choose dates'
                    labelNext='Next'
                    labelPrevious='Previous'
                    name='date'
                    value={selectedDate}
                    onActiveStartDateChange={({ activeStartDate }) =>
                        activeStartDate && setActiveStartDateState(activeStartDate)
                    }
                    onChange={onDateChange}
                />
            </div>
            <H2 align='center'>{street.general_text.available_times}</H2>
            <div className={styles.timePickerContainer}>
                {Array.from(venueOrderLinesByExposition).map(
                    ([expositionGuid, orderLines]) => (
                        <ExpositionTimeSlotPicker
                            key={expositionGuid}
                            label={street.general_text.time_for}
                            labelBold={
                                getVenueByExpositionGuid(street, expositionGuid)?.park
                                    ?.name
                            }
                            date={formatDate(selectedDate)}
                            expositionGuid={expositionGuid}
                            orderLines={orderLines}
                            value={
                                orderLines.find(
                                    (o: IOrderLine | IIncompleteOrderLine) =>
                                        o.exposition_period_guid
                                )?.exposition_period_guid
                            }
                            onChange={(e, label) =>
                                onTimeSlotChange(e, expositionGuid, label)
                            }
                        />
                    )
                )}
            </div>
        </Content>
    );
};
