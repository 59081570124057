import React from 'react';

import { useStreetContext } from 'lib/api/StreetContext';
import { getMediaURL } from 'lib/api/api';
import { eventFactory } from 'lib/events/eventFactory';
import { IArticleOrderLine, ITeachingMaterial } from 'lib/types/api';

import { FlowCard } from 'components/CoverFlow/FlowCard/FlowCard';
import { QuantityField } from 'components/Form/QuantityField/QuantityField';
import { Span } from 'components/Typography/Span/Span';

export interface ILessonFlowCardProps {
    backgroundImage: string;

    teachingMaterial: ITeachingMaterial;

    /** The title is shown in the middle with a slight top offset */
    title: string;

    /** onChange callback. */
    onChange: (e: CustomEvent<IArticleOrderLine>) => void;

    /** String representation of the capacity. */
    capacity?: string;

    /** The levels (VMBO, HAVO etc.) */
    levels?: string[];

    /** The location name is shown under the title in a row */
    locationName?: string;

    /** The value. */
    value?: number;
}

export const TeachingMaterialFlowCard: React.FC<ILessonFlowCardProps> = ({
    backgroundImage,
    teachingMaterial,
    levels,
    locationName,
    capacity,
    title,
    onChange,
    value,
}) => {
    const [street] = useStreetContext();
    const translations = street?.general_text;
    const image = getMediaURL(backgroundImage || '');

    /**
     * Gets called when a quantity is selected.
     * @param e
     */
    const onQuantityFieldChange = (e: CustomEvent<{ name: string; value: number }>) => {
        const articleOrderLine: IArticleOrderLine = {
            amount: e.detail.value,
            article: e.detail.name,
        };
        const event = eventFactory('change', articleOrderLine);
        onChange && onChange(event);
    };

    return (
        <FlowCard
            image={image}
            levels={levels}
            locationName={locationName}
            capacity={capacity}
            title={title}
            footer={
                <Span color='white' size='sm'>
                    <a
                        href={teachingMaterial.description}
                        target='_blank'
                        rel='noreferrer'
                    >
                        {translations?.click_more_info}
                    </a>
                </Span>
            }
        >
            <QuantityField
                contrast={true}
                label={translations?.items}
                labelAdd={translations?.add || ''}
                labelSubtract={translations?.remove || ''}
                name={teachingMaterial?.guid}
                value={value}
                onChange={(e) => onQuantityFieldChange(e)}
            />
        </FlowCard>
    );
};
