import { createStateContext } from 'react-use';

import { IIncompleteOrder } from 'lib/types/api';

import { withSessionStorage } from './withSessionStorage';

const orderContext = createStateContext<IIncompleteOrder>({});

// Export order context decorated with `withsessionStorage`.
export const [useOrderContext, OrderContextProvider] =
    withSessionStorage<IIncompleteOrder>(orderContext, 'orderContext');
