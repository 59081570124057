import React from 'react';

import { InfoButton } from '../../InfoButton/InfoButton';
import { Span } from '../../Typography/Span/Span';
import { Title } from '../../Typography/Title/Title';
import styles from './ProductCard.module.css';

export interface IProductCardProps {
    /** Additional information presentable via a modal. */
    description: string;

    /** Path to an image visualizing the product. */
    image: string;

    /** Aria-label for button without text. */
    labelInfo: string;

    /** Aria-label for button without text. */
    labelClose: string;

    /** Price of the product (in currency set by `currency`). */
    price: number;

    /** Title of the product. */
    title: string;

    /** Currency for the price set by `price`. */
    currency?: string;

    /** Locale, used to format currency. */
    locale?: string;

    /** Quantity fields. */
    children: React.ReactNode;
}

/**
 * A card showing information and quantity selector for a product.
 */
export const ProductCard: React.FC<IProductCardProps> = ({
    children,
    currency = 'EUR',
    description,
    image,
    labelInfo,
    labelClose,
    locale = 'nl-NL',
    price,
    title,
}) => {
    const formattedPrice = new Intl.NumberFormat(locale, {
        style: 'currency',
        currency: currency,
    }).format(price);
    return (
        <div className={styles.productcard}>
            <div className={styles.productcard__body}>
                <div className={styles.productcard__group}>
                    <Title size='small'>{title}</Title>
                    <Span size='md'>{formattedPrice}</Span>
                </div>
                {children}
            </div>

            <header className={styles.productcard__header}>
                <img className={styles.productcard__image} alt={title} src={image} />
                <InfoButton label={labelInfo} labelClose={labelClose}>
                    <Span size='2xl'>{description}</Span>
                </InfoButton>
            </header>
        </div>
    );
};
