import React from 'react';

export interface IPhoneIconProps {
    [key: string]: unknown;
}

export const PhoneIcon: React.FC<IPhoneIconProps> = ({ ...props }) => {
    return (
        <svg
            width='23'
            height='23'
            viewBox='0 0 23 23'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
            {...props}
        >
            <g clipPath='url(#clip0_1927_135)'>
                <path
                    d='M8.30842 11.2139C9.05397 12.7381 10.2895 13.968 11.8171 14.7066C11.9289 14.7596 12.0525 14.7825 12.1758 14.7732C12.2992 14.7639 12.4179 14.7226 12.5205 14.6534L14.7698 13.1535C14.8693 13.0872 14.9837 13.0467 15.1028 13.0358C15.2218 13.0248 15.3418 13.0438 15.4517 13.0909L19.6597 14.8943C19.8026 14.955 19.922 15.0606 19.9997 15.195C20.0775 15.3295 20.1094 15.4855 20.0908 15.6397C19.9578 16.6805 19.45 17.6371 18.6625 18.3304C17.8749 19.0237 16.8617 19.4062 15.8125 19.4062C12.5719 19.4062 9.464 18.1189 7.17254 15.8275C4.88108 13.536 3.59375 10.4281 3.59375 7.1875C3.59381 6.13828 3.9763 5.12505 4.66961 4.33754C5.36293 3.55003 6.31951 3.04223 7.36027 2.90923C7.51445 2.89057 7.67053 2.92252 7.80497 3.00028C7.93942 3.07803 8.04495 3.19737 8.10567 3.34032L9.91068 7.552C9.95737 7.66094 9.97639 7.77973 9.96606 7.8978C9.95572 8.01587 9.91635 8.12956 9.85145 8.22873L8.35677 10.5125C8.28874 10.6153 8.24853 10.7339 8.24005 10.8568C8.23158 10.9797 8.25513 11.1028 8.30842 11.2139V11.2139Z'
                    stroke='black'
                    strokeWidth='1.6'
                    strokeLinecap='round'
                    strokeLinejoin='round'
                />
            </g>
            <defs>
                <clipPath id='clip0_1927_135'>
                    <rect width='23' height='23' fill='white' />
                </clipPath>
            </defs>
        </svg>
    );
};
