import React from 'react';

import { Span } from 'components/Typography/Span/Span';

import styles from './Card.module.css';

export interface ICardProps {
    icon: React.ReactElement;
    boldText?: string;
    text: string;
    onClick?: () => void;
}

export const Card = (props: ICardProps) => {
    const { boldText, text, icon, onClick } = props;
    return (
        <div className={styles.card} onClick={onClick}>
            <div className={styles.card__cardIcon}>{icon}</div>
            <div className={styles.card__cardText}>
                <Span bold size={'xl'}>
                    {boldText}
                </Span>
                <Span size={'xl'}>{text}</Span>
            </div>
        </div>
    );
};
