import { gtmDataLayer } from '../../lib/utils/useGtm';

interface IHandleGtmClickNextProps {
    educationLevel: string;
    educationClass: string;
    educationName: string;
}

export const handleGtmClickNext = ({
    educationLevel,
    educationClass,
    educationName,
}: IHandleGtmClickNextProps) => {
    const dataLayer = {
        event: 'checkout',
        checkout_funnel_name: 'ticketstraat_onderwijs',
        checkout_stage_number: '2',
        checkout_stage_name: 'school',
        education: {
            education_level: educationLevel,
            education_class: educationClass,
            education_name: educationName,
        },
    };
    gtmDataLayer({
        dataLayer,
    });
};
