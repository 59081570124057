import clsx from 'clsx';
import React, { SyntheticEvent, useEffect, useState } from 'react';

import { CloseButton } from '../CloseButton/CloseButton';
import styles from './Modal.module.css';

export interface IModalProps {
    /** Whether the modal is shown. */
    open: boolean;

    /** Whether the modal can be closed. */
    closable?: boolean;

    /** Aria-label for button without text. */
    labelClose?: string;

    /** onClose callback. */
    onClose?: (e: React.SyntheticEvent | KeyboardEvent) => void;

    /** Intent of the modal, changes color. */
    type?: 'info' | 'danger';

    /** Content. */
    children: React.ReactNode;
}

/**
 * Modal (mode/dialog), traps the user interaction (mode) using an overlay while showing a dialog. User interaction is
 * released after close. Children are rendered as dialog content.
 * TODO: Investigate (native) dialog component for full focus trap. Optionally using polyfill if needed.
 */
export const Modal: React.FC<IModalProps> = ({
    closable = true,
    labelClose,
    open,
    onClose = () => undefined,
    type = 'info',
    ...props
}) => {
    const [openState, setOpenState] = useState<boolean>(open);
    useEffect(() => setOpenState(open), [open]);
    useEffect(() => {
        if (openState) {
            document.addEventListener('keyup', close);
        } else {
            document.removeEventListener('keyup', close);
        }
    }, [openState]);

    /**
     * `<CloseButton />` and `div.overlay` click callback.
     * Escape key callback.
     * @param e
     */
    const close = (e: SyntheticEvent | KeyboardEvent) => {
        if (!closable) {
            return;
        }
        if ('key' in e && e.key !== 'Escape') {
            return;
        }
        setOpenState(false);
        onClose(e);
    };

    return (
        <>
            <div
                className={clsx(styles.overlay, { [styles['overlay--open']]: openState })}
                onClick={close}
            ></div>
            <div
                className={clsx(styles.dialog, {
                    [styles['dialog--open']]: openState,
                    [styles[`dialog--type-${type}`]]: type,
                })}
                role='dialog'
            >
                <header className={styles.dialog__header}>
                    {closable && <CloseButton label={labelClose || ''} onClick={close} />}
                </header>
                <div className={styles.dialog__body}>{props.children}</div>
            </div>
        </>
    );
};
