import React from 'react';

import styles from './Close.module.css';

export interface ICloseProps {
    [index: string]: unknown;
}

export const Close: React.FC<ICloseProps> = ({ ...props }) => {
    return (
        <svg
            className={styles.close}
            xmlns='http://www.w3.org/2000/svg'
            viewBox='0 0 20 20'
            {...props}
        >
            <path
                d='M16 4L4 16M16 16L4 4'
                fill='none'
                stroke='currentColor'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
        </svg>
    );
};
