import {
    ArrayHelpers,
    FieldArray,
    FormikValues,
    useField,
    useFormikContext,
} from 'formik';
import { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { z } from 'zod';

import { useOrderContext } from 'lib/api/OrderContext';
import { useStreetContext } from 'lib/api/StreetContext';
import { RequestError, createOrder } from 'lib/api/api';
import { calculateGivenPriceByContext } from 'lib/api/context';
import { replacePlaceholders } from 'lib/format/string';
import { useZodI18nMap } from 'lib/i18n/zodI18nMap';
import { useModalContext } from 'lib/notification/ModalContext';
import { getNextPathByRouteById } from 'lib/routes/routes';
import { useLanguage } from 'lib/routes/useLanguage';
import { useRouteId } from 'lib/routes/useRouteId';
import {
    IContactInformation,
    IIncompleteOrder,
    IOrder,
    IStreetData,
    ZContactInformation,
} from 'lib/types/api';

import { Button } from 'components/Button/Button';
import { Checkbox } from 'components/Form/Checkbox/Checkbox';
import { Form } from 'components/Form/Form';
import { InputField } from 'components/Form/InputField/InputField';
import { SelectField } from 'components/Form/SelectField/SelectField';
import { ValidationErrors, ZodValidate } from 'components/Form/utils';
import { Chevron } from 'components/Icons/Chevron/Chevron';
import { Plus } from 'components/Icons/Plus/Plus';
import { Content } from 'components/Layout/Content/Content';
import { H2 } from 'components/Typography/H2/H2';
import { HGroup } from 'components/Typography/HGroup/HGroup';

import styles from './Contact.module.css';
import { SchoolInfo } from './components/SchoolInfo/SchoolInfo';
import { handleGtmClickReserve, handleGtmOrderCreated } from './datalayer';

export const FORM_SCHEMA = z.intersection(
    ZContactInformation,
    z.object({
        agree_terms_and_conditions: z.boolean(),
    })
);

// Temporarily disabled upon request of the client.
const CHECKBOX_DATA: {
    venueName: string;
    name: string;
}[] = [
    // {
    //     venueName: 'ARTIS',
    //     name: 'artis_newsletter',
    // },
    // {
    //     venueName: 'MICROPIA',
    //     name: 'micropia_newsletter',
    // },
    // {
    //     venueName: 'GROOTE MUSEUM',
    //     name: 'grootemuseum_newsletter',
    // },
];
export const Contact = () => {
    const navigate = useNavigate();
    const routeId = useRouteId();
    const [street] = useStreetContext();
    const language = useLanguage();
    const [orderContext, setOrderContext] = useOrderContext();
    const translations = street?.general_text;
    const [, setModalContext] = useModalContext();
    useZodI18nMap(language);

    /**
     * Gets called when the form is being validated.
     */
    const onFormValidate = (values: IIncompleteOrder): ValidationErrors => {
        // Remove "" additional email values.
        const _values: IIncompleteOrder = {
            ...values,
            additional_emails: values.additional_emails?.filter((e) => e !== ''),
        };

        setOrderContext({ ...orderContext, ..._values });
        return ZodValidate(FORM_SCHEMA, _values);
    };

    /**
     * Gets called when the form is being submitted.
     */
    const onFormSubmit = async (
        values: FormikValues,
        {
            setErrors,
            setSubmitting,
        }: {
            setErrors: (errors: Record<string, string>) => void;
            setSubmitting: (isSubmitting: boolean) => void;
        }
    ) => {
        const newOrderContext = { ...orderContext, ...(values as IContactInformation) };
        setOrderContext(newOrderContext);
        try {
            if (street) {
                handleGtmClickReserve({
                    street,
                    expositionOrderLines:
                        newOrderContext.exposition_orderlines as IOrder['exposition_orderlines'],
                    articleOrderLines:
                        newOrderContext.article_orderlines as IOrder['article_orderlines'],
                    education_newsletter: newOrderContext.education_newsletter,
                    artis_newsletter: newOrderContext.artis_newsletter,
                    micropia_newsletter: newOrderContext.micropia_newsletter,
                    grootemuseum_newsletter: newOrderContext.grootemuseum_newsletter,
                });
            }
            const createdOrder = await createOrder(language, {
                ...values,
                additional_emails: values.additional_emails || [],
                street: street?.guid,
                given_price: calculateGivenPriceByContext(
                    street as IStreetData,
                    orderContext as IOrder
                ),
            } as IOrder).finally(() => setSubmitting(false));
            if (street) {
                handleGtmOrderCreated({ createdOrder, street });
            }
            navigate(
                getNextPathByRouteById(routeId, {
                    language,
                    orderId: createdOrder.rcx_session_id,
                }) || ''
            );
        } catch (e) {
            // If we have a RequestError, try to extract form errors from the response data.
            if (e instanceof RequestError) {
                // If status code is 503, trigger maintenance mode display.
                if (e.response.status === 503) {
                    setModalContext({ title: '', type: 503 });
                    setSubmitting(false);
                    return;
                }

                const fields = Object.keys(orderContext);
                try {
                    setErrors(e.toRecord<string>(fields) as Record<string, string>);
                } catch (e) {
                    // Errors can't be extracted, fail silently.
                }
            }
            setModalContext({
                title: translations?.main_error || '',
                body: (e as Error).message,
                type: 'danger',
            });
            setSubmitting(false);
        }
    };

    const additionalEmailFields =
        orderContext &&
        orderContext.additional_emails &&
        orderContext.additional_emails.length > 0
            ? (orderContext.additional_emails as string[])
            : [];

    return (
        <div className={styles.contact}>
            <Form
                initialValues={orderContext}
                debounceValidate={true}
                labelSubmit={translations?.make_reservations || ''}
                validate={onFormValidate}
                onSubmit={onFormSubmit}
            >
                <Content>
                    <HGroup>
                        <H2 align='center'>{translations?.info_about_you}</H2>
                    </HGroup>
                    <SchoolInfo />
                    <InputField
                        label={translations?.first_name || ''}
                        name='first_name'
                        required
                    />
                    <InputField
                        label={translations?.last_name || ''}
                        name='last_name'
                        required
                    />
                    <InputField label={translations?.email || ''} name='email' required />
                    <div className={styles.additionalEmails}>
                        <FieldArray
                            name='additional_emails'
                            render={(arrayHelpers: ArrayHelpers) => {
                                return (
                                    <ArrayInputField
                                        additionalEmailFields={additionalEmailFields}
                                        arrayHelpers={arrayHelpers}
                                    />
                                );
                            }}
                        />
                    </div>
                    <InputField
                        label={translations?.phone_number || ''}
                        name='phone'
                        required
                    />
                    <SelectField
                        label={translations?.payment_method || ''}
                        name='payment'
                        labelClear={translations?.clear || ''}
                        labelNoOptions={translations?.no_options || ''}
                        options={[
                            { value: 'invoice', label: translations?.invoice || '' },
                            {
                                value: 'pin',
                                label: translations?.pin_at_register || '',
                            },
                        ]}
                        required
                    />
                    <InputField
                        label={translations?.invoice_email || ''}
                        name='invoice_email'
                        required
                    />
                    <InputField
                        label={translations?.other_billing_info || ''}
                        name='other_billing_info'
                    />

                    <Checkbox
                        label={translations?.agree_to_TnC || ''}
                        name='agree_terms_and_conditions'
                        linkHref={`${street?.tnc_link}`}
                        linkText={translations?.view_TnC || ''}
                    />
                    <Checkbox
                        label={translations?.education_newsletter || ''}
                        name='education_newsletter'
                        description={translations?.regular_newsletters}
                    />

                    {CHECKBOX_DATA.map((venue) => {
                        const updateMeText = replacePlaceholders(
                            translations?.update_me || '',
                            [venue.venueName]
                        );
                        return (
                            <Checkbox
                                key={venue.name}
                                label={updateMeText}
                                name={venue.name}
                                description={translations?.regular_newsletters}
                            />
                        );
                    })}
                </Content>
            </Form>
        </div>
    );
};

export interface IArrayInputFieldProps {
    additionalEmailFields: string[];
    arrayHelpers: ArrayHelpers;
}

/**
 * A "wrapped" InputField that is supposed to be used inside the FieldArray component
 * It populates the InputField with the necessary props for invalid/onChange/onBlur..
 * this is needed due to the nature of the FieldArray component making use of `render` prop
 * which means that the useFormikContext() hook cannot be used inside the FieldArray component
 */
export const ArrayInputField: FC<IArrayInputFieldProps> = ({
    additionalEmailFields,
    arrayHelpers,
}) => {
    const [street] = useStreetContext();
    const translations = street?.general_text;
    const { errors } = useFormikContext();

    const [field] = useField({
        name: 'additional_emails',
        value: additionalEmailFields,
    });
    const _errors = (errors as ValidationErrors)?.additional_emails as Record<
        number,
        string
    >;

    return (
        <>
            {additionalEmailFields.map((_, index) => {
                const error = _errors?.[index];
                const invalid = Boolean(error);
                const value = field.value?.[index];
                return (
                    <InputField
                        key={index}
                        errorMessage={error}
                        invalid={invalid}
                        label={translations?.additional_emails || ''}
                        name={`additional_emails.${index}`}
                        onChange={field.onChange}
                        onBlur={field.onBlur}
                        value={value}
                    />
                );
            })}

            <div className={styles.emailButtons}>
                <Button
                    fill={false}
                    fontSize='lg'
                    onClick={() => arrayHelpers.push(undefined)}
                >
                    <Plus />
                    &nbsp;{translations?.additional_emails || ''}
                </Button>
                {additionalEmailFields.length > 0 && (
                    <Button
                        color='danger'
                        fill={false}
                        fontSize='lg'
                        type='reset'
                        onClick={() => arrayHelpers.pop()}
                    >
                        <Chevron direction='left' />
                        &nbsp;
                        {translations?.remove_email || ''}
                    </Button>
                )}
            </div>
        </>
    );
};
