/**
 * Formats a `price` according to the `locale`'s convention.
 * @example `formatPrice(5, 'nl');` // '€ 5,00'
 * @param price
 * @param locale
 * @return {string}
 */
export const formatPrice = (price: number, locale: string): string => {
    const numberFormat = Intl.NumberFormat(locale, {
        style: 'currency',
        currency: 'EUR',
    });
    return numberFormat.format(price);
};
