import React from 'react';

interface IMaintenanceProps {
    title?: string;
    body?: string;
}

/**
 * Maintenance template.
 * @param [title] Override for custom title.
 * @param [body] Override for custom body.
 */
const Maintenance: React.FC<IMaintenanceProps> = ({
    title = 'Op dit moment worden onderhoudswerkzaamheden gepleegd.',
    body = 'Onze excuses voor de overlast. Gelieve het op een later moment weer te proberen.',
}) => {
    const contentStyle: React.CSSProperties = {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        height: '100vh',
        flexDirection: 'column',
        padding: '20px',
        boxSizing: 'border-box',
    };

    const titleStyle: React.CSSProperties = {
        margin: '0',
        borderBottom: '1px dashed #B3B3B3',
        boxSizing: 'border-box',
        clear: 'both',
        fontSize: '36px',
        paddingBottom: '5px',
        wordWrap: 'break-word',
        color: '#059EC2',
        fontStyle: 'normal',
        fontWeight: '300',
        lineHeight: '1.2',
        fontFamily: 'omnes-pro, sans-serif',
        letterSpacing: '-.05px',
        textAlign: 'center',
    };

    const extraStyle: React.CSSProperties = {
        paddingLeft: '0',
        paddingRight: '0',
        margin: '0',
        padding: '0',
        border: '0',
        fontWeight: '300',
        color: '#47535B',
        lineHeight: '1.2',
        fontFamily: 'omnes-pro, sans-serif',
        fontStyle: 'normal',
        letterSpacing: '-.05px',
        fontSize: '17px',
        textAlign: 'center',
    };

    return (
        <div style={contentStyle}>
            <link rel='stylesheet' href='https://use.typekit.net/cuh8jxw.css' />
            <h1 style={titleStyle}>{title}</h1>
            <p style={extraStyle}>{body}</p>
        </div>
    );
};

export default Maintenance;
