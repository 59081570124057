import React, { SyntheticEvent, useEffect, useId, useRef, useState } from 'react';

import { Plus } from '../Icons/Plus/Plus';
import { H3 } from '../Typography/H3/H3';
import { Span } from '../Typography/Span/Span';
import styles from './Faq.module.css';

export interface IFaqItem {
    /** The question. */
    question: string;

    /** The collapsible anwer answer. */
    answer: React.ReactNode;
}

export interface IFaqItemProps extends IFaqItem {
    /** Whether the item should be expanded. */
    active: boolean;

    /** onClick callback. */
    onClick: (e: SyntheticEvent, q: IFaqItem['question'] | null) => void;
}

export interface IFaqProps {
    [index: string]: unknown;

    /** The FaQ Questions */
    faqQuestions: {
        question: string;
        answer: string;
    }[];

    /** FaQ Title label */
    title: string;
}

/**
 * Displays multiple question which can be expanded to show the answer.
 */
export const Faq: React.FC<IFaqProps> = ({ faqQuestions, title, ...props }) => {
    const [activeQuestionState, setActiveQuestionState] = useState<string | null>(
        faqQuestions[0].question
    );
    const renderItems = () =>
        faqQuestions?.map((item) => (
            <FaqItem
                key={item.question}
                active={item.question === activeQuestionState}
                onClick={(e, q) => setActiveQuestionState(q)}
                {...item}
            />
        ));

    return (
        <aside className={styles.faq} {...props}>
            <H3>{title}</H3>
            <ul className={styles.faq__list}>{renderItems()}</ul>
        </aside>
    );
};

/**
 * An item in the Faq list.
 */
const FaqItem: React.FC<IFaqItemProps> = ({ active, answer, onClick, question }) => {
    const answerId = useId();
    const answerRef = useRef<HTMLDivElement>(null);

    /** Updates height to scrollHeight when answer updates or window resizes. */
    useEffect(() => {
        const { current } = answerRef;
        if (!current) {
            return;
        }

        syncHeight(current);

        const callback = syncHeight.bind(null, current);
        window?.addEventListener('resize', callback);
        return window?.removeEventListener('resize', callback);
    }, [answerRef]);

    /** Syncs scrollHeight with height. */
    const syncHeight = (node: HTMLElement) =>
        (node.style.height = `${node.scrollHeight}px`);

    return (
        <li
            key={question}
            className={styles.faq__item}
            aria-controls={answerId}
            aria-expanded={active}
            onClick={(e) => onClick(e, active ? null : question)}
        >
            <Span bold size='md'>
                {question}
                <Plus shape={active ? 'minus' : 'plus'} />
            </Span>
            <div ref={answerRef} id={answerId} className={styles.faq__answer}>
                <Span size='sm'>{answer}</Span>
            </div>
        </li>
    );
};
