import clsx from 'clsx';
import { Formik, useField } from 'formik';
import { FormikValues } from 'formik/dist/types';
import React, { FormEvent, useRef } from 'react';

import { debounce } from 'lib/utils/debounce';

import { Button } from '../Button/Button';
import { Chevron } from '../Icons/Chevron/Chevron';
import { Spinner } from '../Spinner/Spinner';
import styles from './Form.module.css';
import { FormError } from './FormError/FormError';
import { IFieldProps, IFormCapableFieldProps, IFormFieldProps } from './IFormFieldProps';
import { ValidationErrors } from './utils';

export interface IFormProps {
    /** The function that will be called when the form is submitted. The data will be the form state. */
    onSubmit: (values: FormikValues, ...args: any) => void | Promise<unknown>;

    /** The function that will be called when the cancel button is pressed. */
    onCancel?: React.MouseEventHandler<HTMLButtonElement>;

    /** The presentation (display) style of the form, changes the appearance of the submit and cancel button. */
    display?: 'form' | 'control';

    /** Whether to debounce the validate function, known to cause issues when using context in `validate`.. */
    debounceValidate?: boolean;

    /** These values will be filled in the form state when the form is rendered, and can be changed by the user afterward. */
    initialValues?: FormikValues;

    /** The label for the cancel button. */
    labelCancel?: string;

    /** The label for the submit button. */
    labelSubmit?: string;

    /** Whether to show the cancel button (default=false). */
    showCancel?: boolean;

    /** Whether to show the submit button (default=true). */
    showSubmit?: boolean;

    /** Whether to show an error under the button */
    submitErrorLabel?: string;

    /** Validate function. */
    validate?: (values: FormikValues) => ValidationErrors | Promise<ValidationErrors>;

    /** The children of the form. These should be <InputField/>, <SelectField/> etc. components. They will be cloned and the state properties will be injected. */
    children: React.ReactNode;
}

/**
 * A form wrapper that handles the form state and validation automatically for children.
 * (Child) components that implement `name` prop are considered to be form fields and are passed (Formik)
 * `FieldInputProps` automatically, thereby hooking them up to formik.
 */
export const Form: React.FC<IFormProps> = ({
    children,
    debounceValidate,
    display = 'form',
    initialValues = {},
    labelCancel,
    labelSubmit,
    showCancel = false,
    showSubmit = true,
    submitErrorLabel,
    onCancel,
    onSubmit,
    validate,
    ...props
}) => {
    const debouncedValidate = useRef(validate ? debounce(validate, 100) : () => ({}));
    const _validate = debounceValidate ? debouncedValidate.current : validate;

    /**
     * Returns whether `element` has children.
     * @param element
     */
    const hasChildren = (
        element: React.ReactElement<{ [index: string | number | symbol]: any }>
    ): element is React.ReactElement<React.PropsWithChildren> =>
        React.isValidElement(element) && 'children' in element.props;

    /**
     * Returns whether `element` is a form Field (based on `name` prop).
     * @param element
     */
    const isFormField = (
        element: React.ReactElement<{ [index: string | number | symbol]: any }>
    ): element is React.ReactElement<
        IFormCapableFieldProps,
        React.FC<IFormCapableFieldProps>
    > => React.isValidElement(element) && 'name' in element.props;

    /**
     * This is a recursive function that will clone the children of the form and inject the form state properties.
     * This is needed because we'd like to be able to also use other childs that aren't Form Fields like <divs> etc.
     * @param children The children to clone.
     * @param formikProps Formik props.
     * @returns The cloned children with the form state properties injected.
     */
    const renderChildren = (children: React.ReactElement): React.ReactNode => {
        return React.Children.map(children, (child: React.ReactElement) => {
            // Form field.
            if (isFormField(child)) {
                return <FormField child={child}></FormField>;
            }

            // Wrapper.
            else if (hasChildren(child)) {
                const { children, ...props } = child.props;
                return React.cloneElement(child, {
                    ...props,
                    children: renderChildren(children as React.ReactElement),
                });
            }

            // Other element.
            return child;
        });
    };
    return (
        <Formik
            initialValues={initialValues}
            validate={async (values) => _validate && (await _validate(values))}
            onSubmit={onSubmit}
        >
            {({ handleSubmit, isSubmitting, isValid }) => {
                return (
                    <form
                        className={clsx(styles.form, {
                            [styles[`form--display-${display}`]]: display,
                        })}
                        onSubmit={onSubmit}
                        {...props}
                    >
                        {renderChildren(children as React.ReactElement)}

                        <div className={styles.form__actions}>
                            {showCancel && (
                                <Button
                                    color='danger'
                                    disabled={isSubmitting}
                                    fill={false}
                                    fontSize='md'
                                    type='button'
                                    onClick={onCancel as (e: FormEvent) => void}
                                >
                                    {display === 'control' && (
                                        <Chevron direction='left' />
                                    )}
                                    {labelCancel}&nbsp;
                                </Button>
                            )}
                            {showSubmit && (
                                <div className={styles.form__buttonWrapper}>
                                    <Button
                                        disabled={!isValid || isSubmitting}
                                        color={display === 'form' ? undefined : 'primary'}
                                        fill={display === 'form' ? undefined : false}
                                        fontSize='md'
                                        type='submit'
                                        onClick={handleSubmit as (e: FormEvent) => void}
                                    >
                                        {labelSubmit}
                                        {isSubmitting && <Spinner />}
                                        {!isSubmitting && (
                                            <Chevron direction='right'></Chevron>
                                        )}
                                    </Button>
                                    {submitErrorLabel && !isValid && (
                                        <FormError id='submit-error'>
                                            {submitErrorLabel}
                                        </FormError>
                                    )}
                                </div>
                            )}
                        </div>
                    </form>
                );
            }}
        </Formik>
    );
};

/**
 * Wrapped form field with Formik integration.
 */
const FormField: React.FC<{
    child: React.ReactElement<
        IFieldProps & IFormCapableFieldProps,
        React.FC<IFormProps & IFormCapableFieldProps>
    >;
}> = ({ child }) => {
    // Checkboxes need to deal with the "checked" prop.
    const isCheckbox = (child.type.displayName || child.type.name || '').match(
        /checkbox/i
    );
    const [field, { error, touched }] = useField({
        name: child.props.name,
        value: child.props.value,
        type: isCheckbox ? 'checkbox' : undefined,
    });

    const invalid = Array.isArray(error) ? Boolean(error.length) : Boolean(error);
    return React.cloneElement(child, {
        checked: isCheckbox ? field.value : undefined,
        errorMessage: error,
        invalid: touched && invalid,
        ...child.props,
        ...field,
    } as IFormFieldProps);
};
