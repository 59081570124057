import { Confirmation } from 'views/Confirmation/Confirmation';
import { FORM_SCHEMA as CONTACT_FORM_SCHEMA, Contact } from 'views/Contact/Contact';

import { IRoute } from 'lib/routes/routes';

import { Change } from './views/Change/Change';
import { ChangeConfirmation } from './views/ChangeChangeConfirmation/ChangeConfirmation';
import { Home } from './views/Home/Home';
import { FORM_SCHEMA as INFO_FORM_SCHEMA, Info } from './views/Info/Info';
import { FORM_SCHEMA as UPSELL_FORM_SCHEMA, Upsell } from './views/Upsell/Upsell';
import { FORM_SCHEMA as VENUE_FORM_SCHEMA, Venue } from './views/Venue/Venue';

/**
 * The routes (pages).
 * `routeProps` are passed to `<Route />`s as props.
 * `context` is made available as `RouteContext` by `<Layout />`.
 */
export const ROUTES: IRoute[] = [
    {
        routeProps: {
            id: 'home',
            path: '/:language?/',
            element: <Home />,
        },
        context: {
            showInfo: false,
        },
        next: 'info',
    },
    {
        routeProps: {
            id: 'info',
            path: '/:language?/school',
            element: <Info />,
        },
        context: {
            showInfo: true,
            step: 'School',
        },
        formSchema: INFO_FORM_SCHEMA,
        previous: 'home',
        next: 'venue',
    },
    {
        routeProps: {
            id: 'venue',
            path: '/:language?/visit',
            element: <Venue />,
        },
        context: {
            showInfo: true,
            step: 'Visit',
        },
        formSchema: VENUE_FORM_SCHEMA,
        previous: 'info',
        next: 'upsell',
    },
    {
        routeProps: {
            id: 'upsell',
            path: '/:language?/lessons',
            element: <Upsell />,
        },
        context: {
            showInfo: true,
            step: 'Visit',
        },
        formSchema: UPSELL_FORM_SCHEMA,
        previous: 'venue',
        next: 'contact',
    },
    {
        routeProps: {
            id: 'contact',
            path: '/:language?/info',
            element: <Contact />,
        },
        context: {
            showInfo: true,
            step: 'Info',
        },
        formSchema: CONTACT_FORM_SCHEMA,
        previous: 'upsell',
        next: 'confirmation',
    },
    {
        routeProps: {
            id: 'confirmation',
            path: '/:language?/orders/:orderId/confirmed',
            element: <Confirmation />,
        },
        context: {
            showInfo: false,
            stepIcon: 'success',
            stepTranslationKey: 'successful_purchase',
        },
    },
    {
        routeProps: {
            id: 'change_order',
            path: '/:language?/orders/:orderId/change',
            element: <Change />,
        },
        context: {
            showInfo: true,
            stepTranslationKey: 'change_booking',
        },
        next: 'change_order_confirmation',
    },
    {
        routeProps: {
            id: 'change_order_confirmation',
            path: '/:language?/orders/:orderId/changed',
            element: <ChangeConfirmation />,
        },
        context: {
            showInfo: false,
        },
    },
];
