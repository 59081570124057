import clsx from 'clsx';
import React, { useEffect, useId, useState } from 'react';

import { inputChangeEventFactory } from 'lib/events/eventFactory';
import { clamp } from 'lib/format/number';

import { Button } from 'components/Button/Button';
import { Span } from 'components/Typography/Span/Span';

import { FormError } from '../FormError/FormError';
import { IOptionallyLabeledFieldProps } from '../IFormFieldProps';
import { InputField } from '../InputField/InputField';
import styles from './QuantityField.module.css';

export interface IQuantityFieldProps
    extends IOptionallyLabeledFieldProps<
        number,
        CustomEvent<{ name: string; value: number }>
    > {
    /** Aria-label for button without text. */
    labelAdd: string;

    /** Aria-label for button without text. */
    labelSubtract: string;

    /** The background color */
    fill?: false | 'white';

    /** The maximum value. */
    max?: number;

    /** The minimum value. */
    min?: number;

    /** Whether to use a contrast color (white) instead of default (black). */
    contrast?: boolean;
}

export const QuantityField: React.FC<IQuantityFieldProps> = ({
    contrast,
    disabled,
    errorMessage,
    fill = false,
    id,
    invalid,
    label,
    labelAdd,
    labelSubtract,
    max,
    min = 0,
    name,
    onChange,
    required,
    value,
    ...props
}) => {
    const fieldId = id || useId();
    const errorId = invalid ? `${fieldId}-errormessage` : undefined;
    const labelId = `${fieldId}-label`;

    const [valueState, setValueState] = useState<number | null>(0);

    useEffect(() => setValueState(value || 0), [value]);

    /**
     * Gets called when the value changes.
     * This simply calls the onChange prop with the new value.
     * @param newValue The new value, either a number or a string
     * @event CustomEvent#change Custom change event.
     */
    const onSelectChange = (newValue: number) => {
        if (!disabled) {
            setValueState(newValue);
        }
        const value = disabled ? valueState : newValue;
        const event = inputChangeEventFactory(name || '', value);
        onChange && onChange(event);
    };

    /**
     * Gets called when the input changes.
     * This is used to allow the user to delete the value by entering an empty string (until onBlur is called)
     * @param e The input event.
     */
    const handleInput = (e: React.ChangeEvent<HTMLInputElement>) => {
        const numValue = e.target.value === '' ? 0 : parseInt(e.target.value, 10);

        /* If the value is NaN, we set the value to null, which will in turn allow us to show an empty string to the user */
        if (isNaN(numValue)) {
            setValueState(null);
            return;
        }

        const newValue = clamp(
            numValue,
            min || 0,
            typeof max === 'number' ? max : numValue
        );
        onSelectChange(newValue);
    };

    /**
     * Gets called when the input loses focus.
     * This is used to make sure that the user doesn't leave the field with an invalid value.
     * @param e The input event.
     */
    const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
        const numValue = e.target.value === '' ? 0 : parseInt(e.target.value, 10);

        /* If the value is NaN, we set the value to the last valid value enterred (if any) otherwise to 0 */
        if (isNaN(numValue)) {
            setValueState(value || 0);
            return;
        }
        setValueState(numValue);
    };

    /**
     * Gets called when the add button is clicked.
     * Either increments the value by 1 or does nothing.
     */
    const add = () => {
        const newValue = (valueState || 0) + 1;
        const clampedValue = clamp(
            newValue,
            min || 0,
            typeof max === 'number' ? max : newValue
        );
        onSelectChange(clampedValue);
    };

    /**
     * Gets called when the subtract button is clicked.
     * Either decrements the value by 1 or sets it to 0.
     */
    const subtract = () => {
        const newValue = (valueState || 0) - 1;
        const clampedValue = clamp(
            newValue,
            min || 0,
            typeof max === 'number' ? max : Infinity
        );
        onSelectChange(clampedValue);
    };

    return (
        <div
            className={clsx(styles.quantityField, {
                [styles['quantityField--disabled']]: disabled,
                [styles[`quantityField--fill-${fill}`]]: fill,
                [styles['quantityField--invalid']]: invalid,
            })}
        >
            <div className={styles.innerContainer}>
                <InputField
                    label=''
                    disabled={disabled}
                    max={max}
                    min={min}
                    onInput={handleInput}
                    onBlur={handleBlur}
                    onKeyPress={(e: React.KeyboardEvent<HTMLInputElement>) => {
                        // Block any non-numeric key press before we call any other event handlers
                        if (!/^[0-9]+$/.test(e.key)) {
                            e.preventDefault();
                        }
                    }}
                    name={name}
                    type='number'
                    value={valueState === null ? '' : valueState.toString()}
                    required={required}
                    aria-describedby={invalid ? errorId : undefined}
                    aria-invalid={invalid}
                    aria-labelledby={labelId}
                    className={clsx(
                        styles.quantityField__input,
                        contrast && styles['quantityField__input--contrast']
                    )}
                    {...props}
                />
                {label && (
                    <label className={styles.quantityField__label} id={labelId}>
                        <Span size='md' color={contrast ? 'white' : 'black'}>
                            {label}
                            {label && required ? '*' : ''}
                        </Span>
                    </label>
                )}

                <div className={styles.quantityField__buttonGroup}>
                    <Button
                        fill={false}
                        onClick={subtract}
                        title={labelSubtract}
                        aria-label={labelSubtract}
                        disabled={disabled}
                    >
                        <img src='/images/minus-icon.svg' alt={labelSubtract} />
                    </Button>

                    <Button
                        fill={false}
                        onClick={add}
                        title={labelAdd}
                        aria-label={labelAdd}
                        disabled={disabled}
                    >
                        <img src='/images/plus-icon.svg' alt={labelAdd} />
                    </Button>
                </div>
            </div>
            {invalid && <FormError id={errorId as string}>{errorMessage}</FormError>}
        </div>
    );
};
