/**
 * Return string with first letter as capital.
 * @param {string} string
 * @return {string}
 */
export const ucfirst = (string: string) => {
    return String(string[0]).toUpperCase() + String(string).slice(1);
};

/**
 * Replaces placeholders '{}' in a template string with values provided.
 * @param template The template string containing placeholders.
 * @param values An array of values to replace the placeholders with.
 * @returns A string with the placeholders replaced.
 */
export const replacePlaceholders = (template: string, values: string[]): string => {
    return values.reduce((result, value) => {
        return result.replace('{}', value);
    }, template);
};
