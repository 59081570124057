import clsx from 'clsx';
import React from 'react';

import styles from './Chevron.module.css';

export interface IChevronProps {
    direction: 'up' | 'down' | 'left' | 'right';
}

export const Chevron: React.FC<IChevronProps> = ({ direction, ...props }) => {
    return (
        <svg
            className={clsx(styles.chevron, styles[`chevron--direction-${direction}`])}
            xmlns='http://www.w3.org/2000/svg'
            viewBox='0 0 20 20'
            {...props}
        >
            <path
                d='M10 4v12l-4.825-4.904 4.825 4.904 4.825-4.904'
                fill='none'
                stroke='currentColor'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeWidth='1.273'
            />
        </svg>
    );
};
