import React from 'react';

import { IStreetData } from '../types/api';

/**
 * Additional contextual information about a route.
 */
export interface IRouteContext {
    /** Whether to show the info box. */
    showInfo: boolean;

    /** If set, consider this route to be a step in the `<ProgressBar />`. */
    step?: string;

    /** If set, use this icon in `<ProgressBar />` if `stepTranslationKey` is used . */
    stepIcon?: 'success';

    /** If set, use this key to obtain a translation, and render it as step in the `<ProgressBar />`. */
    stepTranslationKey?: keyof IStreetData['general_text'];
}

export const RouteContext = React.createContext<IRouteContext>({
    showInfo: false,
});
