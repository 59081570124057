import clsx from 'clsx';
import React from 'react';

import styles from './Content.module.css';

export interface IContentProps {
    children: React.ReactNode;
    className?: string;
    size?: 'full' | 'medium' | 'small';
    valign?: 'start' | 'center' | 'end';
}

/**
 * Generic container.
 */
export const Content: React.FC<IContentProps> = ({
    children,
    className,
    size = 'full',
    valign = 'center',
    ...props
}) => {
    return (
        <div
            className={clsx(styles.content, className, {
                [styles[`content--size-${size}`]]: size,
                [styles[`content--valign-${valign}`]]: valign,
            })}
            role='document'
            {...props}
        >
            {children}
        </div>
    );
};
