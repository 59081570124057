import {
    IArticleOrderLine,
    ICateringArticle,
    IExposition,
    IIncompleteOrder,
    IIncompleteOrderLine,
    ILesson,
    IOrder,
    IOrderLine,
    IPrice,
    ISchoolLevel,
    IStreetData,
    ITeachingMaterial,
    IVenue,
} from 'lib/types/api';

/**
 * Returns the amount of persons planned to visit exposition with guid `expositionGuid`.
 * @param orderLines
 * @param expositionGuid
 * @return {number}
 */
export const getAmountByExpositionGuid = (
    orderLines: Array<IOrderLine | IIncompleteOrderLine>,
    expositionGuid: string
): number => {
    return orderLines
        .filter((o: IOrderLine | IIncompleteOrderLine) => o.exposition === expositionGuid)
        .reduce(
            (acc: number, o: IOrderLine | IIncompleteOrderLine) => acc + (o.amount || 0),
            0
        );
};

/**
 * Returns the amount of persons planned to visit for price group with guid `priceGroupGuid`.
 * @param orderLines
 * @param priceGroupGuid
 * @return {number}
 */
export const getAmountByPriceGroupGuid = (
    orderLines: Array<IOrderLine | IIncompleteOrderLine>,
    priceGroupGuid: IPrice['group_guid']
): number => {
    const orderLine = orderLines.find((o) => o.exposition_price === priceGroupGuid);
    return orderLine?.amount || 0;
};

/**
 * Returns whether catering articles are available for the selected venue(s).
 * @param street
 * @param orderLines
 */
export const areCateringArticlesAvailable = (
    street: IStreetData,
    orderLines: Array<IOrderLine | IIncompleteOrderLine>
) => getVenuesByOrderLines(street, orderLines).find((v) => Boolean(v.has_catering));

/**
 * Returns `ICateringArticle[]` based on `orderLines`.
 * @param street
 * @param orderLines
 */
export const getCateringArticlesByOrderLines = (
    street: IStreetData,
    orderLines: Array<IOrderLine | IIncompleteOrderLine>
): ICateringArticle[] =>
    orderLines
        .map((o) => street.catering_articles.find((c) => c.guid === o.exposition_price))
        .filter((v): v is ICateringArticle => Boolean(v));

/**
 * Returns the `expositionGuids` for every entry in `orderLines`.
 * @param orderLines
 * @return {string[]}
 */
export const getExpositionGuidsByOrderLines = (
    orderLines: Array<IOrderLine | IIncompleteOrderLine>
): string[] =>
    orderLines.map((o) => o.exposition).filter((v): v is string => typeof v === 'string');

/**
 * Returns whether too many lesson `orderLines` are selected.
 * @param orderLines
 * @param lessons
 * @param [limit=1]
 */
export const areTooManyLessonsSelected = (
    orderLines: Array<IOrderLine | IIncompleteOrderLine>,
    lessons: ILesson[],
    limit = 1
): boolean =>
    Array.from(
        new Set( // Unique Set.
            filterOrderLinesByLessons(
                // Only venue `orderLines`.
                orderLines.filter((o) => (o.amount || 0) > 0),
                lessons
            ).map((o) => o.exposition)
        )
    ).length > limit; // Limit.

/**
 * Returns whether a lesson is required and selected.
 * @param street
 * @param orderLines
 */
export const isLessonOrTeachingMaterialRequired = (
    street: IStreetData,
    orderLines: Array<IOrderLine | IIncompleteOrderLine>
) => {
    const venues = getVenuesByOrderLines(street, orderLines);
    const isLessonRequired = venues.some((v) => v.lesson_required);
    const isTeachingMaterialRequired = venues.some((v) => v.teaching_material_required);

    return {
        isLessonRequired,
        isTeachingMaterialRequired,
    };
};

/**
 * Returns each `ILesson` based on `expositionGuid`.
 * @param street
 * @param expositionGuids
 * @return {IVenue[]}
 */
export const getLessonsByExpositionGuids = (
    street: IStreetData,
    expositionGuids: string[]
): ILesson[] => {
    const lessons = expositionGuids.map((e) => getLessonByExpositionGuid(street, e));
    return Array.from(new Set(lessons.filter((v) => v))) as ILesson[];
};

/**
 * Returns the `ILesson` based on `expositionGuid`.
 * @param street
 * @param expositionGuid
 * @return {IVenue | undefined}
 */
export const getLessonByExpositionGuid = (
    street: IStreetData,
    expositionGuid: string
): ILesson | undefined =>
    street.venues
        .flatMap((v) => v.lessons)
        .find((l) => expositionGuid && l.exposition?.guid === expositionGuid);

/**
 * Returns the `ILesson` for every `IVenue` in `street` for every entry in `order.exposition_orderlines`.
 * If `order.school_level` is set. Only returns `ILesson`s with a matching `ISchoolLevel`.
 * @param street
 * @param order
 * @param [limitAmount=true] If true: only return `IVenue`s matching the capacity specified in `orderLines`.
 * @return {ILesson[]}
 */
export const getLessonsByContext = (
    street: IStreetData,
    order: IIncompleteOrder,
    limitAmount = true
): ILesson[] => {
    const lessons = getLessonsByVenuesInOrderLines(
        street,
        order.exposition_orderlines || [],
        limitAmount
    );
    const contextSchoolLevel = order.school_level;

    // If `order.school_level` is set. Only returns `ILesson`s with a matching `ISchoolLevel`.
    if (contextSchoolLevel) {
        return lessons.filter((l) => l.levels.find((s) => s.guid === contextSchoolLevel));
    }
    return lessons;
};

export interface ICompiledOrderLine {
    amount: number;
    guid: string;
    name: string;
    price: number;
    total: number;
    description: string;
}

/**
 * Returns the total amount based on the context.
 * @param street
 * @param order
 * @return {number}
 */
export const calculateGivenPriceByContext = (
    street: IStreetData,
    order: IOrder
): number => {
    const compiledOrderLines = compileOrderLines(
        street,
        order.exposition_orderlines || []
    );
    const compiledArticleOrderLines = compileArticleOrderLines(
        street,
        order.article_orderlines
    );

    return [...compiledOrderLines, ...compiledArticleOrderLines].reduce((acc, c) => {
        return acc + c.total;
    }, 0);
};

/**
 * Creates an `ICompiledOrderLine` for each item in `orderLines`.
 * @param street
 * @param orderLines
 * @return {ICompiledOrderLine}
 */
export const compileOrderLines = (
    street: IStreetData,
    orderLines: Array<IOrderLine | IIncompleteOrderLine>
): ICompiledOrderLine[] => orderLines.map((o) => compileOrderLine(street, o));

/**
 * Creates an `ICompiledOrderLine` for `orderLine`.
 * @param street
 * @param orderLine
 * @return {ICompiledOrderLine}
 */
export const compileOrderLine = (
    street: IStreetData,
    orderLine: IOrderLine | IIncompleteOrderLine
): ICompiledOrderLine => {
    const amount = Number(orderLine.amount || 0);
    const guid = orderLine.exposition_price || orderLine.exposition || '';
    const priceGroup = getPriceByGuid(street, orderLine?.exposition_price || '');
    const cateringArticle = street.catering_articles.find(
        (c) => c.guid === orderLine.exposition_price
    );
    const price = priceGroup
        ? Number(priceGroup?.price || 0)
        : cateringArticle
        ? Number(cateringArticle.price || 0)
        : 0;

    const total = amount * price;

    const venue = getVenueByExpositionGuid(street, orderLine.exposition || '');
    const lesson = venue
        ? undefined
        : getLessonByExpositionGuid(street, orderLine.exposition || '');
    const catering =
        venue || lesson
            ? undefined
            : street.catering_articles.find((c) => c.guid === orderLine.exposition_price);

    const name =
        venue?.park.name ||
        lesson?.exposition?.name ||
        catering?.title ||
        priceGroup?.group_name ||
        '';
    let description = `${name} x ${amount}`;

    if (orderLine.start_time) {
        description += ` (${orderLine.start_time})`;
    }

    return { amount, guid, name, price, total, description };
};

/**
 * Returns the `IPrice` for a venue or a lesson based on `priceGroupGuid`.
 * @param street
 * @param priceGroupGuid
 */
export const getPriceByGuid = (street: IStreetData, priceGroupGuid: string) => {
    const venuePrices = street.venues
        .filter((v) => v.exposition)
        .flatMap((v) => (v.exposition as IExposition).prices);
    const lessonPrices = street.venues.flatMap((v) =>
        v.lessons.flatMap((l) => l.exposition?.prices).filter((v) => v)
    );
    return [...venuePrices, ...lessonPrices]
        .filter((v): v is IPrice => Boolean(v))
        .find((p) => p.group_guid === priceGroupGuid);
};

/**
 * Creates an `ICompiledOrderLine` for each item in `articleOrderLines`.
 * @param street
 * @param articleOrderLines
 * @return {ICompiledOrderLine}
 */
export const compileArticleOrderLines = (
    street: IStreetData,
    articleOrderLines: IArticleOrderLine[]
): ICompiledOrderLine[] =>
    articleOrderLines.map((o) => compileArticleOrderLine(street, o));

/**
 * Creates an `ICompiledOrderLine` for `articleOrderLine`.
 * @param street
 * @param articleOrderLine
 * @return {ICompiledOrderLine}
 */
export const compileArticleOrderLine = (
    street: IStreetData,
    articleOrderLine: IArticleOrderLine
): ICompiledOrderLine => {
    const teachingMaterials = street.venues.flatMap((v) => v.teaching_materials);
    const teachingMaterial = teachingMaterials.find(
        (t) => t.guid === articleOrderLine.article
    );
    const amount = articleOrderLine.amount;
    const guid = articleOrderLine.article;
    const price = Number(teachingMaterial?.price || 0);
    const total = amount * price;
    const name = teachingMaterial?.title || teachingMaterial?.description || '';
    const description = `${name} x ${amount}`;

    return { amount, guid, name, price, total, description };
};

/**
 * Returns the `ILesson`s referenced in `orderLines`.
 * @param street
 * @param orderLines
 * @param [limitAmount=true] If true: only return `IVenue`s matching the capacity specified in `orderLines`.
 * @return {IVenue[]}
 */
export const getLessonsByOrderLines = (
    street: IStreetData,
    orderLines: Array<IOrderLine | IIncompleteOrderLine>,
    limitAmount = true
): ILesson[] => {
    const expositionGuids = getExpositionGuidsByOrderLines(orderLines);

    // Map trough `expositionGuids`, get amount and only return `venue` if capacity is met.
    if (limitAmount) {
        return Array.from(
            new Set(
                expositionGuids
                    .map((expositionGuid) => {
                        const amount = getAmountByExpositionGuid(
                            orderLines,
                            expositionGuid
                        );
                        const lesson = getLessonByExpositionGuid(street, expositionGuid);
                        if (!lesson) {
                            return undefined;
                        }
                        const limit = Number(lesson.max_students || 0);
                        return amount <= limit ? lesson : null;
                    })
                    .filter((v): v is ILesson => Boolean(v))
            )
        );
    }

    // Call `getLessonsByExpositionGuids` directly.
    return getLessonsByExpositionGuids(street, expositionGuids);
};

/**
 * Returns the `ILesson` for every `IVenue` in `street` for every entry in `orderLines`.
 * @param street
 * @param orderLines
 * @param [limitAmount=true] If true: only return `IVenue`s matching the capacity specified in `orderLines`.
 */
export const getLessonsByVenuesInOrderLines = (
    street: IStreetData,
    orderLines: Array<IOrderLine | IIncompleteOrderLine>,
    limitAmount = true
): ILesson[] => {
    const venues = getVenuesByOrderLines(street, orderLines, limitAmount);
    const lessons = venues.flatMap((v) => v.lessons);
    const entriesById: [string, ILesson][] = lessons
        .filter((l): l is ILesson & { exposition: IExposition } => Boolean(l.exposition))
        .map((l) => [l.exposition.guid, l]);
    const map = new Map<string, ILesson>(entriesById);
    return Array.from(map.values());
};

/**
 * Returns an `IOrder|IIncompleteOrder` based on `order` with both `exposition_orderlines` as `article_orderlines`
 * filtered using `filterOrderLinesWithAmount`.
 * @param order
 * @return {IOrder|IIncompleteOrder}
 */
export const filterOrderLinesWithAmountInOrder = <T extends IOrder | IIncompleteOrder>(
    order: T
): T => ({
    ...order,
    exposition_orderlines: filterOrderLinesWithAmount<IOrderLine | IIncompleteOrderLine>(
        order.exposition_orderlines || []
    ),
    article_orderlines: filterOrderLinesWithAmount<IArticleOrderLine>(
        order.article_orderlines || []
    ),
});

/**
 * Returns the `orderLines` which amount > 0.
 * @param orderLines
 * @return {Array<IOrderLine|IIncompleteOrderLine|IArticleOrderLine>}
 */
export const filterOrderLinesWithAmount = <T extends IOrderLine | IIncompleteOrderLine>(
    orderLines: Array<T>
): Array<T> => orderLines.filter((o) => o?.amount || 0 > 0);

/**
 * Returns the `orderLines` which exposition matches an `exposition.guid` in `lessons`.
 * @param orderLines
 * @param expositionGuid
 * @return {Array<IOrderLine|IIncompleteOrderLine>}
 */
export const filterOrderLinesByExpositionGuid = <
    T extends IOrderLine | IIncompleteOrderLine
>(
    orderLines: Array<T>,
    expositionGuid: string
): Array<T> => orderLines.filter((o) => o.exposition === expositionGuid);

/**
 * Returns the `orderLines` which exposition price matches `expositionPriceGuid`.
 * @param orderLines
 * @param expositionPriceGuid
 * @return {Array<IOrderLine|IIncompleteOrderLine>}
 */
export const filterOrderLinesByExpositionPriceGuid = <
    T extends IOrderLine | IIncompleteOrderLine
>(
    orderLines: Array<T>,
    expositionPriceGuid: string
): Array<T> => orderLines.filter((o) => o.exposition_price === expositionPriceGuid);

/**
 * Returns the `orderLines` which exposition matches an `exposition.guid` in `lessons`.
 * @param orderLines
 * @param lessons
 * @return {Array<IOrderLine|IIncompleteOrderLine>}
 */
export const filterOrderLinesByLessons = (
    orderLines: Array<IOrderLine | IIncompleteOrderLine>,
    lessons: ILesson[]
): Array<IOrderLine | IIncompleteOrderLine> =>
    lessons.flatMap((l) => filterOrderLinesByLesson(orderLines, l));

/**
 * Returns the `orderLines` which exposition matches the `exposition.guid` in `lesson`.
 * @param orderLines
 * @param lesson
 * @return {Array<IOrderLine|IIncompleteOrderLine>}
 */
export const filterOrderLinesByLesson = (
    orderLines: Array<IOrderLine | IIncompleteOrderLine>,
    lesson: ILesson
): Array<IOrderLine | IIncompleteOrderLine> =>
    orderLines.filter(
        (o) => lesson.exposition?.guid && o.exposition === lesson.exposition.guid
    );

/**
 * Returns the `orderLines` which exposition matches an `exposition.guid` in `lessons`.
 * @param orderLines
 * @param venues
 * @return {Array<IOrderLine|IIncompleteOrderLine>}
 */
export const filterOrderLinesByVenues = (
    orderLines: Array<IOrderLine | IIncompleteOrderLine>,
    venues: IVenue[]
): Array<IOrderLine | IIncompleteOrderLine> =>
    venues.flatMap((v) => filterOrderLinesByVenue(orderLines, v));

/**
 * Returns the `orderLines` which exposition matches the `exposition.guid` in `venue`.
 * @param orderLines
 * @param venue
 * @return {Array<IOrderLine|IIncompleteOrderLine>}
 */
export const filterOrderLinesByVenue = (
    orderLines: Array<IOrderLine | IIncompleteOrderLine>,
    venue: IVenue
): Array<IOrderLine | IIncompleteOrderLine> =>
    orderLines.filter((o) => venue.exposition && venue.exposition.guid === o.exposition);

/**
 * Returns the `IArticleOrderLine` which article matches the `teachingMaterialGuid`.
 * @param articleOrderLines
 * @param teachingMaterialGuid
 * @return {Array<IOrderLine|IIncompleteOrderLine>}
 */
export const findArticleOrderLineByTeachingMaterialGuid = (
    articleOrderLines: IArticleOrderLine[],
    teachingMaterialGuid: ITeachingMaterial['guid']
): IArticleOrderLine | undefined =>
    articleOrderLines.find((a) => a.article === teachingMaterialGuid);

/**
 * Returns a map where `orderLines` is subdivided based on the exposition guid.
 * @param orderLines
 * @return {Map<string, Array<IOrderLine | IIncompleteOrderLine>>}
 */
export const mapOrderLinesByExposition = (
    orderLines: Array<IOrderLine | IIncompleteOrderLine>
): Map<string, Array<IOrderLine | IIncompleteOrderLine>> => {
    const orderLinesByExposition = new Map<
        string,
        Array<IOrderLine | IIncompleteOrderLine>
    >();

    for (const orderLine of orderLines.filter(
        (o) => typeof o.amount === 'undefined' || (o.amount as number) > 0
    )) {
        if (!orderLine.exposition) {
            continue;
        }
        const existingLines = orderLinesByExposition.get(orderLine.exposition) ?? [];
        existingLines.push(orderLine);
        orderLinesByExposition.set(orderLine.exposition, existingLines);
    }

    return orderLinesByExposition;
};

/**
 * Merges `orderLines` into `targetOrderLines`. Leaving unrelated order lines untouched.
 * FIXME: Types may be improved.
 * @param targetOrderLines
 * @param orderLines
 * @param matchingKey
 * @return {Array<IOrderLine|IIncompleteOrderLine>}
 */
export const mergeOrderLines = <T = IOrderLine | IIncompleteOrderLine>(
    targetOrderLines: Array<T>,
    orderLines: Partial<T>[],
    matchingKey: keyof T = 'exposition_price' as keyof T
): Array<T> | Array<T | IIncompleteOrderLine> => {
    return orderLines.reduce(
        (mergedOrderLines, currentOrderLine) =>
            mergeOrderLine<IOrderLine | IIncompleteOrderLine>(
                mergedOrderLines,
                currentOrderLine,
                matchingKey as any
            ),
        [...targetOrderLines] as Array<IOrderLine | IIncompleteOrderLine>
    );
};

/**
 * Merges `orderLine` into `targetOrderLines`. Leaving unrelated order lines untouched.
 * FIXME: Types may be improved.
 * @param targetOrderLines
 * @param orderLine
 * @param matchingKey
 * @return {Array<IOrderLine|IIncompleteOrderLine>}
 */
export const mergeOrderLine = <T = IOrderLine | IIncompleteOrderLine>(
    targetOrderLines: Array<T>,
    orderLine: Partial<T>,
    matchingKey: keyof T = 'exposition_price' as keyof T
): Array<T> | Array<T | IIncompleteOrderLine> => {
    // Indicates whether a merge has taken place.
    let merged = false;

    // Merges `orderLine` in `targetOrderLines` (copy) based on `matchingKey`.
    const _targetOrderLines = targetOrderLines.map((targetOrderLine) => {
        if (targetOrderLine[matchingKey] === orderLine[matchingKey]) {
            merged = true; // Signal at least 1 merge.
            return Object.assign({ ...targetOrderLine } || {}, orderLine) as T;
        } else {
            return targetOrderLine;
        }
    });

    // Only append if not merged.
    if (merged) {
        return _targetOrderLines as Array<T>;
    }
    return [...targetOrderLines, orderLine];
};

/**
 * Returns the value for the entry line which `exposition_price` matches `priceGuid`, defaults to 0.
 * @param orderLines
 * @param priceGuid
 * @return {number}
 */
export const getValueByPriceGuid = (
    orderLines: Array<IOrderLine | IIncompleteOrderLine>,
    priceGuid: string
): number => orderLines.find((o) => o.exposition_price === priceGuid)?.amount || 0;

/**
 * Returns the `ITeachingMaterial` for every `IVenue` in `street` for every entry in `order.exposition_orderlines`.
 * If `order.school_level` is set. Only returns `ITeachingMaterial`s with a matching `ISchoolLevel`.
 * @param street
 * @param order
 * @return {ITeachingMaterial[]}
 */
export const getTeachingMaterialsByContext = (
    street: IStreetData,
    order: IIncompleteOrder
): ITeachingMaterial[] => {
    const teachingMaterials = getTeachingMaterialsByVenues(
        getVenuesByOrderLines(street, order.exposition_orderlines || [])
    );
    const contextSchoolLevel = order.school_level;

    // If `order.school_level` is set. Only returns `ILesson`s with a matching `ISchoolLevel`.
    if (contextSchoolLevel) {
        return teachingMaterials.filter((l) =>
            l.levels.find((s) => s.guid === contextSchoolLevel)
        );
    }
    return teachingMaterials;
};

/**
 * Returns `ITeachingMaterial[]` based on `orderLines`.
 * @param street
 * @param articleOrderLines
 */
export const getTeachingMaterialsByArticleOrderLines = (
    street: IStreetData,
    articleOrderLines: IArticleOrderLine[]
): ITeachingMaterial[] => {
    const ids = articleOrderLines.map((a) => a.article);
    return street.venues
        .flatMap((v) => v.teaching_materials)
        .filter((v) => ids.includes(v.guid));
};

/**
 * Returns all the `ITeachingMaterial`s for all `venue`s.
 * @param venues
 * @return {ITeachingMaterial[]}
 */
export const getTeachingMaterialsByVenues = (venues: IVenue[]): ITeachingMaterial[] =>
    Array.from(new Set(venues.flatMap((v) => v.teaching_materials)));

/**
 * Returns whether too many venue `orderLines` are selected.
 * @param orderLines
 * @param venues
 * @param [limit=2]
 */
export const areTooManyVenuesSelected = (
    orderLines: Array<IOrderLine | IIncompleteOrderLine>,
    venues: IVenue[],
    limit = 2
): boolean =>
    Array.from(
        new Set( // Unique Set.
            filterOrderLinesByVenues(
                // Only venue `orderLines`.
                orderLines.filter((o) => (o.amount || 0) > 0),
                venues
            ).map((o) => o.exposition)
        )
    ).length > limit; // Limit.

/**
 * Returns the `IVenue[]` based on `schoolLevelGuid`.
 * @param street
 * @param schoolLevelGuid
 * @return {ILesson[]}
 */
export const getVenuesBySchoolLevelGuid = (
    street: IStreetData,
    schoolLevelGuid: ISchoolLevel['guid']
): IVenue[] =>
    street.venues.filter((v) => v.levels.find((s) => s.guid === schoolLevelGuid));

/**
 * Returns the `IVenue`s referenced in `orderLines`.
 * @param street
 * @param orderLines
 * @param [limitAmount=true] If true: only return `IVenue`s matching the capacity specified in `orderLines`.
 * @return {IVenue[]}
 */
export const getVenuesByOrderLines = (
    street: IStreetData,
    orderLines: Array<IOrderLine | IIncompleteOrderLine>,
    limitAmount = true
): IVenue[] => {
    const expositionGuids = getExpositionGuidsByOrderLines(orderLines);

    // Map trough `expositionGuids`, get amount and only return `venue` if capacity is met.
    if (limitAmount) {
        return Array.from(
            new Set(
                expositionGuids
                    .map((expositionGuid) => {
                        const amount = getAmountByExpositionGuid(
                            orderLines,
                            expositionGuid
                        );
                        const venue = getVenueByExpositionGuid(street, expositionGuid);
                        if (!venue) {
                            return undefined;
                        }
                        const limit = Number(venue.max_students || 0);
                        return amount <= limit ? venue : null;
                    })
                    .filter((v): v is IVenue => Boolean(v))
            )
        );
    }

    // Call `getVenuesByExpositionGuids` directly.
    return getVenuesByExpositionGuids(street, expositionGuids);
};

/**
 * Returns the `IVenue` in `street` for every string in `expositionGuids`.
 * @param street
 * @param expositionGuids
 * @return {IVenue[]}
 */
export const getVenuesByExpositionGuids = (
    street: IStreetData,
    expositionGuids: string[]
): IVenue[] => {
    const venues = expositionGuids.map((e) => getVenueByExpositionGuid(street, e));
    return Array.from(new Set(venues.filter((v) => v))) as IVenue[];
};

/**
 * Returns the `IVenue` in `street` based on `expositionGuid`.
 * @param street
 * @param expositionGuid
 * @return {IVenue | undefined}
 */
export const getVenueByExpositionGuid = (
    street: IStreetData,
    expositionGuid: string
): IVenue | undefined =>
    street.venues.find((v) => v.exposition && v.exposition.guid === expositionGuid);

/**
 * Returns the `IVenue` in `street` based on matching `lesson`.
 * @param street
 * @param lesson
 * @return {IVenue | undefined}
 */
export const getVenueByLesson = (
    street: IStreetData,
    lesson: ILesson
): IVenue | undefined => street.venues.find((v) => v.lessons.includes(lesson));
