import { z } from 'zod';

import { RouteId } from 'lib/routes/routes';

export interface IStreetData {
    background_images: IBackgroundImages;
    catering_articles: ICateringArticle[];
    catering_exposition_id: string;
    countries: Record<string, string>;
    tnc_link: string;
    general_text: {
        // Terminology
        school_singular: string;
        group_singular: string;

        // Multiple pages
        fill_in_required_fields: string;
        add: string;
        menu_go_to: string;
        remove: string;
        clear: string;
        next_step: string;
        no_options: string;
        select_value: string;
        thanks_for_understanding: string;
        loading: string;
        need_help: string;

        // Language Selector
        language_switch: string;

        // Home Page
        home_title: string;
        home_subtitle: string;
        new_booking: string;
        change_booking: string;
        approx_time: string;

        // Plan a School Visit Page
        plan_visit: string;
        add_school: string;
        postal_code: string;
        school_name: string;
        school_address: string;
        address_number: string;
        school_city: string;
        country: string;
        phone_number: string;
        save_school: string;
        go_back: string;
        go_back_to_homepage: string;
        type_of_education: string;
        school_class: string;
        group_name: string;
        // You can select up to 2 venues
        can_select_up_to: string;
        can_select_up_to_lesson: string;
        //Which venues would you like to visit?
        which_venues: string;
        //Multiple venues are possible
        multiple_venues_possible: string;
        // Check Availability
        check_availability: string;
        // Choose Day
        choose_day: string;
        // Available Times
        available_times: string;
        // Time for
        time_for: string;

        // Upsell
        add_lesson: string;
        exposition_sold_out: string;
        click_more_info: string;
        max_lesson: string;
        choose_time: string;
        additional_teaching_materials: string;
        items: string;
        multiple_possible: string;
        complete_with_food: string;
        pickup_time: string;
        pickup_location: string;

        // Contact
        info_about_you: string;

        first_name: string;
        last_name: string;
        email: string;
        edit: string;
        additional_emails: string;
        other_billing_info: string;
        update_me: string;
        regular_newsletters: string;
        education_newsletter: string;

        remove_email: string;
        invoice_email: string;
        payment_method: string;
        invoice: string;
        pin_at_register: string;
        agree_to_TnC: string;
        terms_and_conditions: string;
        make_reservations: string;
        date: string;
        lessons: string;
        teaching_materials: string;
        food: string;
        total: string;

        thank_you: string;
        tickets_emailed: string;
        download_confirmation: string;
        download_map: string;
        successful_purchase: string;
        faq: string;
        view_TnC: string;

        // Change order
        save_changes: string;
        change_order_policy: string;
        cancel_booking: string;

        // Change order confirmation
        order_changed: string;
        successful_order_change: string;

        // ShowInfo component
        summary: string;

        general_error: string;
        main_error: string;
    };
    venues: IVenue[];
    guid: string;
    school_types: ISchoolType[];
    languages: ILanguage[];
    navigation: INavigationItem[];
    general_faq: {
        questions: {
            question: string;
            answer: string;
        }[];
    };
}

type IBackgroundImages = {
    [P in RouteId]?: string;
};

// IPrice in disguise.
export interface ICateringArticle {
    // Actually price group guid.
    guid: string;
    description: string;
    image: string | null;
    price: string;
    title: string;
}

export interface ILanguage {
    label: string;
    isoCode: string;
    imguri: string;
}

export interface INavigationItem {
    image: string;
    name: string;
    order: number;
    url: string;
}

export interface IPrice {
    group_guid: string;
    price: string;
    is_student: boolean;
    group_name: string;
    description: string;
}

export interface ISchoolType {
    name: string;
    class_label: string;
    guid: string;
    levels: ISchoolLevel[];
}

export interface ISchoolLevel {
    name: string;
    guid: string;
}

export interface IVenue {
    background_image: string;
    exposition: IExposition | null; // In some cases, exposition may not be populated.
    has_catering: boolean;
    lesson_required: boolean;
    teaching_material_required: boolean;
    title: string;
    lessons: ILesson[];
    lessons_more_info: IMoreInfo;
    levels: ISchoolLevel[];
    max_students: string;
    max_students_text: string;
    park: {
        guid: string;
        name: string;
        logo: string;
        image: string;
    };
    slug: string;
    teaching_materials: ITeachingMaterial[];
    teaching_materials_more_info: IMoreInfo;
    visit_duration: string;
}

export interface IExposition {
    description: string; // Typically a more info URL.
    guid: string;
    prices: IPrice[];
    name?: string; // Use only in `ILesson` context.
}

export interface ILesson {
    background_image: string;
    duration: string;
    exposition: IExposition | null;
    location: string;
    levels: ISchoolLevel[];
    max_students: number;
    max_students_text: string;
}

export interface IMoreInfo {
    description: string;
    more: string;
    title: string;
}

export interface ITeachingMaterial {
    description: string;
    guid: string;
    image: string | null;
    levels: ISchoolLevel[];
    price: string;
    title: string;
}

/**
 * An order but with required field potentially not (yet) filled.
 */
export interface IIncompleteOrder<T = IIncompleteOrderLine>
    extends Partial<IContactInformation> {
    /** Fields IN the API */
    article_orderlines?: IArticleOrderLine[];
    street?: IStreetData['guid'];
    exposition_orderlines?: T[];
    given_price?: number;
    school_details?: ISchool;

    /** Fields NOT IN the API */
    date?: string;
    group_name?: string;
    school_level?: string;
    school_type?: string;
    invoice_download?: string;
    rcx_session_id?: string;
}

export type IOrder = Required<IIncompleteOrder<IOrderLine>>;

export interface ICreatedOrder extends IOrder {
    invoice_download: string;
    invoice_email: string;
    rcx_session_id: '30b62a50-a3ff-48f4-bd08-85d2c4c007e6';
    reference: '1103AB-04092023-1';
    street: '5889c611-2b8e-466b-a1d4-9966879ed144';
}

export const ZContactInformation = z.object({
    first_name: z.string().nonempty(),
    last_name: z.string().nonempty(),
    phone: z.string().nonempty(),
    payment: z.enum(['invoice', 'pin']),
    other_billing_info: z.string().optional(),
    email: z.string().email(),
    invoice_email: z.string().email(),
    additional_emails: z.array(z.string().email()).optional(),
    education_newsletter: z.boolean().optional(),
    artis_newsletter: z.boolean().optional(),
    micropia_newsletter: z.boolean().optional(),
    grootemuseum_newsletter: z.boolean().optional(),
});

export type IContactInformation = z.infer<typeof ZContactInformation>;

export const ZSchoolSchema = z.object({
    name: z.string().nonempty(),
    street: z.string().nonempty(),
    number: z.string().nonempty(),
    postal_code: z.string().nonempty(),
    city: z.string(),
    country: z.string(),
    phone: z.string().optional(),
    guid: z.string().optional(),
});

export type ISchool = z.infer<typeof ZSchoolSchema>;

export const ZTimeSlotSchema = z.object({
    data: z.array(
        z.object({
            period_id: z.string(),
            expositionId: z.string(),
            from: z.string(),
            availability: z.number(),
            maxAvailability: z.number(),
        })
    ),
    text: z.string(),
    highest_remaining: z.number(),
    comment: z.string(),
});

export const ZOrderLine = z.object({
    amount: z.number(),
    exposition: z.string().nonempty(),
    exposition_price: z.string().nonempty(),
    exposition_period_guid: z.string().nonempty(),
    start_time: z.string().optional(),
    type: z.enum(['venue', 'lesson', 'catering']).optional(), // DO NOT USE, ONLY FOR DEBUG PURPOSES.
});

export type IOrderLine = z.infer<typeof ZOrderLine>;

export const ZIncompleteOrderLine = z.object(
    Object.fromEntries(
        Object.entries(ZOrderLine.shape).map(([key, value]) => [key, value.optional()])
    )
);

// Don't infer from ZIncompleteOrderLine for increased accuracy.
export type IIncompleteOrderLine = Partial<IOrderLine>;

export const ZVenueOrderLine = z.object({
    ...ZOrderLine.shape,
    type: z.literal('venue'),
});

export const ZArticleOrderLine = z.object({
    amount: z.number(),
    article: z.string().nonempty(),
});

export type IArticleOrderLine = z.infer<typeof ZArticleOrderLine>;

export type ITimeSlot = z.infer<typeof ZTimeSlotSchema>;
