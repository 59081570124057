import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import SimpleBar from 'simplebar-react';
import 'simplebar-react/dist/simplebar.min.css';

import { useStreetContext } from 'lib/api/StreetContext';
import { useLanguage } from 'lib/routes/useLanguage';

import { LanguageSelect } from 'components/LanguageSelect/LanguageSelect';

import { RouteContext } from '../../../lib/routes/RouteContext';
import { getPreviousPathByRouteById } from '../../../lib/routes/routes';
import { useRouteId } from '../../../lib/routes/useRouteId';
import { ROUTES } from '../../../routes';
import { ProgressBar } from '../../ProgressBar/ProgressBar';
import styles from './LeftContainer.module.css';
import './simplebar-overrides.css';

export interface ILeftContainerProps {
    children: React.ReactNode;
}

export const LeftContainer = (props: ILeftContainerProps) => {
    const navigate = useNavigate();
    const routeId = useRouteId();
    const [street] = useStreetContext();
    const translations = street?.general_text;
    const routeContext = useContext(RouteContext);
    const language = useLanguage();
    const stepTranslationKey = routeContext.stepTranslationKey;
    const steps = Array.from(
        new Set(ROUTES.filter((r) => r.context.step).map((r) => r.context.step))
    );
    const step = stepTranslationKey || steps.indexOf(routeContext.step);

    /**
     * Event handler for  `<ProgressBar onGoBack/>`.
     */
    const onGoBack = () => {
        navigate(getPreviousPathByRouteById(routeId, { language }) || '');
    };

    const { children } = props;
    return (
        <div className={styles.leftContainer}>
            <SimpleBar style={{ height: '100%', width: '100%' }}>
                <div className={styles.toolbar}>
                    <LanguageSelect />
                    {step !== -1 && (
                        <ProgressBar
                            step={
                                typeof step === 'number'
                                    ? step + 1
                                    : (translations && translations[step]) || ''
                            }
                            stepIcon={routeContext.stepIcon}
                            steps={steps as string[]}
                            onGoBack={onGoBack}
                        />
                    )}
                </div>
                {children}
            </SimpleBar>
        </div>
    );
};
