import clsx from 'clsx';
import React, { useId, useState } from 'react';

import { Button } from '../Button/Button';
import { ListIcon } from '../Icons/ListIcon/ListIcon';
import { Span } from '../Typography/Span/Span';
import styles from './HamburgerMenu.module.css';

export interface IHamburgerMenuProps {
    title: string;
    [index: string]: unknown;
}

export const HamburgerMenu: React.FC<React.PropsWithChildren<IHamburgerMenuProps>> = ({
    children,
    title,
    ...props
}) => {
    const [isOpen, setIsOpen] = useState(false);
    const dialog = useId();

    const onButtonClick = () => {
        setIsOpen(!isOpen);
    };

    return (
        <nav
            className={clsx(styles.hamburgermenu)}
            aria-controls={dialog}
            aria-expanded={isOpen}
            {...props}
        >
            <Button fill={false} onClick={onButtonClick}>
                <ListIcon animateOnHover={true} />
            </Button>

            <div className={styles.hamburgermenu__dialog} id={dialog}>
                <header className={styles.hamburgermenu__header}>
                    <Span align='center' size='md' color='black'>
                        {title}
                    </Span>
                </header>

                <div className={styles.hamburgermenu__body}>{children}</div>
            </div>
        </nav>
    );
};
