/**
 * "Debounces" `func` to run only once every `delay` milliseconds. Returns a promise for `func`s return value.
 *
 * @example
 *   const add = (one, two) => one + two;
 *
 *   // Usage with React:
 *   let debouncedAdd = useRef(debounce(add, 500));
 *   debouncedAdd.current(1, 2);
 *
 *   // Regular usage:
 *   let debouncedAdd = debounce(add, 500);
 *   debouncedAdd(1, 2);
 *
 * @param func The function to debounce.
 * @param delay The setTimeout delay value.
 * @return A promise for the return value of the debounced function.
 */
export const debounce = <A extends any[], V>(
    func: (...args: A) => V,
    delay: number
): ((...args: A) => Promise<V>) => {
    let timeoutId: NodeJS.Timeout;

    return (...args: A) =>
        new Promise<V>((resolve) => {
            clearTimeout(timeoutId);

            timeoutId = setTimeout(() => {
                const result = func.apply(this, args);
                resolve(result);
            }, delay);
        });
};
