import clsx from 'clsx';
import { FC, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { useStreetContext } from 'lib/api/StreetContext';
import { getMediaURL } from 'lib/api/api';
import { getFormattedPath, getRouteById } from 'lib/routes/routes';
import { useLanguage } from 'lib/routes/useLanguage';
import { useRouteId } from 'lib/routes/useRouteId';

import { Button } from 'components/Button/Button';
import { Close } from 'components/Icons/Close/Close';
import { H3 } from 'components/Typography/H3/H3';
import { Span } from 'components/Typography/Span/Span';

import styles from './LanguageSelect.module.css';

export interface ILanguageSelectProps {
    [x: string]: any;
}

export const LanguageSelect: FC<ILanguageSelectProps> = ({ ...props }) => {
    const navigate = useNavigate();
    const routeId = useRouteId();
    const language = useLanguage();
    const [isSelectOpen, setIsSelectOpen] = useState(false);
    const [streetData] = useStreetContext();
    if (!streetData) {
        return null; // fail silently
    }
    const languages = streetData.languages;
    const { language_switch } = streetData.general_text;

    // Find the active language
    const activeLanguage =
        languages.find((lang) => lang.isoCode === language) || languages[0];

    /**
     * Event handler for `<Button onClick/>`. Sets the active language.
     * @param isoCode - The iso code of the language to set.
     */
    const onLanguageSelect = (isoCode: string) => {
        navigate(
            getFormattedPath(getRouteById(routeId).routeProps.path, { language: isoCode })
        );
        setIsSelectOpen(false);
    };

    return (
        <div
            className={clsx(styles.languageselect, isSelectOpen && styles.open)}
            {...props}
        >
            <div className={styles.icon} onClick={() => setIsSelectOpen(!isSelectOpen)}>
                {isSelectOpen ? (
                    <Close />
                ) : (
                    <img
                        src={getMediaURL(activeLanguage.imguri)}
                        alt={activeLanguage.label}
                    />
                )}
            </div>
            <div className={styles.dropdownContainer}>
                <div className={styles.dropdownHeader}>
                    <H3>{language_switch}</H3>
                </div>
                <div className={styles.dropdownOptions}>
                    {languages &&
                        languages.map((lang) => (
                            <Button
                                key={lang.isoCode}
                                fill={'normal'}
                                onClick={() => {
                                    onLanguageSelect(lang.isoCode);
                                }}
                            >
                                <div className={styles.buttonContent}>
                                    <img
                                        src={getMediaURL(lang.imguri)}
                                        alt='' // This image is purely decorative, we already have the label
                                    />
                                    <Span size='lg'>{lang.label}</Span>
                                </div>
                            </Button>
                        ))}
                </div>
            </div>
        </div>
    );
};
