import clsx from 'clsx';
import React from 'react';

import { Span } from 'components/Typography/Span/Span';

import styles from './CardInformationItem.module.css';

export interface ICardInformationItemProps {
    iconUrl: string;
    text: string;
    contrast?: boolean;
}

export const CardInformationItem: React.FC<ICardInformationItemProps> = ({
    contrast,
    iconUrl,
    text,
}) => {
    return (
        <span
            className={clsx(styles.cardinformationitem, {
                [styles['cardinformationitem--contrast']]: contrast,
            })}
        >
            <img src={iconUrl} className={styles.textIcon} />
            <Span size='sm' color={contrast ? 'black' : 'white'} bold>
                {text}
            </Span>
        </span>
    );
};
