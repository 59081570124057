import clsx from 'clsx';
import React from 'react';

import styles from './Plus.module.css';

export interface IPlusProps {
    shape?: 'plus' | 'minus';
}

export const Plus: React.FC<IPlusProps> = ({ shape = 'plus', ...props }) => {
    return (
        <svg
            className={clsx(styles.plus, styles[`plus--shape-${shape}`])}
            xmlns='http://www.w3.org/2000/svg'
            viewBox='0 0 20 20'
            {...props}
        >
            <g stroke='currentColor' strokeLinecap='round' strokeLinejoin='round'>
                <path className={styles.plus__vertical} d='m10 4v12' />
                <path className={styles.plus__horizontal} d='m4 9.99 12 0.02' />
            </g>
        </svg>
    );
};
