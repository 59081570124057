/**
 * Constrain a value to lie between two further values.
 * @param number
 * @param min
 * @param max
 * @returns The value of `number` constrained to the range `min` to `max`.
 */
export const clamp = (number: number, min: number, max: number): number => {
    return Math.min(Math.max(number, min), max);
};
