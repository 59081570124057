import React, { useState } from 'react';

import { CardInformationItem } from '../CardInformationItem/CardInformationItem';
import styles from './VenueCard.module.css';

export interface IVenueCardProps {
    /** The duration of a venue's visit. */
    durationText: string;

    /** The maximum group capacity for the venue. */
    maxCapacityText: string;

    /** The logo shown at the center of the card. */
    logoUrl: string;

    /** The Background shown at the background of the card */
    backgroundUrl: string;

    /** Quantity fields. */
    children: React.ReactNode;
}

export const VenueCard: React.FC<IVenueCardProps> = ({
    children,
    durationText,
    maxCapacityText,
    logoUrl,
    backgroundUrl,
}) => {
    const [checked, setChecked] = useState(false); // Temporary for presentation purposes. Will proly go through rhf

    return (
        <div className={styles.venueCard}>
            <div
                className={styles.imageSection}
                style={{ backgroundImage: `url(${backgroundUrl})` }}
                onClick={() => setChecked(!checked)}
            >
                <img src={logoUrl} className={styles.logo} />
                <div className={styles.info}>
                    {durationText && (
                        <CardInformationItem
                            iconUrl='/images/clock-icon.svg'
                            text={`${durationText}`}
                        />
                    )}
                    {maxCapacityText && (
                        <CardInformationItem
                            iconUrl='/images/capacity-icon.svg'
                            text={maxCapacityText}
                        />
                    )}
                </div>
                <div className={styles.gradientOverlay}></div>
            </div>
            <div className={styles.quantitySection}>{children}</div>
        </div>
    );
};
