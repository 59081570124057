import React, { useEffect } from 'react';

import { useStreetContext } from 'lib/api/StreetContext';
import { fetchExpositionTimeslots } from 'lib/api/api';
import { getAmountByExpositionGuid } from 'lib/api/context';
import { useLanguage } from 'lib/routes/useLanguage';
import { IIncompleteOrderLine } from 'lib/types/api';

import { IOption, SelectField } from 'components/Form/SelectField/SelectField';

export interface IVenueTimePickerProps {
    /** Date to select timeslot for. */
    date: string;

    /** Exposition (guid) to select timeslot for. */
    expositionGuid: string;

    /** onChange callback. */
    onChange: (e: CustomEvent<IOption | null>, label?: string) => void;

    /** The field's label. */
    label: string;

    /** Same as `label`, but bold. If both `label` and `labelBold` are set: both are shown separated by a whitespace. (" "). */
    labelBold?: string;

    /** Orderlines specific for `expositionGuid`. */
    orderLines: IIncompleteOrderLine[];

    /** A valid `exposition_period_guid`. */
    value?: string;
}

/**
 * Selects a timeslot for a specific date.
 */
export const ExpositionTimeSlotPicker: React.FC<IVenueTimePickerProps> = ({
    date,
    expositionGuid,
    label,
    labelBold,
    orderLines,
    onChange,
    value,
}) => {
    const language = useLanguage();
    const [street] = useStreetContext();

    const [options, setOptions] = React.useState<IOption[]>([]);
    const [isLoading, setIsLoading] = React.useState<boolean>(false);
    const totalAmount = getAmountByExpositionGuid(orderLines, expositionGuid);
    const name = `timeslot-${expositionGuid}-${date}`;

    /**
     * Fetch timeslots for a specific date and exposition.
     */
    useEffect(() => {
        setIsLoading(true);
        setOptions([]);
        const abortController = new AbortController();
        fetchExpositionTimeslots(language, date, expositionGuid, abortController.signal)
            .then((timeSlots) => {
                const options = timeSlots.map((timeSlot) => ({
                    label: timeSlot.text,
                    value: timeSlot.data[0]?.period_id,
                }));
                setOptions(options);
            })
            .finally(() => setIsLoading(false));

        // Abort request when calling clean function.
        return () => abortController.abort();
    }, [language, date, expositionGuid, totalAmount]);

    /**
     * Gets called when a time slot is selected.
     * @param e
     */
    const onSelectChange = (e: CustomEvent<IOption>) => {
        const option = options.find((o) => o.value === e.detail.value);
        const label = option?.label;
        onChange(e, label || '');
    };

    return (
        <SelectField
            isLoading={isLoading}
            label={label}
            labelBold={labelBold}
            options={options}
            labelClear={street?.general_text.clear || ''}
            labelNoOptions={street?.general_text.no_options || ''}
            name={name}
            value={value}
            onChange={onSelectChange}
        />
    );
};
