import clsx from 'clsx';
import { MouseEventHandler } from 'react';

import { SuccessIcon } from 'components/Icons/SuccessIcon/SuccessIcon';
import { Span } from 'components/Typography/Span/Span';

import styles from './ProgressBar.module.css';

export interface IProgressBarProps {
    /** Current step (1-indexed), or a string to be directly rendered. */
    step: number | string;

    /** Labels to render, length is used to obtain the number of steps. */
    steps: string[];

    /** If set, use this icon in `<ProgressBar />` if `step` is a `string` . */
    stepIcon?: string;

    /** Callback function for "back" button, set to null for no back button. */
    onGoBack?: MouseEventHandler<HTMLButtonElement>;
}

/**
 * Indicates a current progress. Current step is indicated with (1-indexed) `step` prop which is compared to the length
 * of `steps`, which contains the labels.
 */
export const ProgressBar = ({ step, stepIcon, steps, onGoBack }: IProgressBarProps) => {
    const step0 = typeof step === 'number' ? step - 1 : 0; // Create 0-indexed step.
    const progressPercentage = (step0 / (steps.length - 1)) * 100; // Calculate percentage.
    const renderLabels = () =>
        steps.map((stepName, index) => {
            return (
                <div
                    key={stepName}
                    className={clsx(
                        styles.progressBar__label,
                        step0 === index && styles['progressBar__label--active']
                    )}
                    style={{ left: 0 }}
                >
                    {stepName}
                </div>
            );
        });

    return (
        <div
            className={styles.progressBar}
            role='progressbar'
            aria-valuenow={typeof step === 'number' ? step : undefined}
            aria-valuemin={typeof step === 'number' ? 0 : undefined}
            aria-valuemax={typeof step === 'number' ? step : undefined}
        >
            {typeof step === 'string' ? (
                <div className={styles.sucessMessage}>
                    {stepIcon && <SuccessIcon />}
                    <Span size='xs'>{step}</Span>
                </div>
            ) : (
                <>
                    <div className={styles.progressBar__bar}>
                        <div
                            className={styles.progressBar__value}
                            style={{ width: `${progressPercentage}%` }}
                        />
                    </div>
                    <div className={styles.progressBar__labels}>{renderLabels()}</div>

                    {onGoBack && (
                        <button className={styles.progressBar__back} onClick={onGoBack}>
                            <svg
                                xmlns='http://www.w3.org/2000/svg'
                                width='5'
                                height='7'
                                viewBox='0 0 5 7'
                            >
                                <path
                                    d='M3.7.5L1.4 2.8c-.4.4-.4 1 0 1.4l2.3 2.3'
                                    stroke='black'
                                    fill='none'
                                />
                            </svg>
                        </button>
                    )}
                </>
            )}
        </div>
    );
};
