import React from 'react';

import {
    ICompiledOrderLine,
    compileArticleOrderLine,
    compileOrderLine,
    filterOrderLinesByExpositionGuid,
    findArticleOrderLineByTeachingMaterialGuid,
    mergeOrderLine,
} from 'lib/api/context';
import { inputChangeEventFactory } from 'lib/events/eventFactory';
import { IArticleOrderLine, IOrderLine, IStreetData } from 'lib/types/api';

import { QuantityField } from 'components/Form/QuantityField/QuantityField';
import { Span } from 'components/Typography/Span/Span';

import styles from './OrderLineFieldset.module.css';

export type IOrderLineFieldsetProps = {
    /** The name to use (as if this component was a form field). */
    name: string;

    /** The street to use. */
    street: IStreetData;

    /** The order lines as value of the simulated form field (possibly passed by `Form`). */
    value?: IOrderLine[] | IArticleOrderLine[];

    /** The onChange handles (possibly passed by `Form`). */
    onChange?: (e: CustomEvent) => void;
} & (
    | { expositionGuid: string; teachingMaterialGuid?: never }
    | { teachingMaterialGuid: string; expositionGuid?: never }
);
/**
 * Simulates (and behaves as) a "form capable field" by utilizing an input-like interface (`name`, `value`, `onChange`).
 * Receives `IOrderLine[]` as value and calls `onChange` with updated order lines on change.
 * @see `Form` component.
 * @param children `<CardInformationItem />` items.
 * @param name The name to use (as if this component was a form field).
 * @param title
 * @param value The order lines as value of the simulated form field (possibly passed by `Form`).
 * @param onChange The onChange handles (possibly passed by `Form`).
 */
export const OrderLineFieldset: React.FC<
    React.PropsWithChildren<IOrderLineFieldsetProps>
> = ({
    children,
    expositionGuid,
    teachingMaterialGuid,
    name,
    onChange,
    street,
    value,
}) => {
    const translations = street?.general_text;
    const orderLines = expositionGuid
        ? filterOrderLinesByExpositionGuid<IOrderLine>(
              (value || []) as IOrderLine[],
              expositionGuid
          )
        : [
              findArticleOrderLineByTeachingMaterialGuid(
                  (value || []) as IArticleOrderLine[],
                  teachingMaterialGuid as string
              ) as IArticleOrderLine,
          ];

    /**
     * Returns whether `orderLineOrArticleOrderLine` is an `IOrderLine` based on presence of the `exposition` key.
     * @param orderLineOrArticleOrderLine
     * @return {boolean}
     */
    const isOrderLine = (
        orderLineOrArticleOrderLine: IOrderLine | IArticleOrderLine
    ): orderLineOrArticleOrderLine is IOrderLine => {
        return Boolean(expositionGuid);
    };

    /**
     * Passes `orderLineOrArticleOrderLine` to either `compileOrderLine` or `compileArticleOrderLine` depending on
     * presence of `exposition` key in `orderLineOrArticleOrderLine`. Returns the compiled order line.
     * @param street
     * @param orderLineOrArticleOrderLine
     * @return {ICompiledOrderLine}
     */
    const compileOrderLineOrArticleOrderLine = (
        street: IStreetData,
        orderLineOrArticleOrderLine: IOrderLine | IArticleOrderLine
    ): ICompiledOrderLine =>
        isOrderLine(orderLineOrArticleOrderLine)
            ? compileOrderLine(street, orderLineOrArticleOrderLine)
            : compileArticleOrderLine(street, orderLineOrArticleOrderLine);

    /**
     * Gets called when a quantity is selected.
     * @param e
     * @param orderLineOrArticleOrderLine
     */
    const onQuantityFieldChange = (
        e: CustomEvent<{ name: string; value: number }>,
        orderLineOrArticleOrderLine: IOrderLine | IArticleOrderLine
    ) => {
        const newOrderLine = {
            ...orderLineOrArticleOrderLine,
            amount: e.detail.value,
        };

        const matchingKey = isOrderLine(orderLineOrArticleOrderLine)
            ? 'exposition_price'
            : 'article';

        const mergedOrderLines = mergeOrderLine<typeof orderLineOrArticleOrderLine>(
            value || [],
            newOrderLine,
            matchingKey as any
        );

        const event = inputChangeEventFactory(name, mergedOrderLines);
        onChange && onChange(event);
    };

    const title =
        street &&
        orderLines.length &&
        compileOrderLineOrArticleOrderLine(street as IStreetData, orderLines[0]).name;

    return (
        <fieldset className={styles.orderlinefieldset}>
            <legend className={styles.orderlinefieldset__legend}>
                <Span size='lg'>{title}</Span>

                <aside className={styles.orderlinefieldset__information}>
                    {children}
                </aside>
            </legend>

            <div className={styles.orderlinefieldset__body}>
                {street &&
                    orderLines.map((o) => {
                        const key = isOrderLine(o) ? o.exposition_price : o.article;
                        const label = isOrderLine(o)
                            ? compileOrderLine(street, o).name
                            : compileArticleOrderLine(street, o).name;
                        return (
                            <QuantityField
                                key={key}
                                disabled={
                                    !isOrderLine(
                                        o
                                    ) /* We can't change article_orderlines yet. */
                                }
                                fill='white'
                                label={label}
                                labelAdd={translations?.add || ''}
                                labelSubtract={translations?.remove || ''}
                                onChange={(e) => onQuantityFieldChange(e, o)}
                                value={o.amount}
                            ></QuantityField>
                        );
                    })}
            </div>
        </fieldset>
    );
};
