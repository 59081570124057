import React from 'react';

import styles from './H3.module.css';

export interface IH3Props {
    children?: React.ReactNode;

    [index: string]: any;
}
export const H3: React.FC<IH3Props> = ({ children, ...props }) => {
    return (
        <h3 className={styles.h3} {...props}>
            {children}
        </h3>
    );
};
