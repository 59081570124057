import {
    filterOrderLinesByVenues,
    getExpositionGuidsByOrderLines,
    getVenuesByExpositionGuids,
} from 'lib/api/context';
import { IOrder, IStreetData } from 'lib/types/api';
import { IDataLayer } from 'lib/types/gtm';
import { compileGtmOrderLinesForVenues } from 'lib/utils/gtm';

import { formatDate } from '../../lib/format/date';
import { gtmDataLayer } from '../../lib/utils/useGtm';

interface IHandleGtmClickCheckAvailabilityProps {
    /* The street Context used */
    street: IStreetData;

    /* The exposition order lines */
    expositionOrderLines: IOrder['exposition_orderlines'];
}

/**
 * Handles the GTM click next event. Sends two events to GTM
 * 1. Checkout event - this includes the total price of the order
 * 2. Add to cart event - this includes the price of each item in the order
 * @param street The street Context used
 * @param expositionOrderLines The exposition order lines
 */
export const handleGtmClickCheckAvailability = ({
    street,
    expositionOrderLines,
}: IHandleGtmClickCheckAvailabilityProps) => {
    const items = compileGtmOrderLinesForVenues(street, expositionOrderLines);
    const price = items.reduce((acc, orderLine) => {
        return acc + Number(orderLine.price) * parseInt(orderLine.quantity);
    }, 0);

    const dataLayerCheckout: IDataLayer = {
        event: 'checkout',
        checkout_funnel_name: 'ticketstraat_onderwijs',
        checkout_stage_number: '3',
        checkout_stage_name: 'bezoek',
        value: price.toFixed(2),
        items,
    };

    const dataLayerCart: IDataLayer = {
        event: 'add_to_cart',
        value: price.toFixed(2),
        items,
    };

    gtmDataLayer({
        dataLayer: dataLayerCheckout,
    });

    // if there's no tickets in the datalayerCheckout we want to return early
    if (!dataLayerCheckout.items?.length) return;
    gtmDataLayer({
        dataLayer: dataLayerCart,
    });
};

interface IHandleGtmClickNextProps {
    /* The street Context used */
    street: IStreetData;

    /* The exposition order lines */
    expositionOrderLines: IOrder['exposition_orderlines'];

    /* The selected date */
    date: string;
}

/**
 * Handles the GTM click next event. Sends data to GTM about the order lines that are currently in the order.
 * @param street The street Context used
 * @param expositionOrderLines The exposition order lines
 * @param date The selected date
 */
export const handleGtmClickNext = ({
    street,
    expositionOrderLines,
    date,
}: IHandleGtmClickNextProps) => {
    const filteredOrderLines = filterOrderLinesByVenues(
        expositionOrderLines,
        street.venues
    );

    const expositionGuids = getExpositionGuidsByOrderLines(filteredOrderLines);
    const items = compileGtmOrderLinesForVenues(street, expositionOrderLines);
    const price = items.reduce((acc, orderLine) => {
        return acc + Number(orderLine.price) * parseInt(orderLine.quantity);
    }, 0);
    const venues = getVenuesByExpositionGuids(street, expositionGuids);

    /** This produces an object like this:
     * {
     *   visit_date_chosen_1: '2021-10-01 | 10:00',
     *   visit_date_chosen_2: '2021-10-01 | 10:00',
     * }
     */
    const visitDates = venues.reduce<{
        [key: `visit_date_chosen_${number}`]: string;
    }>((acc, orderLine, index) => {
        const startTimeForThisVenue = filteredOrderLines.find(
            (expositionOrderLine) =>
                expositionOrderLine.exposition === orderLine.exposition?.guid
        )?.start_time;
        const formattedDate = `${formatDate(
            new Date(date),
            'iso'
        )} | ${startTimeForThisVenue}`;
        acc[`visit_date_chosen_${index + 1}`] = formattedDate;
        return acc;
    }, {});

    const dataLayer: IDataLayer = {
        event: 'checkout',
        checkout_funnel_name: 'ticketstraat_onderwijs',
        checkout_stage_number: '4',
        checkout_stage_name: 'datum_&_tijd',
        ...visitDates,
        value: price.toFixed(2),
        items,
    };

    gtmDataLayer({
        dataLayer,
    });
};
