import { createStateContext } from 'react-use';

import { IModalProps } from '../../components/Modal/Modal';

export type ModalContent =
    | {
          title?: string;
          body: string;
      }
    | {
          title: string;
          body?: string;
      };

export type IModalContext = ModalContent & {
    /** @type {string|503} 503 (number) triggers maintenance mode display. */
    type?: IModalProps['type'] | 503;
};

export const [useModalContext, ModalContextProvider] =
    createStateContext<IModalContext | null>(null);
