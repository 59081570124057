import React from 'react';

import { mergeOrderLine } from 'lib/api/context';
import { inputChangeEventFactory } from 'lib/events/eventFactory';
import { IArticleOrderLine, ISchoolLevel, ITeachingMaterial } from 'lib/types/api';

import { CoverFlow } from 'components/CoverFlow/CoverFlow';

import { FormError } from '../../../../components/Form/FormError/FormError';
import { TeachingMaterialFlowCard } from './TeachingMaterialFlowCard';

export interface ITeachingMaterialsCoverFlowProps {
    teachingMaterials: ITeachingMaterial[];
    name: string;
    schoolLevelGuid?: ISchoolLevel['guid'];
    value?: IArticleOrderLine[];
    onChange?: (e: CustomEvent) => void;
    errorMessage?: string | object;
}

/**
 * Simulates (and behaves as) a "form capable field" by utilizing an input-like interface (`name`, `value`, `onChange`).
 * Receives `IArticleOrderLine[]` as value and calls `onChange` with updated order lines on change.
 * @see `Form` component for
 * @param teachingMaterials
 * @param name The name to use (as if this component was a form field).
 * @param value The order lines as value of the simulated form field (possibly passed by `Form`).
 * @param onChange The onChange handles (possibly passed by `Form`).
 * @param errorMessage The error message to show (if any).
 */
export const TeachingMaterialCoverFlow: React.FC<ITeachingMaterialsCoverFlowProps> = ({
    schoolLevelGuid,
    teachingMaterials = [],
    name,
    value = [],
    onChange,
    errorMessage,
}) => {
    /**
     * Returns the `ISchoolLevel` names to be shown for `teachingMaterial` (based on `schoolLevelGuid`).
     * @param teachingMaterial
     */
    const getLevelsFoTeachingMaterial = (teachingMaterial: ITeachingMaterial) => {
        const levels = teachingMaterial.levels || [];

        if (!schoolLevelGuid) {
            return levels.map((l) => l.name);
        }

        return levels.filter((l) => l.guid === schoolLevelGuid).map((l) => l.name);
    };

    /**
     * Gets called when a quantity is selected.
     * @param e
     */
    const onTeachingMaterialFlowCardChange = (e: CustomEvent<IArticleOrderLine>) => {
        const mergedOrderLines = mergeOrderLine<IArticleOrderLine>(
            value,
            e.detail,
            'article'
        );

        const event = inputChangeEventFactory(name, mergedOrderLines);
        onChange && onChange(event);
    };

    const _errorMessage = typeof errorMessage === 'string' ? errorMessage : null;
    return (
        <>
            <CoverFlow>
                {teachingMaterials.map((t) => (
                    <TeachingMaterialFlowCard
                        key={t.guid}
                        backgroundImage={t.image || ''}
                        teachingMaterial={t}
                        title={t.title}
                        levels={getLevelsFoTeachingMaterial(t)}
                        value={value.find((a) => a.article === t.guid)?.amount || 0}
                        onChange={onTeachingMaterialFlowCardChange}
                    />
                ))}
            </CoverFlow>
            {_errorMessage && <FormError id={name}>{_errorMessage}</FormError>}
        </>
    );
};
