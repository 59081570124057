import clsx from 'clsx';
import React from 'react';

import { Sizes } from '../../../lib/types/styling';
import styles from './H2.module.css';

export interface IH2Props extends React.HTMLAttributes<HTMLHeadingElement> {
    align?: 'start' | 'center' | 'end';
    size?: Sizes;
    children?: React.ReactNode;
}
export const H2: React.FC<IH2Props> = ({ align, children, size, ...props }) => {
    return (
        <h2
            className={clsx(
                styles.h2,
                align === 'start' && styles['h2--align-start'],
                align === 'center' && styles['h2--align-center'],
                align === 'end' && styles['h2--align-end'],
                {
                    [styles[`h2--size-${size}`]]: size,
                }
            )}
            {...props}
        >
            {children}
        </h2>
    );
};
