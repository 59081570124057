import React from 'react';

export interface IArrowProps {
    [key: string]: any;
}

export const Arrow: React.FC<IArrowProps> = ({ ...props }) => {
    return (
        <svg
            width='7'
            height='12'
            viewBox='0 0 7 12'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
            {...props}
        >
            <path
                d='M5.9002 1.20621L1.7002 5.82621L5.9002 10.4462'
                stroke='black'
                strokeWidth='2.05333'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
        </svg>
    );
};
