import clsx from 'clsx';

import { Span } from '../../Typography/Span/Span';
import styles from './FontsizeIcon.module.css';

export const fontSizes = ['sm', 'xl'] as const;
export type TFontSize = (typeof fontSizes)[number];
export interface IFontsizeIconProps {
    active: boolean;
    onClick?: () => void;
    size: TFontSize;
}

export const FontsizeIcon = (props: IFontsizeIconProps) => {
    const { active, onClick, size } = props;
    return (
        <div
            className={clsx(
                styles.fontsizeIcon,
                active && styles['fontsizeIcon--active']
            )}
            onClick={onClick}
        >
            <Span size={size} color={active ? 'black' : 'white'} bold>
                A
            </Span>
        </div>
    );
};
