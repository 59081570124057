import clsx from 'clsx';
import React from 'react';

import styles from './ListIcon.module.css';

export interface IListProps {
    animateOnHover: boolean;
}

export const ListIcon: React.FC<IListProps> = ({ animateOnHover, ...props }) => {
    return (
        <svg
            className={clsx(styles.listicon, {
                [styles['listicon--animate-on-hover']]: animateOnHover,
            })}
            clipRule='evenodd'
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit='10'
            version='1.1'
            viewBox='0 0 20 20'
            xmlns='http://www.w3.org/2000/svg'
            {...props}
        >
            <g
                stroke='currentColor'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeWidth='2'
            >
                <path className={styles.listicon__top} d='m1 6h18' />
                <path className={styles.listicon__bottom} d='m1 14h18' />
            </g>
        </svg>
    );
};
