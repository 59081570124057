import { FormikValues } from 'formik';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import useSWR from 'swr';

import {
    RequestError,
    cancelOrder,
    fetchOrder,
    fetchStreet,
    updateOrder,
} from 'lib/api/api';
import {
    getCateringArticlesByOrderLines,
    getLessonsByOrderLines,
    getTeachingMaterialsByArticleOrderLines,
    getVenuesByOrderLines,
} from 'lib/api/context';
import { formatPrice } from 'lib/i18n/i18n';
import { useModalContext } from 'lib/notification/ModalContext';
import { useLanguage } from 'lib/routes/useLanguage';
import { IExposition, IIncompleteOrder, ILesson, IOrder } from 'lib/types/api';

import { CardInformationItem } from 'components/Cards/CardInformationItem/CardInformationItem';
import { Form } from 'components/Form/Form';
import { Spinner } from 'components/Spinner/Spinner';
import { H2 } from 'components/Typography/H2/H2';
import { Span } from 'components/Typography/Span/Span';

import { ValidationErrors } from '../../components/Form/utils';
import { useOrderContext } from '../../lib/api/OrderContext';
import { useStreetContext } from '../../lib/api/StreetContext';
import { ucfirst } from '../../lib/format/string';
import { useZodI18nMap } from '../../lib/i18n/zodI18nMap';
import { getNextPathByRouteById } from '../../lib/routes/routes';
import { useRouteId } from '../../lib/routes/useRouteId';
import styles from './Change.module.css';
import { OrderLineFieldset } from './components/OrderLineFieldset/OrderLineFieldset';

export const Change: React.FC = () => {
    //
    // State and context.
    //

    // Application context.
    const navigate = useNavigate();
    const language = useLanguage();
    const routeId = useRouteId();
    const { orderId } = useParams<{ orderId: string }>();
    const [, setModalContext] = useModalContext();
    useZodI18nMap(language);

    // Specific order and street state.
    const [orderContext, setOrderContext] = useOrderContext();
    const [streetContext, setStreetContext] = useStreetContext();
    const [isStreetLoading, setIsStreetLoading] = useState<boolean>(false);
    const translations = streetContext?.general_text;

    //
    // Data fetching.
    //

    // Fetch order based on `language` and `orderId`.
    const { error, isLoading } = useSWR(
        [language, orderId],
        ([language, orderId]) =>
            fetchOrder(language, orderId as string)
                .then((data) => setOrderContext(data))
                .catch((e) =>
                    setModalContext({
                        title: translations?.main_error || '',
                        body: e.message,
                        type: 'danger',
                    })
                ),
        {
            revalidateOnFocus: false,
        }
    );

    // Fetch street based on `orderState.street`.
    useEffect(() => {
        setIsStreetLoading(true);
        orderContext?.street &&
            fetchStreet(language, orderContext.street)
                .then(setStreetContext)
                .catch((e) =>
                    setModalContext({
                        title: translations?.main_error || '',
                        body: e.message,
                        type: 'danger',
                    })
                )
                .finally(() => setIsStreetLoading(false));
    }, [orderContext?.street]);

    //
    // Loading and error rendering.
    //

    // Exit early when still loading or unable to render form.
    if (isLoading || isStreetLoading || error || !orderContext || !streetContext) {
        return (
            <main className={styles.change}>
                {isLoading && <Spinner />}
                {error && (
                    <Span color='danger' size='md'>
                        {(error?.toFormattedString && error?.toFormattedString()) ||
                            translations?.general_error ||
                            ''}
                    </Span>
                )}
            </main>
        );
    }

    //
    // Form rendering.
    //

    const venues =
        getVenuesByOrderLines(
            streetContext,
            orderContext?.exposition_orderlines || [],
            false
        ) || [];

    const lessons =
        getLessonsByOrderLines(
            streetContext,
            orderContext?.exposition_orderlines || [],
            false
        ) || [];

    const cateringArticles =
        getCateringArticlesByOrderLines(
            streetContext,
            orderContext?.exposition_orderlines || []
        ) || [];

    const teachingMaterials =
        getTeachingMaterialsByArticleOrderLines(
            streetContext,
            orderContext?.article_orderlines || []
        ) || [];

    /**
     * Gets called when the form is being validated.
     */
    const onFormValidate = (values: IIncompleteOrder): ValidationErrors => {
        setOrderContext(values);
        return {};
    };

    /**
     * Gets called when the form is being submitted.
     */
    const onFormSubmit = async (
        values: FormikValues,
        {
            setErrors,
            setSubmitting,
        }: {
            setErrors: (errors: Record<string, string>) => void;
            setSubmitting: (isSubmitting: boolean) => void;
        }
    ) => {
        try {
            await updateOrder(language, orderId as string, values as IOrder);
            navigate(
                getNextPathByRouteById(routeId, { language, orderId: String(orderId) }) ||
                    ''
            );
        } catch (e) {
            // If we have a RequestError, try to extract form errors from the response data.
            if (e instanceof RequestError) {
                const fields = Object.keys(orderContext);
                try {
                    setErrors(e.toRecord<string>(fields) as Record<string, string>);
                } catch (e) {
                    // Errors can't be extracted, fail silently.
                }
            }
            setModalContext({
                title: translations?.main_error || '',
                body: (e as Error).message,
                type: 'danger',
            });
            setSubmitting(false);
        }
    };

    /**
     * Gets called when the booking is being cancelled.
     */
    const onCancel = async () => {
        try {
            await cancelOrder(language, orderId as string);
            navigate(
                getNextPathByRouteById(routeId, { language, orderId: String(orderId) }) ||
                    ''
            );
        } catch (e) {
            // If we have a RequestError, try to extract form errors from the response data
            setModalContext({
                title: translations?.main_error || '',
                body: (e as Error).message,
                type: 'danger',
            });
        }
    };

    // Render form.
    return (
        <main className={styles.change}>
            {isLoading && <Spinner />}
            {error && (
                <Span color='danger' size='md'>
                    {(error?.toFormattedString && error?.toFormattedString()) ||
                        translations?.general_error ||
                        ''}
                </Span>
            )}
            {!isLoading && !error && orderContext && (
                <Form
                    initialValues={orderContext}
                    labelCancel={translations?.cancel_booking}
                    labelSubmit={translations?.save_changes}
                    showCancel={true}
                    validate={onFormValidate}
                    onSubmit={onFormSubmit}
                    onCancel={onCancel}
                >
                    {venues.length > 0 && <H2 align='center'>{ucfirst('Venues')}</H2>}
                    {venues.map((v) => {
                        if (!v.exposition) return;
                        return (
                            <OrderLineFieldset
                                key={v.exposition.guid}
                                expositionGuid={v.exposition.guid}
                                name='exposition_orderlines'
                                street={streetContext}
                            >
                                <CardInformationItem
                                    contrast
                                    iconUrl='/images/clock-icon.svg'
                                    text={v.visit_duration}
                                ></CardInformationItem>
                                <CardInformationItem
                                    contrast
                                    iconUrl='/images/capacity-icon.svg'
                                    text={v.max_students_text}
                                ></CardInformationItem>
                            </OrderLineFieldset>
                        );
                    })}

                    {lessons.length > 0 && (
                        <H2 align='center'>{ucfirst(translations?.lessons || '')}</H2>
                    )}
                    {lessons
                        .filter((l): l is ILesson & { exposition: IExposition } =>
                            Boolean(l.exposition)
                        )
                        .map((l) => (
                            <OrderLineFieldset
                                key={l.exposition.guid}
                                expositionGuid={l.exposition.guid}
                                name='exposition_orderlines'
                                street={streetContext}
                            >
                                <CardInformationItem
                                    contrast
                                    iconUrl='/images/clock-icon.svg'
                                    text={l.duration}
                                ></CardInformationItem>
                                <CardInformationItem
                                    contrast
                                    iconUrl='/images/capacity-icon.svg'
                                    text={l.max_students_text}
                                ></CardInformationItem>
                            </OrderLineFieldset>
                        ))}

                    {teachingMaterials.length > 0 && (
                        <H2 align='center'>
                            {ucfirst(translations?.teaching_materials || '')}
                        </H2>
                    )}
                    {teachingMaterials.map((t) => (
                        <OrderLineFieldset
                            key={t.guid}
                            name='article_orderlines'
                            street={streetContext}
                            teachingMaterialGuid={t.guid}
                        >
                            {t.levels.map((l) => (
                                <CardInformationItem
                                    key={l.guid}
                                    contrast
                                    iconUrl='/images/level-icon.svg'
                                    text={l.name}
                                ></CardInformationItem>
                            ))}
                        </OrderLineFieldset>
                    ))}

                    {cateringArticles.length > 0 && (
                        <H2 align='center'>{ucfirst(translations?.food || '')}</H2>
                    )}
                    {cateringArticles.map((c) => {
                        return (
                            <OrderLineFieldset
                                key={c.guid}
                                expositionGuid={
                                    streetContext?.catering_exposition_id || ''
                                }
                                name='exposition_orderlines'
                                street={streetContext}
                            >
                                <Span size='md'>
                                    {formatPrice(Number(c.price), language)}
                                </Span>
                            </OrderLineFieldset>
                        );
                    })}
                </Form>
            )}
            <Span color='gray' size='sm'>
                {translations?.change_order_policy}
            </Span>
        </main>
    );
};
