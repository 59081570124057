import clsx from 'clsx';
import React from 'react';

import styles from './H1.module.css';

export interface IH1Props {
    align?: 'start' | 'center' | 'end';
    children: React.ReactNode;
}
export const H1: React.FC<IH1Props> = ({ align, children }) => {
    return (
        <h1
            className={clsx(styles.h1, {
                [styles[`h1--align-${align}`]]: align,
            })}
        >
            {children}
        </h1>
    );
};
