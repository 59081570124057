import React from 'react';

import { useStreetContext } from 'lib/api/StreetContext';

import styles from './Spinner.module.css';

export interface ISpinnerProps {
    [key: string]: unknown;
}

export const Spinner: React.FC<ISpinnerProps> = ({ ...props }) => {
    const [street] = useStreetContext();
    const translations = street?.general_text;
    const ariaLabel = translations?.loading || 'Loading...';
    return (
        <div className={styles.spinnerWrapper} aria-label={ariaLabel} {...props}>
            <div className={styles.spinner}>
                {Array.from(Array(12).keys()).map((i) => (
                    <div key={i} />
                ))}
            </div>
        </div>
    );
};
