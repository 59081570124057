import React, { useEffect, useState } from 'react';

import { useOrderContext } from 'lib/api/OrderContext';
import { useStreetContext } from 'lib/api/StreetContext';
import { fetchSoldoutDates, getMediaURL } from 'lib/api/api';
import {
    filterOrderLinesByExpositionPriceGuid,
    getAmountByPriceGroupGuid,
    getLessonByExpositionGuid,
    getPriceByGuid,
    getValueByPriceGuid,
    getVenueByExpositionGuid,
    mergeOrderLine,
} from 'lib/api/context';
import { eventFactory } from 'lib/events/eventFactory';
import { formatDate } from 'lib/format/date';
import { useLanguage } from 'lib/routes/useLanguage';
import { IExposition, IIncompleteOrderLine, IOrderLine, IPrice } from 'lib/types/api';

import { FlowCard } from 'components/CoverFlow/FlowCard/FlowCard';
import { QuantityField } from 'components/Form/QuantityField/QuantityField';
import { Span } from 'components/Typography/Span/Span';

export interface ILessonFlowCardProps {
    backgroundImage: string;
    exposition: IExposition;
    orderLines: Array<IOrderLine | IIncompleteOrderLine>;

    /** The title is shown in the middle with a slight top offset */
    title: string;

    /** onChange callback. */
    onChange: (e: CustomEvent<Array<IOrderLine | IIncompleteOrderLine>>) => void;

    /** Whether the field is disabled. */
    disabled?: boolean;

    /** String representation of the capacity. */
    capacity?: string;

    /** String representation of the duration. */
    duration?: string;

    /** The location name is shown under the title in a row */
    locationName?: string;
}

export const ExpositionFlowCard: React.FC<ILessonFlowCardProps> = ({
    backgroundImage,
    disabled,
    exposition,
    locationName,
    capacity,
    duration,
    title,
    orderLines,
    onChange,
}) => {
    const language = useLanguage();
    const [street] = useStreetContext();
    const translations = street?.general_text;
    const [dirtyFields, setDirtyFields] = useState<string[]>([]);
    const [orderContext] = useOrderContext();
    const [isSoldOut, setIsSoldOut] = useState<boolean>(false);
    const image = getMediaURL(backgroundImage || '');

    if (!street) {
        return null;
    }

    useEffect(() => {
        if (orderContext.date) {
            const abortController = new AbortController();
            const formattedDate = formatDate(new Date(orderContext.date));

            fetchSoldoutDates(
                language,
                formattedDate,
                [exposition.guid],
                abortController.signal
            ).then((soldOutDates) => {
                const isSoldOut = Boolean(soldOutDates.find((d) => d === formattedDate));
                setIsSoldOut(isSoldOut);
            });

            return () => abortController.abort();
        }
    }, [orderContext.date]);

    /**
     * Returns the maximum value for the `<QuantityField />` based on the total amount selected.
     * @param priceGroupGuid
     */
    const getMaxByPriceGroupGuid = (priceGroupGuid: string): number => {
        const lessonOrVenue =
            getLessonByExpositionGuid(street, exposition.guid) ||
            getVenueByExpositionGuid(street, exposition.guid) ||
            undefined;

        if (!lessonOrVenue) {
            return Infinity;
        }

        const maxAmount = Number(lessonOrVenue.max_students);
        const otherPrices =
            lessonOrVenue.exposition?.prices.filter(
                (p) => p.group_guid !== priceGroupGuid
            ) || [];
        const othersAmount = otherPrices.reduce(
            (_, price) => getAmountByPriceGroupGuid(orderLines, price.group_guid),
            0
        );
        return maxAmount - othersAmount;
    };

    /**
     * Returns the highest count of a price group.
     * @param student
     */
    const getHighestCount = (student: boolean) => {
        const expositionPriceGroupGuids =
            orderContext.exposition_orderlines?.map((o) => o.exposition_price) ?? [];
        const priceGroups = expositionPriceGroupGuids.map((guid) =>
            getPriceByGuid(street, guid as string)
        );
        const studentPriceGroups = priceGroups.filter((p) => p?.is_student === student);
        const orderLinesWithPriceGroups = studentPriceGroups.flatMap((p) =>
            filterOrderLinesByExpositionPriceGuid(
                orderContext.exposition_orderlines || [],
                p?.group_guid || ''
            )
        );
        return orderLinesWithPriceGroups
            .map((o) => o.amount)
            .sort()
            .pop();
    };

    /**
     * Gets called when a quantity is selected.
     * @param e
     * @param price
     */
    const onQuantityFieldChange = (
        e: CustomEvent<{ name: string; value: number }>,
        price: IPrice
    ) => {
        /* Extract the new quantity from the event */
        let updatedQuantity = e.detail.value;

        /* Retrieve existing quantity for this price group, defaulting to 0 if not found */
        const currentQuantity =
            orderLines.find((line) => line.exposition_price === price.group_guid)
                ?.amount || 0;

        /* Check if the price group is already marked as modified */
        const isDirty = dirtyFields.includes(price.group_guid);

        /* If not modified and the new quantity exceeds the current, adjust based on the highest venue count */
        if (!isDirty && updatedQuantity > currentQuantity) {
            const maxVenueQuantity = getHighestCount(price.is_student) ?? 1;
            /* If the max venue quantity is greater than 1, use it as the new quantity */
            if (maxVenueQuantity > 1) {
                updatedQuantity = maxVenueQuantity;
            }
            /* Mark this price group as modified */
            setDirtyFields([...dirtyFields, price.group_guid]);
        }

        /* Construct the updated order line with the new quantity */
        const updatedOrderLine: IIncompleteOrderLine = {
            amount: updatedQuantity,
            exposition: exposition.guid,
            exposition_price: price.group_guid,
            type: 'lesson',
        };

        /* Merge the updated order line into the existing order lines */
        const mergedOrderLines = mergeOrderLine(orderLines, updatedOrderLine);
        const changeEvent = eventFactory('change', mergedOrderLines);
        onChange && onChange(changeEvent);
    };

    return (
        <FlowCard
            footer={
                exposition.description && (
                    <Span color='white' size='sm'>
                        <a href={exposition.description} rel='noreferrer' target='_blank'>
                            {translations?.click_more_info}
                        </a>
                    </Span>
                )
            }
            image={image}
            locationName={locationName}
            capacity={capacity}
            duration={duration}
            title={title}
        >
            {isSoldOut && (
                <Span align='center' color='white' size='md'>
                    {translations?.exposition_sold_out}
                </Span>
            )}
            {exposition.prices.map((p) => (
                <QuantityField
                    key={p.group_guid}
                    contrast={true}
                    disabled={disabled || isSoldOut}
                    label={p.group_name}
                    labelAdd={street?.general_text?.add || ''}
                    labelSubtract={street?.general_text?.remove || ''}
                    max={getMaxByPriceGroupGuid(p.group_guid)}
                    value={getValueByPriceGuid(orderLines, p.group_guid)}
                    onChange={(e) => onQuantityFieldChange(e, p)}
                />
            ))}
        </FlowCard>
    );
};
