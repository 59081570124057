import React, { Dispatch, SetStateAction } from 'react';

type ContextProvider<T> = ({
    children,
    initialValue,
}: {
    children?: React.ReactNode;
    initialValue?: T;
}) => React.FunctionComponentElement<
    React.ProviderProps<[T, React.Dispatch<React.SetStateAction<T>>] | undefined>
>;
type UseContext<T> = () => [T, Dispatch<SetStateAction<T>>];

/**
 * Decorator wrapping a (react-use) `stateContext` and using sessionStorage as cache.
 * Falls back to regular behaviour if sessionStorage is not accessible.
 * @param stateContext The state context to wrap.
 * @param sessionStorageName Key for `sessionStorage` item.
 */
export const withSessionStorage = <T extends object | null>(
    stateContext: readonly [UseContext<T>, ContextProvider<T>, any],
    sessionStorageName: string
): [UseContext<T>, ContextProvider<T>] => {
    const [useContext, contextProvider] = stateContext;

    const wrappedUseContext: UseContext<T> = () => {
        const [_context, _setContext] = useContext();

        try {
            const context =
                sessionStorageName in sessionStorage
                    ? JSON.parse(
                          sessionStorage.getItem(sessionStorageName) ||
                              JSON.stringify(_context)
                      )
                    : _context; // Context not found in `sessionStorage`, return regular context.
            const setContext: Dispatch<SetStateAction<T>> = (context) => {
                _setContext(context);
                try {
                    sessionStorage.setItem(sessionStorageName, JSON.stringify(context));
                } catch (e) {
                    // Setting context on `sessionStorage` failed, fail silently.
                }
            };
            return [context, setContext];
        } catch (e) {
            // Accessing `sessionStorage` failed, return regular context.
            return [_context, _setContext];
        }
    };

    return [wrappedUseContext, contextProvider];
};
