/**
 * Creates a `CustomEvent` of type "change" with target set to `{name: name, value: value}`.
 * The returned event and be used as on onChange callback allowing an input to work with `<Form/>`.
 * @param name The name of the input.
 * @param value The value of the input.
 */
export const inputChangeEventFactory = <
    T extends Date | number | string | null | Record<string, string> | Record<string, any>
>(
    name: string,
    value: T
) => eventFactory<{ name: string; value: any }>('change', { name, value });

/**
 * Creates a `CustomEvent` of `type` with target set to `target`.
 * @param type The event type (e.g. `"change"`).
 * @param target A value describing the event target (e.g. `{ name: "school": value: "ee6a7af7-650d-499b-8e32-58a52ffdb7bc"}`).
 * @param bubbles Whether the event bubbles up through the DOM tree or not.
 * @param cancelable Whether the event can be canceled, and therefore prevented as if the event never happened.
 * @param composed Whether or not the event will propagate across the shadow DOM boundary into the standard DOM.
 */
export const eventFactory = <T = Record<string, any>>(
    type: string,
    target: T,
    bubbles = true,
    cancelable = true,
    composed = true
): CustomEvent<T> => {
    const customEvent = new CustomEvent<T>(type, {
        bubbles,
        cancelable,
        composed,
        detail: target,
    });

    Object.defineProperty(customEvent, 'target', {
        value: target,
    });

    return customEvent;
};
