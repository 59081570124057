import React, { useEffect, useMemo } from 'react';

import { useStreetContext } from 'lib/api/StreetContext';
import { useUserSettingsContext } from 'lib/api/UserSettingsContext';
import { getMediaURL } from 'lib/api/api';
import { useRouteId } from 'lib/routes/useRouteId';

import { ChatIcon } from 'components/Icons/ChatIcon/ChatIcon';
import { EmailIcon } from 'components/Icons/EmailIcon/EmailIcon';
import { PhoneIcon } from 'components/Icons/PhoneIcon/PhoneIcon';
import { Span } from 'components/Typography/Span/Span';

import { IRouteContext } from '../../../lib/routes/RouteContext';
import { ButtonLink } from '../../Button/ButtonLink';
import { HamburgerMenu } from '../../HamburgerMenu/HamburgerMenu';
import {
    FontsizeIcon,
    TFontSize,
    fontSizes,
} from '../../Icons/FontsizeIcon/FontsizeIcon';
import { CircleIcon } from './CircleIcon/CircleIcon';
import styles from './RightContainer.module.css';
import { ShowInfo } from './ShowInfo/ShowInfo';

export interface IRightContainerProps {
    context: IRouteContext;
}

export const RightContainer: React.FC<IRightContainerProps> = ({ context }) => {
    const routeId = useRouteId();
    const showInfo = context.showInfo;
    const [streetData] = useStreetContext();
    const translations = streetData?.general_text;
    const [userSettings, setUserSettings] = useUserSettingsContext();
    // Memoize the background image for this route.
    // Without the use of memoization, the background image would be updated to the current route when changed resulting
    // in all items in `<TransitionGroup />` to use the same background image. Thereby shwoing an incorrect animation.
    const backdrop = useMemo(
        () => streetData?.background_images[routeId] || '',
        [streetData]
    );

    /**
     * Sync <html> `--font-size` with `userSettings.fontSize`.
     */
    useEffect(() => {
        document.documentElement.style.setProperty(
            '--font-size',
            userSettings.fontSize === 'xl' ? '20px' : '16px'
        );
    }, [userSettings.fontSize]);

    /**
     * We change the font size of the `html` element, which changes the font size.
     * We change it via the `--font-size` CSS variable so that we can add a ✨ smooth transition ✨.
     * @param changeTo - The font size to change to.
     * @private
     */
    const onFontSizeClick = (changeTo: TFontSize) => {
        setUserSettings({
            ...userSettings,
            fontSize: changeTo,
        });
    };

    return (
        <div className={styles.rightContainer}>
            <img
                className={styles.rightContainer__backdrop}
                src={getMediaURL(backdrop)}
                alt=''
            />
            {showInfo && <ShowInfo />}

            <div className={styles.rightContainer__topRight}>
                <HamburgerMenu title={translations?.menu_go_to || ''}>
                    {streetData?.navigation.map((n) => (
                        <ButtonLink
                            key={n.name}
                            fill='normal'
                            href={n.url}
                            size='larger'
                            target='_blank'
                        >
                            <img alt={n.name} src={getMediaURL(n.image)} />
                        </ButtonLink>
                    ))}
                </HamburgerMenu>
            </div>
            <div className={styles.rightContainer__bottomRight}>
                <div className={styles.rightContainer__bottomRightContent}>
                    {fontSizes.map((size) => (
                        <FontsizeIcon
                            key={size}
                            active={userSettings.fontSize === size}
                            onClick={() => onFontSizeClick(size)}
                            size={size}
                        />
                    ))}
                </div>
            </div>
            <div className={styles.rightContainer__bottomLeft}>
                <div className={styles.rightContainer__bottomLeftContent}>
                    <Span size={'2xl'} color={'white'}>
                        {translations?.need_help}
                    </Span>
                    {/*TODO: Expose in API? */}
                    <a
                        href='https://www.artis.nl/nl/footer/contact/contactformulier'
                        target='_blank'
                        rel='noreferrer'
                    >
                        <CircleIcon icon={<PhoneIcon />} available={null} />
                    </a>
                    <a
                        href='https://www.artis.nl/nl/bezoek/veelgesteldevragen/'
                        target='_blank'
                        rel='noreferrer'
                    >
                        <CircleIcon icon={<ChatIcon />} available={null} />
                    </a>
                    <a
                        href='https://www.artis.nl/nl/footer/contact/contactformulier'
                        target='_blank'
                        rel='noreferrer'
                    >
                        <CircleIcon
                            icon={<EmailIcon color={'black'} />}
                            available={null}
                        />
                    </a>
                </div>
            </div>
        </div>
    );
};
