import clsx from 'clsx';
import React, { useId } from 'react';
import Calendar from 'react-calendar';
import { Value, View } from 'react-calendar/dist/cjs/shared/types';
import { OnArgs } from 'react-calendar/src/shared/types';

import { inputChangeEventFactory } from 'lib/events/eventFactory';

import { Span } from 'components/Typography/Span/Span';

import { FormError } from '../FormError/FormError';
import { ILabeledFieldProps } from '../IFormFieldProps';
import styles from './Datepicker.module.css';
import './react-calendar-overrides.css';

export interface IDatepickerProps extends ILabeledFieldProps<Date | string, CustomEvent> {
    /** Aria-label for button without text. */
    labelNext: string;

    /** Aria-label for button without text. */
    labelPrevious: string;

    /** An array of dates that should not be selectable. */
    disabledDates?: Date[];

    /** Gets called when the user navigates from one view to another using previous/next button. */
    onActiveStartDateChange?: ({ action, activeStartDate, value, view }: OnArgs) => void;
}

export const Datepicker: React.FC<IDatepickerProps> = ({
    disabled,
    disabledDates = [],
    errorMessage,
    id,
    invalid,
    label,
    labelNext,
    labelPrevious,
    name,
    onActiveStartDateChange,
    onChange,
    required,
    value,
    ...props
}) => {
    const fieldId = id || useId();
    const errorId = invalid ? `${fieldId}-errormessage` : undefined;

    /**
     * Translates react-calendar onChange event to native-like onChange event.
     * @param value The selected date.
     * @event CustomEvent#change Custom change event.
     */
    const onCalendarChange = (value: Value) => {
        const event = inputChangeEventFactory(name || '', value);
        onChange && onChange(event);
    };

    const formatShortWeekday = (locale: string | undefined, date: Date) =>
        date.toLocaleDateString(locale, { weekday: 'short' }).slice(0, 2);

    const isDayDisabled = (date: Date, view: View) => {
        if (view !== 'month') return false;
        const today = new Date();
        const isPastDay = date < today;
        const isToday =
            date.getDate() === today.getDate() &&
            date.getMonth() === today.getMonth() &&
            date.getFullYear() === today.getFullYear();
        const isDisabled = disabledDates.some((disabledDate) => {
            return (
                disabledDate.getDate() === date.getDate() &&
                disabledDate.getMonth() === date.getMonth() &&
                disabledDate.getFullYear() === date.getFullYear()
            );
        });
        return isDisabled || (isPastDay && !isToday);
    };

    const getTileClassName = ({ date, view }: { date: Date; view: View }) => {
        if (view === 'month' && isDayDisabled(date, view)) {
            return 'react-calendar__month-view__days__day--disabled';
        }
        return null;
    };

    return (
        <div
            className={clsx(styles.datepicker, {
                [styles['datepicker--disabled']]: disabled,
                [styles['datepicker--invalid']]: invalid,
            })}
            id={id}
            title={required ? `${label}*` : label}
            aria-describedby={invalid ? errorId : undefined}
        >
            <Calendar
                minDetail='year'
                minDate={new Date()}
                nextLabel={<img src='/images/arrow-right-icon.svg' alt={labelNext} />}
                prevLabel={<img src='/images/arrow-left-icon.svg' alt={labelPrevious} />}
                value={value}
                navigationLabel={({ date, locale }) => (
                    <>
                        <Span size='xl' bold>
                            {date.toLocaleDateString(locale, { month: 'long' })}
                        </Span>{' '}
                        <Span size='xl'>
                            {date.toLocaleDateString(locale, { year: 'numeric' })}
                        </Span>
                    </>
                )}
                tileDisabled={({ date, view }) => isDayDisabled(date, view)}
                next2Label={null}
                prev2Label={null}
                formatShortWeekday={formatShortWeekday}
                tileClassName={getTileClassName}
                onChange={onCalendarChange}
                onActiveStartDateChange={onActiveStartDateChange}
                {...props}
            />
            {invalid && <FormError id={errorId as string}>{errorMessage}</FormError>}
        </div>
    );
};
