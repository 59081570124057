import clsx from 'clsx';
import React, { HTMLAttributeAnchorTarget } from 'react';

import styles from './Button.module.css';
import { IBaseButtonProps } from './IBaseButtonProps';

interface IButtonLinkProps extends IBaseButtonProps {
    href: string;
    target?: HTMLAttributeAnchorTarget;
}

/**
 * Generic button component.
 */
export const ButtonLink: React.FC<IButtonLinkProps> = ({
    children,
    color,
    fontSize,
    href,
    fill = 'primary',
    bold = true,
    size = 'normal',
    target,
    ...props
}) => {
    return (
        <a
            className={clsx(styles.button, {
                [styles[`button--color-${color}`]]: color,
                [styles[`button--font-size-${fontSize}`]]: fontSize,
                [styles[`button--filled`]]: Boolean(fill),
                [styles[`button--fill-color-${fill}`]]: fill,
                [styles[`button--bold`]]: bold,
                [styles[`button--size-${size}`]]: size,
            })}
            href={href}
            target={target}
            {...props}
        >
            {children}
        </a>
    );
};
