import clsx from 'clsx';
import React from 'react';

import styles from './Title.module.css';

export interface ITitleProps {
    /** The size of the title. */
    size: 'normal' | 'small';

    /** Whether to use a contrast color (white) instead of default (black). */
    contrast?: boolean;

    children: React.ReactNode;

    [index: string]: unknown;
}

/**
 * Generic typographic title component.
 */
export const Title: React.FC<ITitleProps> = ({ size, contrast = false, ...props }) => {
    return (
        <h4
            className={clsx(styles.title, styles[`title--size-${size}`], {
                [styles['title--contrast']]: contrast,
            })}
        >
            {props.children}
        </h4>
    );
};
