import clsx from 'clsx';
import React from 'react';

import styles from './Button.module.css';
import { IBaseButtonProps } from './IBaseButtonProps';

interface IButtonProps extends IBaseButtonProps {
    /** onClick callback. */
    onClick: (e: React.SyntheticEvent) => void;

    /** Button type. */
    type?: 'submit' | 'button' | 'reset';
}

/**
 * Generic button component.
 */
export const Button: React.FC<IButtonProps> = ({
    children,
    color,
    fontSize,
    type = 'button',
    onClick,
    fill = 'primary',
    bold = true,
    size = 'normal',
    ...props
}) => {
    return (
        <button
            className={clsx(styles.button, {
                [styles[`button--color-${color}`]]: color,
                [styles[`button--font-size-${fontSize}`]]: fontSize,
                [styles[`button--filled`]]: Boolean(fill),
                [styles[`button--fill-color-${fill}`]]: fill,
                [styles[`button--bold`]]: bold,
                [styles[`button--size-${size}`]]: size,
            })}
            type={type}
            onClick={onClick}
            {...props}
        >
            {children}
        </button>
    );
};
