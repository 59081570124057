import React from 'react';

import { useStreetContext } from 'lib/api/StreetContext';
import { getMediaURL } from 'lib/api/api';
import { inputChangeEventFactory } from 'lib/events/eventFactory';
import { useModalContext } from 'lib/notification/ModalContext';
import { IExposition, IIncompleteOrderLine, IVenue } from 'lib/types/api';

import { VenueCard } from 'components/Cards/VenueCard/VenueCard';
import { QuantityField } from 'components/Form/QuantityField/QuantityField';

import { getAmountByPriceGroupGuid } from '../../../../lib/api/context';

export interface IVenuePriceGroupAmountProps {
    /** Aria-label for button without text. */
    labelAdd: string;

    /** Aria-label for button without text. */
    labelSubtract: string;

    /** Whether the field is disabled. */
    disabled?: boolean;

    expositions: IExposition[];
    name: string;
    venue: IVenue;
    value?: IIncompleteOrderLine[];
    onChange?: (e: Event) => void;
}

export const VenuePriceGroupAmount: React.FC<IVenuePriceGroupAmountProps> = ({
    disabled,
    expositions,
    labelAdd,
    labelSubtract,
    name,
    onChange,
    value = [],
    venue,
}) => {
    const [street] = useStreetContext();
    const translations = street?.general_text;
    const [, setModalContext] = useModalContext();

    /**
     * Returns the maximum value for the `<QuantityField />` based on the total amount selected.
     * @param priceGroupGuid
     */
    const getMaxByPriceGroupGuid = (priceGroupGuid: string): number => {
        const maxAmount = Number(venue.max_students);
        const otherPrices =
            venue.exposition?.prices.filter((p) => p.group_guid !== priceGroupGuid) || [];
        const othersAmount = otherPrices.reduce(
            (total, price) => getAmountByPriceGroupGuid(value, price.group_guid),
            0
        );
        return maxAmount - othersAmount;
    };

    /**
     * Gets called when a quantity is selected.
     */
    const onQuantityFieldChange = (
        e: CustomEvent<{ name: string; value: number }>,
        priceGroupGuid: string
    ) => {
        if (disabled) {
            setModalContext({
                body: `${translations?.can_select_up_to || ''}
                ${translations?.thanks_for_understanding || ''}`,
            });
        }

        // Find order line matching `priceGroupGuid` (default {}) and other order lines.
        const matchingOrderLine =
            value.find((o) => o.exposition_price === priceGroupGuid) || {};
        const otherOrderLines =
            value.filter((o) => o.exposition_price !== priceGroupGuid) || [];

        // Add known values to `matchingOrderLine`.
        Object.assign(matchingOrderLine, {
            amount: e.detail.value,
            exposition: expositions.find((e) =>
                e.prices.find((p) => p.group_guid === priceGroupGuid)
            )?.guid,
            exposition_price: priceGroupGuid,
        });

        // Use combined order lines as value.
        const newOrderLines = [...otherOrderLines, matchingOrderLine];
        const event = inputChangeEventFactory(name, newOrderLines as Record<string, any>);
        onChange && onChange(event);
    };

    return (
        <>
            <VenueCard
                key={venue.slug}
                durationText={venue.visit_duration}
                maxCapacityText={venue.max_students_text}
                logoUrl={getMediaURL(venue.park.logo)}
                backgroundUrl={getMediaURL(venue.background_image)}
            >
                {venue.exposition?.prices.map((price) => {
                    return (
                        <QuantityField
                            key={price.group_guid}
                            disabled={disabled}
                            label={price.group_name}
                            labelAdd={labelAdd}
                            labelSubtract={labelSubtract}
                            max={getMaxByPriceGroupGuid(price.group_guid)}
                            name={`price-group-amount-${price.group_guid}`}
                            value={getAmountByPriceGroupGuid(value, price.group_guid)}
                            onChange={(e) => onQuantityFieldChange(e, price.group_guid)}
                        />
                    );
                })}
            </VenueCard>
        </>
    );
};
