import { ExpositionTimeSlotPicker } from 'containers/ExpositionTimeSlotPicker/ExpositionTimeSlotPicker';
import React from 'react';

import { useOrderContext } from 'lib/api/OrderContext';
import { useStreetContext } from 'lib/api/StreetContext';
import {
    areTooManyLessonsSelected,
    filterOrderLinesByLesson,
    filterOrderLinesByLessons,
    filterOrderLinesByVenue,
    getLessonByExpositionGuid,
    getLessonsByContext,
    getVenueByExpositionGuid,
    getVenueByLesson,
    mapOrderLinesByExposition,
    mergeOrderLine,
    mergeOrderLines,
} from 'lib/api/context';
import { inputChangeEventFactory } from 'lib/events/eventFactory';
import { formatDate } from 'lib/format/date';
import { useModalContext } from 'lib/notification/ModalContext';
import {
    IExposition,
    IIncompleteOrderLine,
    IOrderLine,
    IStreetData,
} from 'lib/types/api';

import { CoverFlow } from 'components/CoverFlow/CoverFlow';
import { IOption } from 'components/Form/SelectField/SelectField';
import { H2 } from 'components/Typography/H2/H2';
import { Span } from 'components/Typography/Span/Span';

import { FormError } from '../../../../components/Form/FormError/FormError';
import { ExpositionFlowCard } from './ExpositionFlowCard';

export interface IExpositionsCoverFlowProps {
    expositions: IExposition[];
    name: string;
    value?: Array<IOrderLine | IIncompleteOrderLine>;
    onChange?: (e: CustomEvent) => void;
    errorMessage?: string | object;
}

/**
 * Simulates (and behaves as) a "form capable field" by utilizing an input-like interface (`name`, `value`, `onChange`).
 * Receives `Array<IOrderLine | IIncompleteOrderLine>` as value and calls `onChange` with updated order lines on change.
 * @see `Form` component.
 * @param expositions
 * @param name The name to use (as if this component was a form field).
 * @param value The order lines as value of the simulated form field (possibly passed by `Form`).
 * @param onChange The onChange handles (possibly passed by `Form`).
 * @param errorMessage The error message to show (if any).
 */
export const ExpositionsCoverFlow: React.FC<IExpositionsCoverFlowProps> = ({
    expositions,
    name,
    value = [],
    onChange,
    errorMessage,
}) => {
    const [street] = useStreetContext() as [IStreetData, unknown];
    const translations = street.general_text;
    const [orderContext] = useOrderContext();
    const [, setModalContext] = useModalContext();

    const lessons = (street && getLessonsByContext(street, orderContext)) || [];
    const lessonOrderLines = filterOrderLinesByLessons(
        orderContext.exposition_orderlines || [],
        lessons
    );
    const lessonOrderLinesByExposition = mapOrderLinesByExposition(lessonOrderLines);

    /**
     * Returns whether the `<QuantityField />` in `<ExpositionFlowCard />` should be disabled (when setting a value will
     * result in too many lessons being selected).
     * @param exposition
     */
    const getDisabled = (exposition: IExposition): boolean => {
        const lesson = getLessonByExpositionGuid(street, exposition.guid);

        if (!lesson) {
            return false;
        }

        const venue = getVenueByLesson(street, lesson);

        // 1. Create a fake orderline.
        const tempOrderLine: IIncompleteOrderLine = {
            exposition: exposition.guid,
            amount: 1,
        };

        // 2. Merge the orderline with values from `orderContext.exposition_orderlines`.
        const tempMergedOrderLines = mergeOrderLine(
            orderContext.exposition_orderlines || [],
            tempOrderLine,
            'exposition'
        );

        // 3. Check whether merged orderLines are considered too many.
        return areTooManyLessonsSelected(tempMergedOrderLines, venue?.lessons || []);
    };

    /**
     * Gets called when a quantity is selected.
     * @param e
     * @param exposition
     */
    const onLessonFlowCardChange = (
        e: CustomEvent<Array<IOrderLine | IIncompleteOrderLine>>,
        exposition: IExposition
    ) => {
        if (getDisabled(exposition)) {
            setModalContext({
                body: `${street?.general_text.can_select_up_to_lesson}
                ${street?.general_text.thanks_for_understanding}`,
            });
        }

        const mergedOrderLines = mergeOrderLines(value, e.detail);

        const event = inputChangeEventFactory(name, mergedOrderLines);
        onChange && onChange(event);
    };

    /**
     * Gets called when a time slot is selected.
     * @param e
     * @param expositionGuid
     * @param label
     */
    const onExpositionTimeSlotPickerChange = (
        e: CustomEvent<IOption | null>,
        expositionGuid: string,
        label?: string
    ) => {
        const startTime = label?.split('-')[0];
        const incompleteOrderLine: IIncompleteOrderLine = {
            exposition: expositionGuid,
            exposition_period_guid: e.detail ? e.detail.value : undefined,
            start_time: startTime,
        };

        const mergedOrderLines = mergeOrderLine(value, incompleteOrderLine, 'exposition');

        const event = inputChangeEventFactory(name, mergedOrderLines);
        onChange && onChange(event);
    };
    const _errorMessage = typeof errorMessage === 'string' ? errorMessage : null;

    return (
        <>
            <CoverFlow>
                {expositions.map((exposition) => {
                    const lesson = getLessonByExpositionGuid(street, exposition.guid);

                    const venue = lesson
                        ? getVenueByLesson(street, lesson)
                        : getVenueByExpositionGuid(street, exposition.guid);

                    const backgroundImage =
                        lesson?.background_image || venue?.background_image || '';

                    const title = lesson?.exposition?.name || venue?.park?.name || '';

                    const locationName =
                        (lesson ? lesson.location : venue?.park.name) || '';

                    const capacity =
                        lesson?.max_students_text || venue?.max_students_text;
                    const duration = lesson?.duration || venue?.visit_duration;

                    const orderLines = lesson
                        ? filterOrderLinesByLesson(value, lesson)
                        : venue
                        ? filterOrderLinesByVenue(value, venue)
                        : [];
                    return (
                        <ExpositionFlowCard
                            key={exposition.guid}
                            backgroundImage={backgroundImage}
                            disabled={getDisabled(exposition)}
                            exposition={exposition}
                            locationName={locationName}
                            capacity={capacity}
                            duration={duration}
                            orderLines={orderLines}
                            title={title}
                            onChange={(e) => onLessonFlowCardChange(e, exposition)}
                        />
                    );
                })}
            </CoverFlow>
            {_errorMessage && <FormError id={name}>{_errorMessage}</FormError>}
            {expositions.length > 1 && (
                <Span align='center' color='black' size='sm'>
                    {translations?.max_lesson}
                </Span>
            )}

            {lessonOrderLinesByExposition.size > 0 && (
                <H2 align='center'>{translations?.choose_time}</H2>
            )}

            {Array.from(lessonOrderLinesByExposition).map(
                ([expositionGuid, orderLines]) => (
                    <ExpositionTimeSlotPicker
                        key={expositionGuid}
                        date={formatDate(
                            orderContext.date ? new Date(orderContext.date) : new Date()
                        )}
                        expositionGuid={expositionGuid}
                        label={translations?.time_for || ''}
                        onChange={(e, label) =>
                            onExpositionTimeSlotPickerChange(e, expositionGuid, label)
                        }
                        orderLines={orderLines}
                        value={
                            orderContext.exposition_orderlines?.find(
                                (o) => o.exposition === expositionGuid
                            )?.exposition_period_guid
                        }
                    />
                )
            )}
        </>
    );
};
