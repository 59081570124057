import { IOrder, IStreetData } from 'lib/types/api';
import { IDataLayer } from 'lib/types/gtm';

import { gtmDataLayer } from '../../lib/utils/useGtm';
import {
    compileGtmOrderLinesForUpsell,
    compileGtmOrderLinesForVenues,
} from './../../lib/utils/gtm';

interface IHandleGtmClickNextProps {
    /* The street Context used */
    street: IStreetData;

    /* The exposition order lines */
    expositionOrderLines: IOrder['exposition_orderlines'];

    /* The article order lines */
    articleOrderLines: IOrder['article_orderlines'];
}

/**
 * Handles the GTM click next event. Sends data to GTM about the order lines that are currently in the order.
 * @param street The street Context used
 * @param expositionOrderLines The exposition order lines
 * @param articleOrderLines The article order lines
 */
export const handleGtmClickNext = ({
    street,
    expositionOrderLines,
    articleOrderLines,
}: IHandleGtmClickNextProps) => {
    const venueItems = compileGtmOrderLinesForVenues(street, expositionOrderLines);
    const upsellItems = compileGtmOrderLinesForUpsell({
        street,
        expositionOrderLines,
        articleOrderLines,
    });

    const combinedItems = [...venueItems, ...upsellItems];
    const combinedPrice = combinedItems.reduce((acc, orderLine) => {
        return acc + Number(orderLine.price) * parseInt(orderLine.quantity);
    }, 0);

    const dataLayerCheckout: IDataLayer = {
        event: 'checkout',
        checkout_funnel_name: 'ticketstraat_onderwijs',
        checkout_stage_number: '5',
        checkout_stage_name: 'add_ons',
        value: combinedPrice.toFixed(2),
        items: combinedItems,
    };

    gtmDataLayer({
        dataLayer: dataLayerCheckout,
    });
};

export const handleGtmAddToCart = ({
    street,
    expositionOrderLines,
    articleOrderLines,
}: IHandleGtmClickNextProps) => {
    const upsellItems = compileGtmOrderLinesForUpsell({
        street,
        expositionOrderLines,
        articleOrderLines,
    });

    // Calculate the total price of the order lines
    const totalPrice = upsellItems.reduce((acc, orderLine) => {
        return acc + Number(orderLine.price) * parseInt(orderLine.quantity);
    }, 0);

    const dataLayerCart: IDataLayer = {
        event: 'add_to_cart',
        value: totalPrice.toFixed(2),
        items: upsellItems,
    };

    gtmDataLayer({
        dataLayer: dataLayerCart,
    });
};
