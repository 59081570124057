import React, { SyntheticEvent, useState } from 'react';

import { Button } from '../Button/Button';
import { Info } from '../Icons/Info/Info';
import { Modal } from '../Modal/Modal';
import styles from './InfoButton.module.css';

export interface IInfoButtonProps {
    /** Aria-label for button without text. */
    label: string;

    /** Aria-label for close button without text. */
    labelClose: string;

    /** onClick callback. */
    onClick?: (e: React.SyntheticEvent) => void;

    /** onClose callback. */
    onClose?: (e: React.SyntheticEvent | KeyboardEvent) => void;

    /** Get passed to the modal opened by this button modal. */
    children: React.ReactNode;
}

/**
 * Simple generic info button, can be used to show UI elements like modals.
 */
export const InfoButton: React.FC<IInfoButtonProps> = ({
    label,
    labelClose,
    onClick = () => undefined,
    onClose = () => undefined,
    ...props
}) => {
    const [openState, setOpenState] = useState<boolean>(false);

    /**
     * `<button />` click callback.
     * @param e
     */
    const onButtonClick = (e: SyntheticEvent) => {
        setOpenState(!openState);
        onClick(e);
    };

    /**
     * `<Modal />` close callback.
     * @param e
     */
    const onModalClose = (e: SyntheticEvent | KeyboardEvent) => {
        setOpenState(false);
        onClose(e);
    };

    return (
        <>
            <Button
                className={styles.infobutton}
                aria-label={label}
                title={label}
                onClick={onButtonClick}
                {...props}
            >
                <Info />
            </Button>
            <Modal labelClose={labelClose} open={openState} onClose={onModalClose}>
                {props.children}
            </Modal>
        </>
    );
};
