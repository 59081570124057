import React from 'react';

import styles from './Ticket.module.css';

export interface ITicketProps {
    [index: string]: any;
}

export const Ticket: React.FC<ITicketProps> = ({ ...props }) => {
    return (
        <svg
            className={styles.ticket}
            xmlns='http://www.w3.org/2000/svg'
            viewBox='0 0 20 20'
            {...props}
        >
            <g
                fill='none'
                stroke='currentColor'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeWidth='.5'
            >
                <path d='m8.1538 5.8462v8.3076' />
                <path d='m4 12.261c-2e-5 -0.1063 0.03673-0.2094 0.10401-0.2918 0.06729-0.0823 0.16097-0.1388 0.26517-0.16 0.41697-0.0851 0.79171-0.3117 1.0608-0.6414 0.26911-0.3297 0.41612-0.7422 0.41612-1.1678 0-0.42558-0.14701-0.8381-0.41612-1.1678-0.26914-0.32969-0.64388-0.55629-1.0608-0.64147-0.1042-0.02115-0.19788-0.07769-0.26517-0.16002-0.06728-0.08233-0.10403-0.1854-0.10401-0.29173v-1.4313c0-0.12241 0.04862-0.2398 0.13518-0.32635 0.08656-0.08656 0.20395-0.13519 0.32636-0.13519h11.077c0.1224 0 0.2398 0.04863 0.3263 0.13519 0.0866 0.08655 0.1352 0.20394 0.1352 0.32635v1.4313c0 0.10633-0.0367 0.2094-0.104 0.29173s-0.161 0.13887-0.2652 0.16002c-0.4169 0.08519-0.7917 0.31178-1.0608 0.64147-0.2691 0.32966-0.4162 0.74218-0.4162 1.1678 0 0.4256 0.1471 0.8381 0.4162 1.1678s0.6439 0.5563 1.0608 0.6414c0.1042 0.0212 0.1979 0.0777 0.2652 0.16 0.0673 0.0824 0.104 0.1855 0.104 0.2918v1.4313c0 0.1224-0.0486 0.2398-0.1352 0.3264-0.0865 0.0865-0.2039 0.1351-0.3263 0.1351h-11.077c-0.12241 0-0.2398-0.0486-0.32636-0.1351-0.08656-0.0866-0.13518-0.204-0.13518-0.3264v-1.4313z' />
            </g>
        </svg>
    );
};
