import React, { useEffect, useRef } from 'react';
import Swiper from 'swiper';
import 'swiper/css';
import 'swiper/css/effect-coverflow';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { EffectCoverflow, Navigation, Pagination } from 'swiper/modules';
import { SwiperOptions } from 'swiper/types/swiper-options';

import styles from './CoverFlow.module.css';

export interface ICoverFlowProps {
    [index: string]: unknown;
}

export const CoverFlow: React.FC<React.PropsWithChildren<ICoverFlowProps>> = ({
    children,
    ...props
}) => {
    const swiperRef = useRef<HTMLDivElement>(null);
    const paginationRef = useRef(null);
    useEffect(() => {
        if (!swiperRef.current) {
            return;
        }

        const params: SwiperOptions = {
            modules: [EffectCoverflow, Navigation, Pagination],
            autoHeight: true,
            centeredSlides: true,
            coverflowEffect: {
                depth: 300,
                modifier: 1,
                rotate: 0,
                slideShadows: true,
            },
            effect: 'coverflow',
            grabCursor: true,
            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
            },
            pagination: {
                // We need to pass a ref to the pagination element to make sure multiple instances of this component won't clash
                el: paginationRef.current,
            },
            slidesPerView: 'auto',
            slideToClickedSlide: true,
            spaceBetween: 120,
        };

        // Again, we need to pass a ref to the swiper element to make sure multiple instances of this component won't clash
        new Swiper(swiperRef.current, params);
    }, [swiperRef]);

    const renderChildren = (): React.ReactNode => {
        return React.Children.map(
            children as React.ReactElement,
            (child: React.ReactElement) => (
                <li key={child.key} className='swiper-slide'>
                    {child}
                </li>
            )
        );
    };

    return (
        <section className={styles.coverflow} {...props}>
            <div ref={swiperRef} className='swiper'>
                <ul className='swiper-wrapper'>{renderChildren()}</ul>
                <footer className={styles.coverflow__footer}>
                    <button className='swiper-button-prev'></button>
                    {React.Children.toArray(children).length > 1 && (
                        <nav ref={paginationRef} className='swiper-pagination'></nav>
                    )}
                    <button className='swiper-button-next'></button>
                </footer>
            </div>
        </section>
    );
};
