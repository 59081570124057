import { FormikValues } from 'formik/dist/types';
import { useNavigate } from 'react-router-dom';
import { z } from 'zod';

import { useOrderContext } from 'lib/api/OrderContext';
import { useStreetContext } from 'lib/api/StreetContext';
import {
    areCateringArticlesAvailable,
    areTooManyLessonsSelected,
    filterOrderLinesWithAmountInOrder,
    getLessonsByContext,
    getLessonsByOrderLines,
    getTeachingMaterialsByArticleOrderLines,
    getTeachingMaterialsByContext,
    getVenuesByOrderLines,
    isLessonOrTeachingMaterialRequired,
} from 'lib/api/context';
import { useModalContext } from 'lib/notification/ModalContext';
import { getNextPathByRouteById } from 'lib/routes/routes';
import { useLanguage } from 'lib/routes/useLanguage';
import { useRouteId } from 'lib/routes/useRouteId';
import {
    IExposition,
    IIncompleteOrder,
    ILesson,
    IMoreInfo,
    IOrder,
    IStreetData,
    ZArticleOrderLine,
    ZOrderLine,
} from 'lib/types/api';

import { Form } from 'components/Form/Form';
import { ValidationErrors, ZodValidate } from 'components/Form/utils';
import { Content } from 'components/Layout/Content/Content';
import { H2 } from 'components/Typography/H2/H2';
import { Hr } from 'components/Typography/Hr/Hr';
import { Span } from 'components/Typography/Span/Span';

import { HGroup } from '../../components/Typography/HGroup/HGroup';
import { useZodI18nMap } from '../../lib/i18n/zodI18nMap';
import styles from './Upsell.module.css';
import { CateringArticleProductCards } from './components/CateringArticleProductCards/CateringArticleProductCards';
import { ExpositionsCoverFlow } from './components/ExpositionCoverFlow/ExpositionsCoverFlow';
import { TeachingMaterialCoverFlow } from './components/TeachingMaterialCoverFlow/TeachingMaterialCoverFlow';
import { handleGtmAddToCart, handleGtmClickNext } from './datalayer';

// TODO: Translations
export const FORM_SCHEMA = z.object({
    exposition_orderlines: z.array(ZOrderLine).min(1),
    article_orderlines: z.array(ZArticleOrderLine).optional(),
});

/**
 * A runtime variant of `FORM_SCHEMA` that takes into account whether lessons and/or teaching materials are selected
 * (and should) be.
 * @param street
 */
const createFormSchema = (street: IStreetData) => {
    return FORM_SCHEMA.superRefine((data, ctx) => {
        const { isLessonRequired, isTeachingMaterialRequired } =
            isLessonOrTeachingMaterialRequired(street, data.exposition_orderlines);
        const selectedLessons = getLessonsByOrderLines(
            street,
            data.exposition_orderlines
        );
        const selectedTeachingMaterials = getTeachingMaterialsByArticleOrderLines(
            street,
            data.article_orderlines || []
        );

        if (isLessonRequired && selectedLessons.length === 0) {
            ctx.addIssue({
                path: ['exposition_orderlines'],
                message: 'At least one lesson is required', // TODO: Translation, optionally we make it the generic Required message
                code: 'too_small',
                type: 'string',
                minimum: 1,
                inclusive: true,
            });
        }

        if (areTooManyLessonsSelected(data.exposition_orderlines, selectedLessons)) {
            ctx.addIssue({
                path: ['exposition_orderlines'],
                message: 'Too many lessons selected', // TODO: Translation (currently we don't show this message to the user though)
                code: 'too_big',
                type: 'number',
                inclusive: true,
                maximum: 1,
            });
        }

        if (isTeachingMaterialRequired && selectedTeachingMaterials.length === 0) {
            ctx.addIssue({
                path: ['article_orderlines'],
                message: 'At least one teaching material is required', // TODO: Translation, optionally we make it the generic Required message
                code: 'too_small',
                type: 'string',
                minimum: 1,
                inclusive: true,
            });
        }
    });
};

export const Upsell = () => {
    const navigate = useNavigate();
    const routeId = useRouteId();
    const language = useLanguage();
    const [street] = useStreetContext();
    const translations = street?.general_text;
    const [orderContext, setOrderContext] = useOrderContext();
    const [, setModalContext] = useModalContext();
    useZodI18nMap(language);

    const venues =
        (street &&
            getVenuesByOrderLines(street, orderContext.exposition_orderlines || [])) ||
        [];
    const lessons = (street && getLessonsByContext(street, orderContext)) || [];
    const teachingMaterials =
        (street && getTeachingMaterialsByContext(street, orderContext)) || [];

    /**
     * Gets called when the form is being validated.
     * NOTE: This callback is debounced by the <Form/> component.
     */
    const onFormValidate = (values: IIncompleteOrder): ValidationErrors => {
        // Remove 0 amount orderLines.
        const _values = filterOrderLinesWithAmountInOrder(values);
        setOrderContext({ ...orderContext, ..._values });
        return ZodValidate(createFormSchema(street as IStreetData), _values);
    };

    /**
     * Gets called when the form is submitted.
     * @param values
     */
    const onFormSubmit = (values: FormikValues) => {
        const _values = filterOrderLinesWithAmountInOrder(values);
        const newOrderContext = { ...orderContext, ..._values };
        setOrderContext(newOrderContext);
        if (street) {
            handleGtmClickNext({
                street,
                expositionOrderLines:
                    newOrderContext.exposition_orderlines as IOrder['exposition_orderlines'],
                articleOrderLines:
                    newOrderContext.article_orderlines as IOrder['article_orderlines'],
            });
            handleGtmAddToCart({
                street,
                expositionOrderLines:
                    orderContext.exposition_orderlines as IOrder['exposition_orderlines'],
                articleOrderLines:
                    orderContext.article_orderlines as IOrder['article_orderlines'],
            });
        }
        navigate(getNextPathByRouteById(routeId, { language }) || '');
    };

    /**
     * Opens a modal for the provided `IMoreInfo`.
     * @param moreInfo
     */
    const showMoreInfo = (moreInfo: IMoreInfo) => {
        setModalContext({ title: moreInfo.title, body: moreInfo.description });
    };

    return (
        <div className={styles.upsell}>
            {street && (
                <Form
                    initialValues={orderContext}
                    labelSubmit={translations?.next_step || ''}
                    validate={onFormValidate}
                    onSubmit={onFormSubmit}
                    submitErrorLabel={translations?.fill_in_required_fields}
                >
                    <Content>
                        {lessons.length > 0 && (
                            <>
                                <HGroup>
                                    <H2 align='center'>{translations?.add_lesson}</H2>
                                    {venues[0]?.lessons_more_info && (
                                        <Span align='center' color='gray' size='sm'>
                                            <a
                                                href='#'
                                                onClick={() =>
                                                    showMoreInfo(
                                                        venues[0].lessons_more_info
                                                    )
                                                }
                                            >
                                                {translations?.click_more_info}
                                            </a>
                                        </Span>
                                    )}
                                </HGroup>
                                <ExpositionsCoverFlow
                                    expositions={lessons
                                        .filter(
                                            (
                                                l
                                            ): l is ILesson & {
                                                exposition: IExposition;
                                            } => Boolean(l.exposition)
                                        )
                                        .map((l) => l.exposition)}
                                    name='exposition_orderlines'
                                />

                                <Hr />
                            </>
                        )}

                        {teachingMaterials.length > 0 && (
                            <>
                                <HGroup>
                                    <H2 align='center'>
                                        {translations?.additional_teaching_materials}
                                    </H2>
                                    {venues[0]?.teaching_materials_more_info && (
                                        <Span align='center' color='gray' size='sm'>
                                            <a
                                                href='#'
                                                onClick={() =>
                                                    showMoreInfo(
                                                        venues[0]
                                                            .teaching_materials_more_info
                                                    )
                                                }
                                            >
                                                {translations?.click_more_info}
                                            </a>
                                        </Span>
                                    )}
                                </HGroup>

                                <TeachingMaterialCoverFlow
                                    teachingMaterials={teachingMaterials}
                                    schoolLevelGuid={orderContext.school_level}
                                    name='article_orderlines'
                                />
                                <Span align='center' color='black' size='sm'>
                                    {translations?.multiple_possible}
                                </Span>

                                <Hr />
                            </>
                        )}

                        {areCateringArticlesAvailable(
                            street,
                            orderContext.exposition_orderlines || []
                        ) &&
                            street.catering_articles.length > 0 && (
                                <>
                                    <HGroup>
                                        <H2 align='center'>
                                            {translations?.complete_with_food}
                                        </H2>
                                    </HGroup>
                                    <CateringArticleProductCards name='exposition_orderlines' />
                                </>
                            )}
                    </Content>
                </Form>
            )}
        </div>
    );
};
