/**
 * Formats a Date object.
 * @example `formatDate(date);` // "1988-08-02"
 * @param date
 * @param format "iso" or a language identifier. E.g.: "iso" or "nl".
 * @return {string}
 */
export const formatDate = (date: Date, format = 'iso'): string => {
    if (format === 'iso') {
        const YYYY = date.getFullYear();
        const MM = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-indexed
        const DD = String(date.getDate()).padStart(2, '0');
        return `${YYYY}-${MM}-${DD}`;
    }
    return new Intl.DateTimeFormat(format, {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
    }).format(date);
};
