import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import useSWR from 'swr';

import { useStreetContext } from 'lib/api/StreetContext';

import { Faq } from 'components/Faq/Faq';
import { Arrow } from 'components/Icons/Arrow/Arrow';
import { Content } from 'components/Layout/Content/Content';
import { Span } from 'components/Typography/Span/Span';

import { Button } from '../../components/Button/Button';
import { ButtonLink } from '../../components/Button/ButtonLink';
import { Spinner } from '../../components/Spinner/Spinner';
import { H2 } from '../../components/Typography/H2/H2';
import { HGroup } from '../../components/Typography/HGroup/HGroup';
import { useOrderContext } from '../../lib/api/OrderContext';
import { fetchOrder } from '../../lib/api/api';
import { useModalContext } from '../../lib/notification/ModalContext';
import { getPathByRouteId } from '../../lib/routes/routes';
import { useLanguage } from '../../lib/routes/useLanguage';
import { ICreatedOrder, IIncompleteOrder } from '../../lib/types/api';
import styles from './Confirmation.module.css';

export const Confirmation = () => {
    // Application context.
    const navigate = useNavigate();
    const language = useLanguage();
    const { orderId } = useParams<{ orderId: string }>();
    const [, setModalContext] = useModalContext();

    // Specific order and street state.
    const [orderContext, setOrderContext] = useOrderContext();
    const [streetContext] = useStreetContext();
    const translations = streetContext?.general_text;
    const faqQuestions = streetContext?.general_faq.questions || [];

    //
    // Data fetching.
    //

    // Fetch order based on `language` and `orderId`.
    const { error, isLoading } = useSWR(
        [language, orderId],
        ([language, orderId]) =>
            fetchOrder(language, orderId as string)
                .then((data) => {
                    setOrderContext({
                        school_details: data.school_details,
                        school_level: data.school_level,
                        school_type: data.school_type,
                        invoice_download: data.invoice_download,
                        rcx_session_id: data.rcx_session_id,
                    });
                })
                .catch((e) =>
                    setModalContext({
                        title: translations?.main_error || '',
                        body: e.message,
                        type: 'danger',
                    })
                ),
        {
            revalidateOnFocus: false,
        }
    );

    //
    // Loading and error rendering.
    //

    // Exit early when still loading or unable to render form.
    if (isLoading || error || !orderContext || !streetContext) {
        return (
            <main className={styles.change}>
                {isLoading && <Spinner />}
                {error && (
                    <Span color='danger' size='md'>
                        {(error?.toFormattedString && error?.toFormattedString()) ||
                            translations?.general_error ||
                            ''}
                    </Span>
                )}
            </main>
        );
    }

    //
    // Page rendering.
    //

    /**
     * Get called when the new booking button is clicked.
     */
    const onNewBookingClick: React.EventHandler<React.SyntheticEvent> = () => {
        const path = getPathByRouteId('info', { language }) || '/';

        // Clears the order context right before navigating to the confirmation path.
        const partiallyClearedOrderContext: IIncompleteOrder = {
            school_details: orderContext.school_details,
            school_level: orderContext.school_level,
            school_type: orderContext.school_type,
        };
        setOrderContext(partiallyClearedOrderContext);
        navigate(path);
    };

    return (
        <main className={styles.confirmation}>
            <Content size='small'>
                <HGroup>
                    <H2 align='center' size='6xl'>
                        {translations?.thank_you}
                    </H2>
                    <Span align={'center'} size='3xl'>
                        {translations?.tickets_emailed}
                    </Span>
                </HGroup>
                <div className={styles.buttons}>
                    <ButtonLink
                        color='normal'
                        fontSize='2xl'
                        fill='normal'
                        href={(orderContext as ICreatedOrder).invoice_download}
                        size='large'
                        bold={false}
                    >
                        {translations?.download_confirmation}
                    </ButtonLink>
                    <Button
                        color='normal'
                        fontSize='2xl'
                        fill='normal'
                        onClick={onNewBookingClick}
                        size='large'
                        bold={false}
                    >
                        {translations?.new_booking}
                    </Button>
                    <ButtonLink
                        color='normal'
                        fontSize='2xl'
                        fill={false}
                        href={
                            getPathByRouteId('change_order', {
                                language,
                                orderId: (orderContext as ICreatedOrder).rcx_session_id,
                            }) || '/'
                        }
                    >
                        <div className={styles.changeBookingButton}>
                            <Arrow />
                            <Span size='2xl'>{translations?.change_booking}</Span>
                        </div>
                    </ButtonLink>
                </div>
            </Content>
            {faqQuestions.length > 0 && (
                <Faq faqQuestions={faqQuestions} title={translations?.faq || ''} />
            )}
        </main>
    );
};
