import clsx from 'clsx';
import React from 'react';

import styles from './CircleIcon.module.css';

export interface ICircleIconProps {
    icon: React.ReactElement;
    onClick?: () => void;
    available: boolean | null;
}

export const CircleIcon = (props: ICircleIconProps) => {
    const { icon, onClick, available } = props;
    return (
        <div className={styles.circleIcon} onClick={onClick}>
            {icon}
            <div
                className={clsx(
                    styles.circleIcon__available,
                    available === true && styles['circleIcon__available--true'],
                    available === false && styles['circleIcon__available--false'],
                    available === null && styles['circleIcon__available--not_applied']
                )}
            ></div>
        </div>
    );
};
