import { useEffect } from 'react';
import TagManager, { DataLayerArgs } from 'react-gtm-module';

declare global {
    interface Window {
        [key: string]: any;

        CookieConsent?: {
            consent?: {
                statistics: boolean;
            };
        };
    }
}

const GTM_ID = process.env.REACT_APP_GTM_ID;
let gtmEventQueue: DataLayerArgs[] = [];

if (GTM_ID) {
    initializeGTM(GTM_ID);

    // This disables the GTM tracking until the user has given consent.
    gtmLog('Initially disabling GTM');
    window[`ga-disable-${GTM_ID}`] = true;
}

export const useGtm = () => {
    useEffect(() => {
        const handleConsent = () => {
            const isGTMEnabled = Boolean(window.CookieConsent?.consent?.statistics);

            if (isGTMEnabled) {
                gtmLog('User requested GTM to be enabled, processing event queue');
                window[`ga-disable-${GTM_ID}`] = false;
                gtmEventQueue.forEach((event) => gtmSendDataLayer(event));
                gtmEventQueue = [];
            } else {
                gtmLog('User requested GTM to be disabled');
                window[`ga-disable-${GTM_ID}`] = true;
            }
        };

        // The reason for using an event listener here, as opposed to simply
        // checking the cookieConsent state, is that Cookiebot loads asynchronously.
        // The event listener ensures that we respond dynamically to the user's
        // consent as soon as it's available, which might not be the case at the
        // initial render of the component.
        window.addEventListener('CookiebotOnAccept', handleConsent);

        // Cleanup the event listener
        return () => {
            window.removeEventListener('CookiebotOnAccept', handleConsent);
        };
    }, [GTM_ID]);
};

/**
 * Initializes GTM.
 * @param gtmId
 */
export function initializeGTM(gtmId: string) {
    if (isGTMInitialized()) {
        gtmLog('GTM already initialized!');
        return;
    }
    document.documentElement.dataset.gtmInitialized = 'true';
    gtmLog('Initializing GTM with id:', gtmId);

    TagManager.initialize({
        gtmId,
    });
}

/**
 * Returns whether GTM is initialized.
 */
export function isGTMInitialized(): boolean {
    return Boolean(document.documentElement.dataset.gtmInitialized);
}

/**
 * Either sends the dataLayer event to GTM or queues it for later.
 * @param dataLayer
 */
export function gtmDataLayer(dataLayer: DataLayerArgs): void {
    const isGTMEnabled = Boolean(window.CookieConsent?.consent?.statistics);
    if (!isGTMEnabled) {
        gtmQueueDataLayer(dataLayer);
    } else {
        gtmSendDataLayer(dataLayer);
    }
}

/**
 * Sends the dataLayer event to GTM.
 * @param dataLayer
 * @private
 */
function gtmSendDataLayer(dataLayer: DataLayerArgs) {
    TagManager.dataLayer(dataLayer);
    gtmLog('Datalayer event sent:', dataLayer);
}

/**
 * Queues the dataLayer event for later.
 * @param dataLayer
 * @private
 */
function gtmQueueDataLayer(dataLayer: DataLayerArgs) {
    gtmEventQueue.push(dataLayer);
    gtmLog('Datalayer event queued:', dataLayer);
}

/**
 * Logs GTM related debug message if process.env.REACT_APP_GTM_DEBUG is truthy.
 * @param messages
 */
export function gtmLog(...messages: unknown[]) {
    if (!process.env.REACT_APP_GTM_DEBUG) {
        return;
    }
    console.debug('GTM:', ...messages);
}
