import React from 'react';

import styles from './Info.module.css';

export interface IInfoProps {
    [index: string]: unknown;
}

export const Info: React.FC<IInfoProps> = ({ ...props }) => {
    return (
        <svg
            className={styles.info}
            xmlns='http://www.w3.org/2000/svg'
            viewBox='0 0 20 20'
            {...props}
        >
            <g stroke='currentColor'>
                <path
                    d='m10.001 9.2005v6.7995'
                    strokeLinecap='round'
                    strokeLinejoin='round'
                />
                <path
                    d='m10.831 4.8296c0 0.45743-0.3716 0.82956-0.83141 0.82956-0.45992 0-0.83136-0.37213-0.83136-0.82956s0.37144-0.82956 0.83136-0.82956c0.45981 0 0.83141 0.37213 0.83141 0.82956z'
                    strokeLinecap='butt'
                    strokeLinejoin='miter'
                />
            </g>
        </svg>
    );
};
