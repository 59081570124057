import { ICreatedOrder, IOrder, IStreetData } from 'lib/types/api';
import { IDataLayer } from 'lib/types/gtm';
import {
    compileGtmOrderLinesForUpsell,
    compileGtmOrderLinesForVenues,
} from 'lib/utils/gtm';

import { gtmDataLayer } from '../../lib/utils/useGtm';

interface IHandleGtmClickNextProps {
    /* The street Context used */
    street: IStreetData;

    /* The exposition order lines */
    expositionOrderLines: IOrder['exposition_orderlines'];

    /* The article order lines */
    articleOrderLines: IOrder['article_orderlines'];

    education_newsletter?: boolean;
    micropia_newsletter?: boolean;
    artis_newsletter?: boolean;
    grootemuseum_newsletter?: boolean;
}

export const handleGtmClickReserve = ({
    street,
    expositionOrderLines,
    articleOrderLines,
    education_newsletter,
    micropia_newsletter,
    artis_newsletter,
    grootemuseum_newsletter,
}: IHandleGtmClickNextProps) => {
    const venueItems = compileGtmOrderLinesForVenues(street, expositionOrderLines);
    const upsellItems = compileGtmOrderLinesForUpsell({
        street,
        expositionOrderLines,
        articleOrderLines,
    });

    const items = [...venueItems, ...upsellItems];
    const newsletterToLabel: {
        [key: string]: string;
    } = {
        education_newsletter: 'Onderwijs nieuws',
        micropia_newsletter: 'Micropia nieuws',
        artis_newsletter: 'Artis nieuws',
        grootemuseum_newsletter: 'Grootemuseum nieuws',
    };
    const newsletters: {
        [key: string]: boolean | undefined;
    } = {
        education_newsletter,
        micropia_newsletter,
        artis_newsletter,
        grootemuseum_newsletter,
    };

    const combinedNewsletterOptin =
        Object.keys(newsletterToLabel)
            .filter((key) => newsletters[key])
            .map((key) => newsletterToLabel[key])
            .join('|') || '';

    const dataLayer: IDataLayer = {
        event: 'checkout',
        checkout_funnel_name: 'ticketstraat_onderwijs',
        checkout_stage_number: '6',
        checkout_stage_name: 'gegevens',
        newsletter_optin: combinedNewsletterOptin,
        value: items
            .reduce((acc, orderLine) => {
                return acc + Number(orderLine.price) * parseInt(orderLine.quantity);
            }, 0)
            .toFixed(2),
        items,
    };

    gtmDataLayer({
        dataLayer,
    });
};

interface IHandleGtmOrderCreatedProps {
    /* The created order */
    createdOrder: ICreatedOrder;

    /* The street Context used */
    street: IStreetData;
}

export const handleGtmOrderCreated = ({
    createdOrder,
    street,
}: IHandleGtmOrderCreatedProps) => {
    const venueItems = compileGtmOrderLinesForVenues(
        street,
        createdOrder.exposition_orderlines
    );
    const upsellItems = compileGtmOrderLinesForUpsell({
        street,
        expositionOrderLines: createdOrder.exposition_orderlines,
        articleOrderLines: createdOrder.article_orderlines,
    });

    const items = [...venueItems, ...upsellItems];

    const dataLayer: IDataLayer = {
        event: 'purchase',
        transaction_id: createdOrder.rcx_session_id,
        payment_method: createdOrder.payment,
        value: items
            .reduce((acc, orderLine) => {
                return acc + Number(orderLine.price) * parseInt(orderLine.quantity);
            }, 0)
            .toFixed(2),
        items,
    };

    gtmDataLayer({
        dataLayer,
    });
};
