import React from 'react';
import { CSSTransition, TransitionGroup } from 'react-transition-group';

import { IRouteContext } from 'lib/routes/RouteContext';

import { LeftContainer } from '../LeftContainer/LeftContainer';
import { RightContainer } from '../RightContainer/RightContainer';
import styles from './Layout.module.css';

export interface ILayoutProps {
    /** The route context. */
    context: IRouteContext;

    /** Unique key for the current location, used as key for <CSSTransition/>. */
    locationKey: string;

    /** Left column content. */
    children: React.ReactNode;
}

export const Layout: React.FC<ILayoutProps> = ({ children, context, locationKey }) => {
    return (
        <TransitionGroup>
            <CSSTransition
                key={locationKey}
                classNames={{
                    appear: styles['layout-appear'],
                    appearActive: styles['layout--appear-active'],
                    appearDone: styles['layout--appear-done'],
                    enter: styles['layout--enter'],
                    enterActive: styles['layout--enter-active'],
                    enterDone: styles['layout--enter-done'],
                    exit: styles['layout--exit'],
                    exitActive: styles['layout--exit-active'],
                    exitDone: styles['layout--exit-done'],
                }}
                appear={true}
                timeout={{ appear: 0, enter: 1000, exit: 1000 }}
            >
                <div className={styles.layout}>
                    <LeftContainer>{children}</LeftContainer>
                    <RightContainer context={context} />
                </div>
            </CSSTransition>
        </TransitionGroup>
    );
};
