import clsx from 'clsx';

import { useOrderContext } from 'lib/api/OrderContext';
import { useStreetContext } from 'lib/api/StreetContext';
import {
    calculateGivenPriceByContext,
    compileArticleOrderLines,
    compileOrderLines,
    getCateringArticlesByOrderLines,
    getLessonByExpositionGuid,
    getVenueByExpositionGuid,
} from 'lib/api/context';
import { formatDate } from 'lib/format/date';
import { ucfirst } from 'lib/format/string';
import { useLanguage } from 'lib/routes/useLanguage';
import { IOrder } from 'lib/types/api';

import { H2 } from 'components/Typography/H2/H2';
import { Span } from 'components/Typography/Span/Span';

import { formatPrice } from '../../../../lib/i18n/i18n';
import styles from './ShowInfo.module.css';

export const ShowInfo = () => {
    const language = useLanguage();
    const [street] = useStreetContext();
    const translations = street?.general_text;
    const [orderContext] = useOrderContext();

    if (!street) {
        return null;
    }

    const articleOrderLines = orderContext.article_orderlines || [];
    const orderLines = orderContext.exposition_orderlines || [];
    const venueOrderLines = orderLines.filter((o) =>
        getVenueByExpositionGuid(street, o.exposition || '')
    );
    const lessonOrderLines = orderLines.filter((o) =>
        getLessonByExpositionGuid(street, o.exposition || '')
    );
    const cateringOrderLines = orderLines.filter(
        (o) => getCateringArticlesByOrderLines(street, [o]).length
    );
    const hasSchoolDetails = Boolean(orderContext.school_details?.name);
    const hasGroupDetails = Boolean(orderContext.group_name);
    const hasDetails = hasSchoolDetails || hasGroupDetails;

    if (!hasDetails) {
        return null;
    }

    return (
        <div className={styles.showInfo}>
            <H2>{translations?.summary}</H2>
            {hasSchoolDetails && (
                <div className={clsx(styles.showInfo__splitbox)}>
                    <div>
                        <div className={styles.showInfo__line}>
                            <div className={styles.showInfo__title}>
                                <Span color='dark-gray' size='sm'>
                                    {ucfirst(translations?.school_singular || '')}
                                </Span>
                            </div>
                        </div>

                        <div className={styles.showInfo__line}>
                            <Span size={'md'} bold>
                                {orderContext.school_details?.name}
                                <br />
                            </Span>
                        </div>

                        <div className={styles.showInfo__line}>
                            <Span size='sm'>
                                {orderContext.school_details?.street}&nbsp;
                                {orderContext.school_details?.number}
                            </Span>
                        </div>

                        <div className={styles.showInfo__line}>
                            <Span size='sm'>
                                {orderContext.school_details?.postal_code}
                            </Span>
                            &nbsp;
                            <Span size='sm' wrap='nowrap'>
                                {orderContext.school_details?.city}
                            </Span>
                        </div>

                        <div className={styles.showInfo__line}>
                            <Span size='sm'>{orderContext.school_details?.phone}</Span>
                        </div>
                    </div>

                    {orderContext.date && (
                        <div>
                            <div className={styles.showInfo__line}>
                                <div className={styles.showInfo__title}>
                                    <Span color='dark-gray' size='sm'>
                                        {translations?.date}
                                    </Span>
                                </div>
                            </div>

                            <div className={styles.showInfo__line}>
                                <Span size={'md'} bold>
                                    {formatDate(new Date(orderContext.date), language)}
                                    <br />
                                </Span>
                            </div>
                        </div>
                    )}
                </div>
            )}

            {hasGroupDetails && (
                <div className={styles.showInfo__box}>
                    <div className={styles.showInfo__title}>
                        <Span size='sm' bold>
                            {ucfirst(translations?.group_singular || '')}
                        </Span>
                        {orderContext.group_name && (
                            <>
                                &nbsp;
                                <Span size='sm' bold>
                                    &quot;{orderContext.group_name}&quot;
                                </Span>
                            </>
                        )}
                    </div>

                    {street &&
                        venueOrderLines.length > 0 &&
                        compileOrderLines(street, venueOrderLines).map((c) => (
                            <div key={c.guid} className={styles.showInfo__orderline}>
                                <Span size='sm'>{c.description}</Span>
                                <Span size='sm'>{formatPrice(c.total, language)}</Span>
                            </div>
                        ))}

                    {street && lessonOrderLines.length > 0 && (
                        <>
                            <div className={styles.showInfo__title}>
                                <Span color='dark-gray' size='sm'>
                                    {ucfirst(translations?.lessons || '')}
                                </Span>
                            </div>

                            {compileOrderLines(street, lessonOrderLines).map((c) => (
                                <div key={c.guid} className={styles.showInfo__orderline}>
                                    <Span size='sm'>{c.description}</Span>
                                    <Span size='sm'>
                                        {formatPrice(c.total, language)}
                                    </Span>
                                </div>
                            ))}
                        </>
                    )}

                    {street && articleOrderLines.length > 0 && (
                        <>
                            <div className={styles.showInfo__title}>
                                <Span color='dark-gray' size='sm'>
                                    {ucfirst(translations?.teaching_materials || '')}
                                </Span>
                            </div>

                            {compileArticleOrderLines(street, articleOrderLines).map(
                                (c) => (
                                    <div
                                        key={c.guid}
                                        className={styles.showInfo__orderline}
                                    >
                                        <Span size='sm'>{c.description}</Span>
                                        <Span size='sm'>
                                            {formatPrice(c.total, language)}
                                        </Span>
                                    </div>
                                )
                            )}
                        </>
                    )}

                    {street && cateringOrderLines.length > 0 && (
                        <>
                            <div className={styles.showInfo__title}>
                                <Span color='dark-gray' size='sm'>
                                    {ucfirst(translations?.food || '')}
                                </Span>
                            </div>

                            {compileOrderLines(street, cateringOrderLines).map((c) => (
                                <div key={c.guid} className={styles.showInfo__orderline}>
                                    <Span size='sm'>{c.description}</Span>
                                    <Span size='sm'>
                                        {formatPrice(c.total, language)}
                                    </Span>
                                </div>
                            ))}
                        </>
                    )}

                    {street && venueOrderLines.length > 0 && (
                        <>
                            <hr className={styles.showInfo__divider} />
                            <div className={styles.showInfo__orderline}>
                                <Span size='sm'>{translations?.total}</Span>
                                <Span bold={true} size='sm'>
                                    {formatPrice(
                                        calculateGivenPriceByContext(
                                            street,
                                            orderContext as IOrder
                                        ),
                                        language
                                    )}
                                </Span>
                            </div>
                        </>
                    )}
                </div>
            )}
        </div>
    );
};
