import React from 'react';

import { CardInformationItem } from '../../Cards/CardInformationItem/CardInformationItem';
import { Span } from '../../Typography/Span/Span';
import styles from './FlowCard.module.css';

export interface IFlowCardProps {
    /** Path to a background image. */
    image: string;

    /** The title is shown in the middle with a slight top offset */
    title: string;

    /** String representation of the capacity. */
    capacity?: string;

    /** String representation of the duration. */
    duration?: string;

    /** The levels (VMBO, HAVO etc.) */
    levels?: string[];

    /** The location name is shown under the title in a row */
    locationName?: string;

    /** The footer text. */
    footer?: React.ReactNode;

    /** Quantity fields. */
    children: React.ReactNode;
}

/**
 * The FlowCard is a card that is used for a "flowing" motion. It is used in the `CoverFlow` component.
 * It has a background image, a logo, a checkbox, a title, some information, a quantity field and a
 * text that contains a link to more information.
 */
export const FlowCard: React.FC<IFlowCardProps> = ({
    image,
    footer,
    levels,
    locationName,
    capacity,
    duration,
    title,
    children,
}) => (
    <div className={styles.flowcard} style={{ backgroundImage: `url('${image}')` }}>
        <div className={styles.flowcard__body}>
            <header className={styles.flowcard__header}>
                <div className={styles.flowcard__logo}>
                    <img src='/images/artis-logo.png' alt='Flow Card Logo' />
                </div>
                <Span align='center' size='6xl' color='white' bold>
                    {title}
                </Span>
                <div className={styles.flowcard__infobar}>
                    {duration && (
                        <CardInformationItem
                            iconUrl='/images/clock-icon.svg'
                            text={duration}
                        />
                    )}
                    {capacity && (
                        <CardInformationItem
                            iconUrl='/images/capacity-icon.svg'
                            text={capacity}
                        />
                    )}
                    {locationName && (
                        <CardInformationItem
                            iconUrl='/images/location-icon.svg'
                            text={locationName}
                        />
                    )}
                    {levels &&
                        levels.map((l) => (
                            <CardInformationItem
                                key={l}
                                iconUrl='/images/level-icon.svg'
                                text={l}
                            />
                        ))}
                </div>
            </header>
            <div className={styles.flowcard__controls}>{children}</div>
            <footer className={styles.flowcard__footer}>{footer}</footer>
        </div>
    </div>
);
