import React from 'react';

import { useOrderContext } from 'lib/api/OrderContext';

import { Span } from 'components/Typography/Span/Span';

import styles from './SchoolInfo.module.css';

export const SchoolInfo: React.FC = ({ ...props }) => {
    const [orderContext] = useOrderContext();
    return (
        <div className={styles.schoolInfo} {...props}>
            <div className={styles.schoolHeader}>
                <Span size='lg' bold>
                    {orderContext.school_details?.street}{' '}
                    {orderContext.school_details?.number}
                </Span>
            </div>
            <Span size='sm'>
                {orderContext.school_details?.postal_code}{' '}
                {orderContext.school_details?.city}
            </Span>
        </div>
    );
};
