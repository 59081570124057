import clsx from 'clsx';
import React, { ChangeEvent, useId } from 'react';

import { Span } from '../../Typography/Span/Span';
import { FormError } from '../FormError/FormError';
import { IOptionallyLabeledFieldProps } from '../IFormFieldProps';
import styles from './Checkbox.module.css';

export interface ICheckboxProps
    extends IOptionallyLabeledFieldProps<string, ChangeEvent<HTMLInputElement>> {
    /** An additional label for more detailed content. */
    description?: string;

    /** Whether the checkbox is checked. */
    checked?: boolean;

    /** linkText will show under the checkbox's main text */
    linkText?: string;

    /** linkHref will be the href for the linkText */
    linkHref?: string;

    /** Remaining props get passed to `<input />`. */
    [index: string]: any;
}

export const Checkbox: React.FC<ICheckboxProps> = ({
    checked,
    description,
    disabled,
    errorMessage,
    id,
    invalid,
    label,
    name,
    onChange,
    required,
    value,
    linkHref,
    linkText,
    ...props
}) => {
    const fieldId = id || useId();
    const errorId = invalid ? `${fieldId}-errormessage` : undefined;
    const labelId = `${fieldId}-label`;
    return (
        <div className={styles.checkbox}>
            <input
                checked={checked}
                className={clsx(styles.checkbox__input, {
                    [styles['checkbox__input--invalid']]: disabled,
                })}
                disabled={disabled}
                id={fieldId}
                name={name}
                onChange={onChange}
                required={required}
                type='checkbox'
                value={value}
                aria-describedby={invalid ? errorId : undefined}
                aria-invalid={invalid}
                aria-labelledby={(label && labelId) as string | undefined}
                {...props}
            />
            {label && (
                <label className={styles.checkbox__label} id={labelId} htmlFor={fieldId}>
                    <Span bold size='lg'>
                        <>
                            {label}
                            {required && '*'}
                        </>
                    </Span>
                    <Span size='sm'>{description?.trim()}</Span>
                    {linkHref && linkText && (
                        <Span color='gray' size='md'>
                            <a href={linkHref} rel='noreferrer' target='_blank'>
                                {linkText}
                            </a>
                        </Span>
                    )}
                </label>
            )}
            {invalid && <FormError id={errorId as string}>{errorMessage}</FormError>}
        </div>
    );
};
