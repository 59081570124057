import { RouteProps } from 'react-router-dom';
import { ZodSchema } from 'zod';

import { ROUTES } from '../../routes';
import { IRouteContext } from './RouteContext';

// The route ids available. We strongly type these due to consistancy with the background images.
export type RouteId =
    | 'home'
    | 'info'
    | 'venue'
    | 'upsell'
    | 'contact'
    | 'confirmation'
    | 'change_order'
    | 'change_order_confirmation';

/**
 * We extend the react-router's `RouteProps` with a custom typed `id` field.
 * This later enforces strongly typed `id` fields in the ROUTES object.
 */
export interface IRouteProps extends Omit<RouteProps, 'path'> {
    id: RouteId;
    path: string;
}

/**
 * combined `RouteProps` with `IRouteContext` allowing additional data to be passed.
 */
export interface IRoute {
    /** The props to pass to the `<Route />`. */
    routeProps: IRouteProps;

    /** Additional context to be made available by `<Layout />` as `RouteContext`. */
    context: IRouteContext;

    formSchema?: ZodSchema;

    /** Id (in `routeProps`) of previous route. */
    previous?: RouteId;

    /** Id (in `routeProps`) of next route. */
    next?: RouteId;
}

/**
 * Replaces placeholder params with appropriate value in params.
 * @param path
 * @param params
 */
export const getFormattedPath = (path: string, params: Record<string, string>) => {
    return Object.entries(params).reduce((acc, [key, value]) => {
        const regExp = new RegExp(`:${key}\\??`);
        return acc.replace(regExp, value);
    }, path);
};

/**
 * Returns the previous route path.
 * Previous route should be specified by `previous` entry on the `IRoute`.
 * @param id The value of `id` in the `routeProps` entry.
 * @param params The params apply to the path.
 * @throws Error if route is not found.
 * @throws Error if route does not specify a previous route.
 * @return The path the previous route or null.
 */
export const getPreviousPathByRouteById = (
    id: RouteId,
    params: Record<string, string> = {}
): string | null => {
    return getFormattedPath(getPreviousRouteById(id).routeProps.path, params) || null;
};

/**
 * Returns the route path.
 * @param id The value of `id` in the `routeProps` entry.
 * @param params The params apply to the path.
 * @throws Error if route is not found.
 * @return The path or null.
 */
export const getPathByRouteId = (
    id: RouteId,
    params: Record<string, string> = {}
): string | null => {
    return getFormattedPath(getRouteById(id).routeProps.path, params) || null;
};

/**
 * Returns the next route path.
 * Next route should be specified by `next` entry on the `IRoute`.
 * @param id The value of `id` in the `routeProps` entry.
 * @param params The params apply to the path.
 * @throws Error if route is not found.
 * @throws Error if route does not specify a next route.
 * @return The path the next route or null.
 */
export const getNextPathByRouteById = (
    id: RouteId,
    params: Record<string, string> = {}
): string | null => {
    return getFormattedPath(getNextRouteById(id).routeProps.path, params) || null;
};

/**
 * Returns the previous routes.
 * Previous route should be specified by `previous` entry on each `IRoute`.
 * @param id The value of `id` in the `routeProps` entry.
 * @throws Error if route is not found.
 * @throws Error if route does not specify a previous route.
 * @return {IRoute[]} First `IRoute` in path (closest to "root") is first item in array.
 */
export const getPreviousRoutesById = (id: RouteId): IRoute[] => {
    let iterationRoute: IRoute = getRouteById(id);
    const routes: IRoute[] = [];

    while (iterationRoute.previous) {
        iterationRoute = getPreviousRouteById(iterationRoute.routeProps.id);
        routes.push(iterationRoute);
    }

    return routes.reverse();
};

/**
 * Returns the previous route.
 * Previous route should be specified by `previous` entry on the `IRoute`.
 * @param id The value of `id` in the `routeProps` entry.
 * @throws Error if route is not found.
 * @throws Error if route does not specify a previous route.
 * @return The associated `IRoute`.
 */
export const getPreviousRouteById = (id: RouteId): IRoute => {
    const previous = getRouteById(id).previous;
    if (!previous) {
        throw new Error(`Route with id "${id}" does not specify a previous route!`);
    }
    return getRouteById(previous);
};

/**
 * Returns the next route.
 * Next route should be specified by `next` entry on the `IRoute`.
 * @param id The value of `id` in the `routeProps` entry.
 * @throws Error if route is not found.
 * @throws Error if route does not specify a next route.
 * @return The associated `IRoute`.
 */
export const getNextRouteById = (id: RouteId): IRoute => {
    const next = getRouteById(id).next;
    if (!next) {
        throw new Error(`Route with id "${id}" does not specify a next route!`);
    }
    return getRouteById(next);
};

/**
 * Returns the `IRoute` in `ROUTES` specified by `id`.
 * @param id The value of `id` in the `routeProps` entry.
 * @throws Error if route is not found.
 * @return The associated `IRoute`.
 */
export const getRouteById = (id: RouteId): IRoute => {
    const route = ROUTES.find((r) => r.routeProps.id === id);
    if (!route) {
        throw new Error(`Route with id "${id}" not found!`);
    }
    return route;
};
