import {
    ICompiledOrderLine,
    compileArticleOrderLines,
    compileOrderLine,
    compileOrderLines,
    filterOrderLinesByExpositionGuid,
    filterOrderLinesByLessons,
    filterOrderLinesByVenues,
    getLessonsByVenuesInOrderLines,
    getPriceByGuid,
    getVenueByExpositionGuid,
} from 'lib/api/context';
import { IOrder, IStreetData } from 'lib/types/api';
import { IGtmItem } from 'lib/types/gtm';

/**
 * Compiles the order lines to the expected format expected to send to GTM as the items array
 * This can also be used later on for the order confirmation, as we'll need these items again.
 * @param street The street Context used
 * @param expositionOrderLines The exposition order lines
 * @param articleOrderLines The article order lines
 * @returns The compiled order lines
 */
export const compileGtmOrderLinesForVenues = (
    street: IStreetData,
    expositionOrderLines: IOrder['exposition_orderlines']
): IGtmItem[] => {
    /* We make sure that we do indeed only have venue order lines */
    const filteredOrderLines = filterOrderLinesByVenues(
        expositionOrderLines,
        street.venues
    );

    /* We map over the order lines and compile them to the expected format */
    return filteredOrderLines.map((orderLine): IGtmItem => {
        const compiledOrderLine = compileOrderLine(street, orderLine);
        const venue = getVenueByExpositionGuid(street, orderLine.exposition || '');
        const priceGroup = getPriceByGuid(street, orderLine.exposition_price || '');
        return {
            item_id: venue?.exposition?.guid || '',
            item_name: priceGroup?.group_name || '', // This isn't exactly what's asked in the design, but I see no other way to know if a price group is for a "leerling" or "docent" to get the format they want of "Micropia | Docent" or "Micropia | Leerling"
            item_brand: venue?.park.name || '',
            item_category: 'ticket_onderwijs',
            item_category2: null,
            price: compiledOrderLine.price.toFixed(2),
            quantity: orderLine.amount?.toString() || '0',
            discount: null,
            coupon: null,
        };
    });
};

/**
 * Compiles the order lines to the expected format expected to send to GTM as the items array
 * This can also be used later on for the order confirmation, as we'll need these items again.
 * @param street The street Context used
 * @param expositionOrderLines The exposition order lines
 * @param articleOrderLines The article order lines
 * @returns The compiled order lines
 */
export const compileGtmOrderLinesForUpsell = ({
    street,
    expositionOrderLines,
    articleOrderLines,
}: {
    /* The street Context used */
    street: IStreetData;

    /* The exposition order lines */
    expositionOrderLines: IOrder['exposition_orderlines'];

    /* The article order lines */
    articleOrderLines: IOrder['article_orderlines'];
}): IGtmItem[] => {
    /* We want to filter the order lines by type, so we can compile them separately */
    const lessons = getLessonsByVenuesInOrderLines(street, expositionOrderLines);

    const lessonsOrderLines = filterOrderLinesByLessons(expositionOrderLines, lessons);
    const cateringOrderLines = filterOrderLinesByExpositionGuid(
        expositionOrderLines,
        street.catering_exposition_id
    );

    /* Compile the order lines so that we can easily use/read them out later */
    const compiledLessonOrderLines = compileOrderLines(street, lessonsOrderLines);
    const compiledCateringOrderLines = compileOrderLines(street, cateringOrderLines);
    const compiledArticleOrderLines = compileArticleOrderLines(street, articleOrderLines);
    const gtmLesson = compiledLessonOrderLines.map((orderLine) =>
        upsellOrderLineToGTM(orderLine, 'artis_les', orderLine.name)
    );
    const gtmCatering = compiledCateringOrderLines.map((orderLine) =>
        upsellOrderLineToGTM(orderLine, 'add_on', 'horeca_onderwijs')
    );
    const gtmArticle = compiledArticleOrderLines.map((orderLine) =>
        upsellOrderLineToGTM(orderLine, 'onderwijsmateriaal', orderLine.name)
    );

    const combinedGTMItems = [...gtmLesson, ...gtmCatering, ...gtmArticle];

    return combinedGTMItems;
};

const upsellOrderLineToGTM = (
    orderLine: ICompiledOrderLine,
    type: 'artis_les' | 'add_on' | 'onderwijsmateriaal',
    subType: string
): IGtmItem => ({
    item_id: orderLine.guid,
    item_name: orderLine.name,
    item_brand: 'artis_park',
    price: orderLine?.price.toFixed(2),
    quantity: orderLine.amount.toString(),
    discount: null,
    coupon: null,
    item_category: type,
    item_category2: subType,
});
