import React from 'react';

export interface IEmailIconProps {
    [key: string]: unknown;
}

export const EmailIcon: React.FC<IEmailIconProps> = ({ ...props }) => {
    return (
        <svg
            width='24'
            height='24'
            viewBox='0 0 24 24'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
            {...props}
        >
            <g clipPath='url(#clip0_1927_141)'>
                <path
                    d='M3.38574 5.53953H20.6133V17.7424C20.6133 17.9328 20.5377 18.1154 20.4031 18.25C20.2685 18.3846 20.0859 18.4602 19.8955 18.4602H4.10356C3.91318 18.4602 3.7306 18.3846 3.59599 18.25C3.46137 18.1154 3.38574 17.9328 3.38574 17.7424V5.53953Z'
                    stroke='black'
                    strokeWidth='1.6'
                    strokeLinecap='round'
                    strokeLinejoin='round'
                />
                <path
                    d='M20.6133 5.53953L11.9995 13.4355L3.38574 5.53953'
                    stroke='black'
                    strokeWidth='1.6'
                    strokeLinecap='round'
                    strokeLinejoin='round'
                />
            </g>
            <defs>
                <clipPath id='clip0_1927_141'>
                    <rect
                        width='22.9701'
                        height='22.9701'
                        fill='white'
                        transform='translate(0.514648 0.514709)'
                    />
                </clipPath>
            </defs>
        </svg>
    );
};
