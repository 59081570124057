import React from 'react';

import { Span } from '../../Typography/Span/Span';
import styles from './FormError.module.css';

export interface IFormErrorProps {
    /** Must be present and corresponding field should have aria-describedby set to the same value. */
    id: string;

    /** Error message. */
    children: React.ReactNode;
}

/**
 * Generic error component.
 */
export const FormError: React.FC<IFormErrorProps> = ({ children, id, ...props }) => {
    return (
        <label className={styles.formError} id={id} role='alert' {...props}>
            <Span size='sm'>{children}</Span>
        </label>
    );
};
