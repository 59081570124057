import clsx from 'clsx';
import React from 'react';

import { Sizes } from 'lib/types/styling';

import styles from './Span.module.css';

export interface ISpanProps {
    bold?: boolean;
    children?: React.ReactNode;
    size: Sizes;
    color?: 'black' | 'white' | 'primary' | 'danger' | 'gray' | 'dark-gray' | 'green';
    align?: 'start' | 'center' | 'end';
    wrap?: 'normal' | 'nowrap' | 'pre-line';
    [index: string]: unknown;
}

export const Span: React.FC<ISpanProps> = ({
    bold,
    children,
    size,
    color,
    align,
    wrap = 'normal',
    ...props
}) => {
    return (
        <span
            className={clsx(
                styles.span,
                color && styles[`span--color-${color}`],
                bold && styles['span--bold'],
                size === 'sm' && styles['span--sm'],
                size === 'md' && styles['span--md'],
                size === 'lg' && styles['span--lg'],
                size === 'xl' && styles['span--xl'],
                size === '2xl' && styles['span--2xl'],
                size === '3xl' && styles['span--3xl'],
                size === '4xl' && styles['span--4xl'],
                size === '5xl' && styles['span--5xl'],
                size === '6xl' && styles['span--6xl'],
                align === 'start' && styles['span--align-start'],
                align === 'center' && styles['span--align-center'],
                align === 'end' && styles['span--align-end'],
                {
                    [styles[`span--wrap-${wrap}`]]: wrap,
                }
            )}
            {...props}
        >
            {children}
        </span>
    );
};
