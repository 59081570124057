import { ICreatedOrder, IOrder, ISchool, IStreetData, ITimeSlot } from 'lib/types/api';

/** The media origin. */
const MEDIA_ORIGIN =
    (process.env.STORYBOOK ? '/images/storybook' : process.env.REACT_APP_MEDIA_ORIGIN) ||
    'http://localhost:8000';

/** The API root url (without trailing slash). */
const API_ROOT = process.env.REACT_APP_API_ROOT || 'http://localhost:8000';

/** The API authentication token (must be present in admin and set as environment variable). */
const API_TOKEN = process.env.STORYBOOK
    ? process.env.STORYBOOK_APP_API_TOKEN
    : process.env.REACT_APP_API_TOKEN;

/**
 * Returns `Promise` for `StreetData`.
 * @param language Language, used for translations.
 * @param [uuid] Optional, the UUID of the street to fetch, defaults to `process.env.REACT_APP_STREET_UUID`
 * @param [abortSignal] `AbortController.signal` (dispatched by calling  AbortController.abort()).
 * @throws {Error} if request was not successful.
 * @throws {Error} if JSON response could not be parsed.
 * @returns `Promise` for `StreetData`.
 */
export const fetchStreet = async (
    language: string,
    uuid: string | undefined = undefined,
    abortSignal: AbortSignal | undefined = undefined
): Promise<IStreetData> => {
    const _uuid = uuid || '';
    return fetchEndpoint<IStreetData>(language, `streets/${_uuid}/`, {}, abortSignal);
};

/**
 * Returns `Promise` for `ISchool` results based on postal code search.
 * @param language Language, used for translations
 * @param [postalCode] the postal code to search schools by.
 * @param [abortSignal] `AbortController.signal` (dispatched by calling  AbortController.abort()).
 * @throws {Error} if request was not successful.
 * @throws {Error} if JSON response could not be parsed.
 * @returns `Promise` for `ISchool`.
 */
export const searchSchoolsByPostalCode = async (
    language: string,
    postalCode: string,
    abortSignal: AbortSignal | undefined = undefined
): Promise<ISchool[]> => {
    return fetchEndpoint<ISchool[]>(
        language,
        'schools/search/',
        {
            partial: 'true',
            postal_code: postalCode,
        },
        abortSignal
    );
};

/**
 * Returns `Promise` for `string` results indicating the dates that are soldout for any of the `guids`.
 * @param language Language, used for translations.
 * @param from The date from which to start searching.
 * @param guids The exposition guid's to check for.
 * @param [abortSignal] `AbortController.signal` (dispatched by calling  AbortController.abort()).
 * @throws {Error} if request was not successful.
 * @throws {Error} if JSON response could not be parsed.
 * @returns `Promise` for `ITimeSlot[]`.
 */
export const fetchSoldoutDates = async (
    language: string,
    from: string,
    guids: string[],
    abortSignal: AbortSignal | undefined = undefined
): Promise<string[]> => {
    return fetchEndpoint(
        language,
        `expositions/soldout/?expositions=${guids.join('&expositions=')}&from=${from}`,
        undefined, // We can't use params here due to the array format used by the backend/RCX.
        abortSignal
    );
};

/**
 * Returns `Promise` for `ITimeSlot` results based on the amount of students/teachers, the exposition's `guid` and the current day.
 * @param language Language, used for translations.
 * @param from The date from which to start searching.
 * @param guid The exposition's guid.
 * @param [abortSignal] `AbortController.signal` (dispatched by calling  AbortController.abort()).
 * @throws {Error} if request was not successful.
 * @throws {Error} if JSON response could not be parsed.
 * @returns `Promise` for `ITimeSlot[]`.
 */
export const fetchExpositionTimeslots = async (
    language: string,
    from: string,
    guid: string,
    abortSignal: AbortSignal | undefined = undefined
): Promise<ITimeSlot[]> => {
    // The backend systems are configured to respond with timeslots that have N amount of spots left, where N is > amount.
    // Amount refers to the amount of schools here, instead of the amount of persons. This is to prevent multiple
    // schools from checking it at once.
    // As the frontend allows a single school to book tickets, the amount is always 1.
    const amount = 1;

    return fetchEndpoint<ITimeSlot[]>(
        language,
        'expositions/timeslots/',
        {
            from,
            expositions: JSON.stringify({ guid, amount: String(amount) }),
        },
        abortSignal
    );
};

/**
 * Submits the order the API.
 * @param language Language, used for translations.
 * @param order
 * @param abortSignal
 */
export const createOrder = async (
    language: string,
    order: IOrder,
    abortSignal: AbortSignal | undefined = undefined
): Promise<ICreatedOrder> => {
    // Filter school guid if not an actual guid for validation puproses.
    const _order: IOrder = order.school_details.guid
        ? order
        : {
              ...order,
              school_details: { ...order.school_details, guid: undefined },
          };
    return fetchEndpoint(
        language,
        'orders/create/',
        {},
        abortSignal,
        'POST',
        JSON.stringify(_order)
    );
};

/**
 * Fetches an order
 * @param language
 * @param orderId
 * @param abortSignal
 */
export const fetchOrder = async (
    language: string,
    orderId: string,
    abortSignal: AbortSignal | undefined = undefined
) => fetchEndpoint<IOrder>(language, `orders/retrieve_order/${orderId}`, {}, abortSignal);

/**
 * Updates an order
 * @param language
 * @param order
 * @param orderId
 * @param abortSignal
 */
export const updateOrder = async (
    language: string,
    orderId: string,
    order: IOrder,
    abortSignal: AbortSignal | undefined = undefined
) =>
    fetchEndpoint<IOrder>(
        language,
        `orders/adjust_order/${orderId}`,
        {},
        abortSignal,
        'POST',
        JSON.stringify(order)
    );

/**
 * Updates an order
 * @param language
 * @param orderId
 * @param abortSignal
 */
export const cancelOrder = async (
    language: string,
    orderId: string,
    abortSignal: AbortSignal | undefined = undefined
) =>
    fetchEndpoint<IOrder>(
        language,
        `orders/cancel_order/${orderId}`,
        {},
        abortSignal,
        'DELETE'
    );

/**
 * Extends `Error` by allowing the `Response` to be set.
 */
export class RequestError extends Error {
    /** The error message. */
    message: string;

    /** The `Response` object. */
    response: Response;

    /** The resolved output of `Response.json()`. */
    data: unknown;

    /**
     * Constructor method.
     * @param statusText
     * @param response
     * @param data
     */
    constructor(statusText: string, response: Response, data: unknown) {
        super(statusText);
        this.message = statusText;
        this.response = response;
        this.data = data;
    }

    toRecord<T = unknown>(
        fields: string[] = Object.keys(this.data || {})
    ): Record<string, T> | undefined {
        try {
            const entries = Object.entries(this.data as Record<string, T>);
            const filteredEntries = entries.filter(([key]) => fields.includes(key));
            return Object.fromEntries(filteredEntries);
        } catch (e) {
            // Errors can't be extracted, fail silently.
        }
    }

    toFormattedString(fields: string[] = Object.keys(this.data || {})): string {
        const record = this.toRecord(fields);
        if (!record) {
            return '';
        }

        return Object.entries(record)
            .map((e) => e.join(': '))
            .join('\n');
    }
}

/**
 * Returns `Promise` for an Object containing API response from `endpoint`.
 * @param language Language, used for translations.
 * @param endpoint The endpoint path (without API_ROOT).
 * @param params The query params as `Object`.
 * @param [abortSignal] `AbortController.signal` (dispatched by calling  AbortController.abort()).
 * @param method
 * @param body
 * @throws {Error} if request was not successful.
 * @throws {Error} if JSON response could not be parsed.
 * @return `Promise` for Object containing API response from `endpoint`.
 */
const fetchEndpoint = async <T = unknown>(
    language: string,
    endpoint: string,
    params: Record<string, string> = {},
    abortSignal: AbortSignal | undefined = undefined,
    method: 'GET' | 'POST' | 'DELETE' = 'GET',
    body: string | Record<string, boolean | number | string> | undefined = undefined
): Promise<T> => {
    const queryString = new URLSearchParams(params).toString();
    const url = `${API_ROOT}/${language}/api/${endpoint}${
        queryString ? '?' + queryString : ''
    }`;

    // Validate that an API token is set.
    if (!API_TOKEN) {
        throw new Error(
            'API token not set! Please provide process.env.REACT_APP_API_TOKEN.'
        );
    }

    // Create the headers object, POST requests require the addtional "Content-Type" header to be set.
    const header = new Headers({
        Authorization: `Token ${API_TOKEN}`,
    });
    if (method === 'POST') {
        header.set('Content-Type', 'application/json');
    }

    // Fetch the url.
    const response = await fetch(url, {
        headers: header,
        signal: abortSignal,
        method: method,
        body: body ? (typeof body === 'string' ? body : JSON.stringify(body)) : undefined,
    });

    // if the server response is not ok, throw an error
    if (!response.ok) {
        let errorData;
        let errorMessage;

        try {
            // Format the server response into an error message.
            errorData = await response.json();
            const { detail, message, ...errors } = errorData;
            const responseMessage = detail || message; // Generic error message can be in detail or message.
            const joinedErrors = Object.values(errors).flatMap((v) => v); // Create a flat array of errors.

            errorMessage = [responseMessage, ...joinedErrors]
                .filter((v) => v)
                .join('\n\n');
        } catch (e) {
            // Server did not or cannot provide error message.
            errorMessage = `${response.status}: ${response.statusText}.`;
        }
        // Throw an error with additional information about the request.
        throw new RequestError(errorMessage, response, errorData);
    }

    // 204 returns no body, catch json error
    if (method === 'DELETE' && response.status === 204) {
        return response.json().catch(() => {
            return {};
        });
    }

    // Response is ok, return attempt to read JSON.
    return response.json();
};

/**
 * Concatenates `mediaPath` with MEDIA_ORIGIN to get a usable URL.
 * @param mediaPath
 */
export const getMediaURL = (mediaPath: string | null) => {
    if (!mediaPath) {
        return '';
    }

    return mediaPath.startsWith('/')
        ? `${MEDIA_ORIGIN}${mediaPath}`
        : `${MEDIA_ORIGIN}/${mediaPath}`;
};
