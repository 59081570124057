import React from 'react';

export interface ISuccessIconProps {
    [key: string]: unknown;
}

export const SuccessIcon: React.FC<ISuccessIconProps> = ({ ...props }) => {
    return (
        <svg
            width='12'
            height='12'
            viewBox='0 0 12 12'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
            {...props}
        >
            <path
                d='M11.5 5.9519C11.5 8.95912 9.04137 11.4038 6 11.4038C2.95863 11.4038 0.5 8.95912 0.5 5.9519C0.5 2.94469 2.95863 0.5 6 0.5C9.04137 0.5 11.5 2.94469 11.5 5.9519Z'
                stroke='black'
            />
            <path
                d='M8.5 4.46387L5.125 7.81164L3.5 5.95184'
                stroke='black'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
        </svg>
    );
};
