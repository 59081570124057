import clsx from 'clsx';
import { FormikValues } from 'formik/dist/types';
import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { z } from 'zod';

import { useOrderContext } from 'lib/api/OrderContext';
import { useStreetContext } from 'lib/api/StreetContext';
import {
    areTooManyVenuesSelected,
    filterOrderLinesByVenues,
    filterOrderLinesWithAmountInOrder,
    getVenuesBySchoolLevelGuid,
    mergeOrderLine,
} from 'lib/api/context';
import { getNextPathByRouteById } from 'lib/routes/routes';
import { useLanguage } from 'lib/routes/useLanguage';
import { useRouteId } from 'lib/routes/useRouteId';
import {
    IExposition,
    IIncompleteOrder,
    IIncompleteOrderLine,
    IOrder,
    IVenue,
    ZIncompleteOrderLine,
    ZOrderLine,
} from 'lib/types/api';

import { Button } from 'components/Button/Button';
import { Form } from 'components/Form/Form';
import { ValidationErrors, ZodValidate } from 'components/Form/utils';
import { Chevron } from 'components/Icons/Chevron/Chevron';
import { Content } from 'components/Layout/Content/Content';
import { H2 } from 'components/Typography/H2/H2';
import { Span } from 'components/Typography/Span/Span';

import { HGroup } from '../../components/Typography/HGroup/HGroup';
import { useZodI18nMap } from '../../lib/i18n/zodI18nMap';
import styles from './Venue.module.css';
import { VenueDateAndTimePicker } from './components/VenueDateAndTimePicker/VenueDateAndTimePicker';
import { VenuePriceGroupAmount } from './components/VenuePriceGroupAmount/VenuePriceGroupAmount';
import { handleGtmClickCheckAvailability, handleGtmClickNext } from './datalayer';

export const FORM_SCHEMA = z.object({
    // Only orderlines of type "venue" should be validated with required keys.
    exposition_orderlines: z.array(
        z.union([
            z.object({
                ...ZOrderLine.shape,
                type: z.literal('venue').optional(),
            }),
            z.object({
                ...ZIncompleteOrderLine.shape,
                type: z.enum(['lesson', 'catering']).optional(),
            }),
        ])
    ),
});

export const Venue = () => {
    const node = useRef<HTMLDivElement>(null);
    const navigate = useNavigate();
    const routeId = useRouteId();
    const language = useLanguage();
    const [street] = useStreetContext();
    const [orderContext, setOrderContext] = useOrderContext();
    const translations = street?.general_text;
    useZodI18nMap(language);

    const [modeState, setModeState] = useState<'venue' | 'date'>('venue');

    const venues: IVenue[] = street
        ? getVenuesBySchoolLevelGuid(street, orderContext?.school_level || '')
        : [];
    const expositions = venues
        .map((v) => v.exposition)
        .filter((v): v is IExposition => Boolean(v));
    const venueOrderLines = filterOrderLinesByVenues(
        orderContext.exposition_orderlines || [],
        venues
    );
    const isVenueSelected = street && venueOrderLines.find((o) => o.amount);

    /**
     * Scrolls to the last <h2/> found within `node.current` when `modeState` changes.
     */
    useEffect(() => {
        const scrollTarget =
            modeState === 'date'
                ? node.current?.querySelector('[role=document]:not(:first-of-type)')
                : document.querySelector('h2');

        scrollTarget?.scrollIntoView({
            behavior: 'smooth',
        });
    }, [modeState]);

    /**
     * Returns whether the `<QuantityField />` in `<VenuePriceGroupAmount />` should be disabled (when setting a value will
     * result in too many venues being selected).
     * @param venue
     */
    const getDisabled = (venue: IVenue): boolean => {
        // 1. Create a fake orderline.
        const tempOrderLine: IIncompleteOrderLine = {
            exposition: venue.exposition?.guid,
            amount: 1,
        };

        // 2. Merge the orderline with values from `orderContext.exposition_orderlines`.
        const tempMergedOrderLines = mergeOrderLine(
            orderContext.exposition_orderlines || [],
            tempOrderLine,
            'exposition'
        );

        // 3. Check whether merged orderLines are considered too many.
        return areTooManyVenuesSelected(tempMergedOrderLines, street?.venues || []);
    };

    /**
     * Gets called when the form is being validated.
     */
    const onFormValidate = (values: IIncompleteOrder): ValidationErrors => {
        const orderLines = values.exposition_orderlines || [];
        const tooManyVenues = areTooManyVenuesSelected(orderLines, venues);

        // Should be handled elsewhere but left as safety.
        if (tooManyVenues) {
            return {};
        }

        // Remove 0 amount orderLines.
        const _values = filterOrderLinesWithAmountInOrder(values);
        setOrderContext({ ...orderContext, ..._values });
        return ZodValidate(FORM_SCHEMA, _values);
    };

    /**
     * Gets called when the form is submitted.
     * @param values
     */
    const onFormSubmit = async (values: FormikValues) => {
        const _values = filterOrderLinesWithAmountInOrder(values);
        const newOrderContext = { ...orderContext, ..._values };
        setOrderContext(newOrderContext);
        if (street) {
            handleGtmClickNext({
                street,
                expositionOrderLines:
                    newOrderContext.exposition_orderlines as IOrder['exposition_orderlines'],
                date: newOrderContext.date || '',
            });
        }
        navigate(getNextPathByRouteById(routeId, { language }) || '');
    };

    /**
     * Gets called when the "check availability" button is clicked.
     */
    const onChangeModeClick = () => {
        const switchTo = modeState === 'venue' ? 'date' : 'venue';
        setModeState(switchTo);
        if (switchTo === 'date' && street) {
            handleGtmClickCheckAvailability({
                expositionOrderLines:
                    orderContext.exposition_orderlines as IOrder['exposition_orderlines'],
                street,
            });
        }
    };

    return (
        <div ref={node} className={styles.venue}>
            <div
                className={clsx(
                    styles.chooseVenueContainer,
                    modeState === 'date' && styles['chooseVenueContainer--disabled']
                )}
            >
                <Form
                    initialValues={orderContext}
                    labelSubmit={translations?.next_step || ''}
                    showSubmit={modeState === 'date'}
                    validate={onFormValidate}
                    onSubmit={onFormSubmit}
                >
                    <Content className={styles.cardsContainer}>
                        <HGroup>
                            <H2 align='center'>{translations?.which_venues}</H2>
                            <Span align='center' size='sm' color='primary'>
                                {translations?.multiple_venues_possible}
                            </Span>
                        </HGroup>
                        {venues?.map((venue) => {
                            return (
                                <VenuePriceGroupAmount
                                    key={venue.slug}
                                    disabled={getDisabled(venue)}
                                    expositions={expositions}
                                    labelAdd={street?.general_text.add || ''}
                                    labelSubtract={street?.general_text.remove || ''}
                                    name='exposition_orderlines'
                                    venue={venue}
                                />
                            );
                        })}
                    </Content>

                    <Button
                        disabled={!isVenueSelected}
                        fontSize='md'
                        type='button'
                        onClick={onChangeModeClick}
                    >
                        {modeState === 'venue'
                            ? translations?.check_availability
                            : translations?.go_back}
                        &nbsp;
                        <Chevron
                            direction={modeState === 'venue' ? 'down' : 'up'}
                        ></Chevron>
                    </Button>

                    {street && modeState === 'date' && (
                        <VenueDateAndTimePicker name='exposition_orderlines' />
                    )}
                </Form>
            </div>
        </div>
    );
};
