import { isMobile, isTablet } from 'react-device-detect';
import { useNavigate } from 'react-router-dom';
import { useEffectOnce } from 'react-use';

import { useStreetContext } from 'lib/api/StreetContext';
import { getNextPathByRouteById } from 'lib/routes/routes';
import { useLanguage } from 'lib/routes/useLanguage';
import { useRouteId } from 'lib/routes/useRouteId';

import { Card } from 'components/Cards/Card/Card';
import { Ticket } from 'components/Icons/Ticket/Ticket';
import { Content } from 'components/Layout/Content/Content';
import { H1 } from 'components/Typography/H1/H1';
import { Span } from 'components/Typography/Span/Span';

import { useZodI18nMap } from '../../lib/i18n/zodI18nMap';
import styles from './Home.module.css';
import { handleGtmClickNext, handleGtmEnterPage } from './datalayer';

export const Home = () => {
    const navigate = useNavigate();
    const language = useLanguage();
    const routeId = useRouteId();
    const [street] = useStreetContext();
    const translations = street?.general_text;
    useZodI18nMap(language);

    useEffectOnce(() => {
        const pageLayout = isMobile ? 'mobile' : isTablet ? 'tablet' : 'desktop';
        handleGtmEnterPage({
            documentReferrer: document.referrer,
            pageLayout: pageLayout,
            languageCode: language,
        });
    });

    return (
        <main className={styles.home}>
            <H1 align='center'>ARTIS</H1>
            <Content>
                <Span bold size={'2xl'}>
                    {translations?.home_title}
                </Span>
                <Span size={'2xl'}>{translations?.home_subtitle}</Span>

                <div className={styles.home__cardContainer}>
                    <Card
                        icon={<Ticket />}
                        text={translations?.new_booking || ''}
                        onClick={() => {
                            navigate(getNextPathByRouteById(routeId, { language }) || '');
                            handleGtmClickNext();
                        }}
                    />
                    {/* <Card
                        icon={<Creditcard />}
                        text={translations?.change_booking || ''}
                    /> */}
                </div>
                <Span size={'sm'}>{translations?.approx_time}</Span>
            </Content>
        </main>
    );
};
