import i18next from 'i18next';
import { z } from 'zod';
import { zodI18nMap } from 'zod-i18n-map';

/**
 * Sets a custom Zod `ErrorMap` with `language` translations from `zodI18nMap`.
 * Zod should show localized error messages (if any) afterwards.
 * @param language
 */
export const useZodI18nMap = async (language: string) => {
    const translation = await import(`zod-i18n-map/locales/${language}/zod.json`);
    i18next.init({
        lng: language,
        resources: {
            [language]: { zod: translation },
        },
    });
    z.setErrorMap(zodI18nMap);
};
