import React from 'react';

export interface IChatIconProps {
    [key: string]: unknown;
}

export const ChatIcon: React.FC<IChatIconProps> = ({ ...props }) => {
    return (
        <svg
            width='24'
            height='23'
            viewBox='0 0 24 23'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
            {...props}
        >
            <g clipPath='url(#clip0_1927_128)'>
                <path
                    d='M4.85591 15.9022C3.78431 14.0957 3.40902 11.9602 3.80053 9.89662C4.19203 7.83305 5.32339 5.98337 6.98215 4.69492C8.64092 3.40647 10.713 2.76788 12.8093 2.89906C14.9056 3.03024 16.8818 3.92217 18.367 5.40736C19.8522 6.89255 20.7442 8.86884 20.8754 10.9651C21.0066 13.0614 20.368 15.1335 19.0795 16.7923C17.7911 18.451 15.9414 19.5824 13.8779 19.9739C11.8143 20.3654 9.67876 19.9901 7.8723 18.9186L7.87232 18.9185L4.89341 19.7696C4.77016 19.8048 4.63974 19.8064 4.51566 19.7743C4.39158 19.7421 4.27835 19.6774 4.18771 19.5867C4.09708 19.4961 4.03233 19.3829 4.00018 19.2588C3.96802 19.1347 3.96964 19.0043 4.00485 18.881L4.85597 15.9021L4.85591 15.9022Z'
                    stroke='black'
                    strokeWidth='1.6'
                    strokeLinecap='round'
                    strokeLinejoin='round'
                />
            </g>
            <defs>
                <clipPath id='clip0_1927_128'>
                    <rect
                        width='23'
                        height='23'
                        fill='white'
                        transform='translate(0.774414)'
                    />
                </clipPath>
            </defs>
        </svg>
    );
};
