import { FormikValues } from 'formik/dist/types';
import React, { useEffect, useState } from 'react';
import { useDebounce } from 'react-use';
import useSWR from 'swr';

import { searchSchoolsByPostalCode } from 'lib/api/api';
import { inputChangeEventFactory } from 'lib/events/eventFactory';
import { useLanguage } from 'lib/routes/useLanguage';
import { ISchool, ZSchoolSchema } from 'lib/types/api';

import { Button } from 'components/Button/Button';
import { Checkbox } from 'components/Form/Checkbox/Checkbox';
import { InputField } from 'components/Form/InputField/InputField';
import { IOption, SelectField } from 'components/Form/SelectField/SelectField';
import { Plus } from 'components/Icons/Plus/Plus';
import { Spinner } from 'components/Spinner/Spinner';

import { Form } from '../../../../components/Form/Form';
import { ZodValidate } from '../../../../components/Form/utils';
import styles from './SchoolSelect.module.css';

export type ComplexSchoolSelectErrors = Record<
    'name' | 'street' | 'number' | 'postal_code' | 'city' | 'country' | 'phone' | 'guid',
    string
>;

export interface ISchoolSelectProps {
    /** The country options. */
    countryOptions: IOption[];

    /** The label for the street field. */
    labelAddressStreet: string;

    /** The label for the number field. */
    labelAddressNumber: string;

    /** The label for the city field. */
    labelAddressCity: string;

    /** The label for the city field. */
    labelAddressCountry: string;

    /** The add school button label. */
    labelAddSchool: string;

    /** The back button label. */
    labelBack: string;

    /** Aria-label for button without text. */
    labelClear: string;

    /** Shown when no options are found given the string. */
    labelNoOptions: string;

    /** The label for the phone field. */
    labelPhone: string;

    /** The label for the postal code field. */
    labelPostalCode: string;

    /** The save button label. */
    labelSave: string;

    /** The label for the school name  field. */
    labelSchoolName: string;

    /** The name for the selected school field. */
    name: string;

    /**
     * The error messages to display. Can be either an Object with individual error messages (nested keys) or a string
     * for the "postal_code" field.
     * */
    errorMessage?: string | ComplexSchoolSelectErrors;

    /** onChange callback. */
    onChange?: (
        event: React.ChangeEvent | CustomEvent,
        school: ISchool | undefined
    ) => void;

    /** The value. */
    value?: ISchool | undefined;

    /** Remaining props get passed to `<InputField />`. */
    [index: string]: unknown;
}

/**
 * A smart component that allows the user to search for (by postal code) and select a school.
 * If school is not found this component allows the user to manually input the school data.
 * This component can be used like any other form capable field, it will use an `ISchool` as value.
 */
export const SchoolSelect: React.FC<ISchoolSelectProps> = ({
    countryOptions,
    errorMessage,
    labelAddressStreet,
    labelAddressNumber,
    labelAddressCity,
    labelAddressCountry,
    labelAddSchool,
    labelBack,
    labelClear,
    labelNoOptions,
    labelPhone,
    labelPostalCode,
    labelSave,
    labelSchoolName,
    name,
    onChange,
    value,
    ...props
}) => {
    const inputName = name;
    const primitiveErrorMessage = typeof errorMessage === 'string' ? errorMessage : '';
    const complexErrorMessages = (
        Object(errorMessage) === errorMessage ? errorMessage : {}
    ) as ComplexSchoolSelectErrors;

    const language = useLanguage();
    const [pristineState, setPristineState] = useState<boolean>(true);
    const [valueState, setValueState] = useState<string>('');
    const [queryState, setQueryState] = useState<string>('');
    const [activeSchoolState, setActiveSchoolState] = useState<ISchool | undefined>(
        value
    );
    const [modeState, setModeState] = useState<'search' | 'add'>('search');
    const { data, error, isLoading } = useSWR([language, queryState], ([l, q]) =>
        searchSchoolsByPostalCode(l, q)
    );

    const schoolTemplate: ISchool = {
        name: '',
        street: '',
        number: '',
        postal_code: '',
        city: '',
        country: '',
        phone: '',
        guid: '',
    };

    /**
     * Callback get called 300ms after last `valueState` change.
     */
    useDebounce(async () => setQueryState(valueState), 300, [valueState]);

    /**
     * Dispatches change event when `activeSchoolState` changes.
     * @event #change Change event.
     */
    useEffect(() => {
        if (pristineState) {
            return;
        }
        const event = inputChangeEventFactory(
            inputName,
            activeSchoolState as unknown as Record<string, string>
        );
        onChange && onChange(event, activeSchoolState);
    }, [activeSchoolState]);

    /**
     * Returns First of: `[activeSchoolState]`, `exactMatches`, `data` or `[]`.
     */
    const getResults = (): ISchool[] => {
        if (!data) {
            return activeSchoolState ? [activeSchoolState] : [];
        }

        if (activeSchoolState) {
            const dataResults = data.filter((s) => s.guid === activeSchoolState.guid);
            return dataResults.length > 0 ? dataResults : [activeSchoolState];
        }

        const exactMatches = data.filter(
            (s) => s.postal_code.toLowerCase() === queryState.toLowerCase()
        );

        if (exactMatches.length) {
            return exactMatches;
        }
        return data.slice(0, 3); // Limit to 3 items max when no exact match is found.
    };

    /**
     * Returns whether the postal code is invalid (based on errorMessage).
     */
    const isPostalCodeInValid = () => {
        // primitiveErrorMessage always applies to postal code, assume postal code invalid.
        if (primitiveErrorMessage) {
            return true;
        }

        // complexErrorMessages.postal_code is set, assume postal coe invalid.
        if (complexErrorMessages.postal_code) {
            return true;
        }
    };

    /**
     * `<InputField />` change callback.
     */
    const onQueryFieldChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
        setActiveSchoolState(undefined);
        setValueState(e.target.value);
        setPristineState(false);
    };

    /**
     * `<Checkbox />` change callback.
     */
    const onCheckboxChange = (e: React.ChangeEvent, school: ISchool) => {
        setActiveSchoolState(activeSchoolState ? undefined : school);
        setPristineState(false);
    };

    /**
     * `<InputField />` change callback for attributes (when adding school).
     */
    const onAttributeFieldChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
        const { name, value } = e.target;
        const school = {
            ...schoolTemplate,
            postal_code: name === 'postal_code' ? value : valueState,
            ...(activeSchoolState || {}),
            [name]: value,
        };
        setValueState(school.postal_code);
        setActiveSchoolState(school);
        setPristineState(false);
    };

    /**
     * `<Select />` change callback (when selecting country).
     */
    const onCountyChange = (e: CustomEvent<IOption>) => {
        const option = e.detail;
        const school = {
            ...schoolTemplate,
            postal_code: valueState,
            ...(activeSchoolState || {}),
            country: option.value,
        };
        setActiveSchoolState(school);
        setPristineState(false);
    };

    const onFormValidate = (values: FormikValues) => {
        setPristineState(false);
        return ZodValidate(ZSchoolSchema, {
            ...schoolTemplate,
            postal_code: valueState,
            ...values,
        });
    };

    const onFormSubmit = (
        values: FormikValues,
        {
            setSubmitting,
        }: {
            setSubmitting: (isSubmitting: boolean) => void;
        }
    ) => {
        const school = {
            ...schoolTemplate,
            postal_code: valueState,
            ...values,
        };
        setActiveSchoolState(school);
        setModeState('search');
        setSubmitting(false);
    };

    /**
     * `<Button />` click callback for back button.
     */
    const onFormCancel = () => {
        setModeState('search');
        setActiveSchoolState(undefined);
        setPristineState(false);
    };

    /**
     * `<Button />` click callback (when adding school).
     */
    const onAddButtonClick = () => {
        setModeState('add');
        setActiveSchoolState({
            ...schoolTemplate,
            postal_code: valueState,
        });
        setPristineState(false);
    };

    const checkShowAddSchoolButton = (): boolean => {
        if (valueState.length < 4) return false;
        if (valueState.length > 3 && (!isLoading || (data && data.length === 0)))
            return true;
        return false;
    };

    return (
        <fieldset className={styles.schoolselect}>
            <InputField
                {...props}
                errorMessage={
                    error?.message ||
                    complexErrorMessages.postal_code ||
                    primitiveErrorMessage
                }
                invalid={error || isPostalCodeInValid()}
                label={labelPostalCode}
                name='postal_code'
                onChange={
                    modeState === 'search' ? onQueryFieldChange : onAttributeFieldChange
                }
                value={value?.postal_code}
            />

            {modeState === 'search' && (
                <div aria-live='polite' aria-busy={isLoading}>
                    {isLoading && <Spinner />}
                    {getResults().length > 0 && (
                        <ul className={styles.schoolselect__list}>
                            {getResults().map((school) => {
                                const { city, guid, name, number, postal_code, street } =
                                    school;
                                return (
                                    <li key={guid} className={styles.schoolselect__item}>
                                        <Checkbox
                                            checked={activeSchoolState?.guid === guid}
                                            description={`
                                    ${street} ${number}
                                    ${postal_code} ${city}
                                `}
                                            label={name}
                                            name={inputName}
                                            onChange={(e) => onCheckboxChange(e, school)}
                                            value={guid}
                                        ></Checkbox>
                                    </li>
                                );
                            })}
                        </ul>
                    )}

                    <div className={styles.schoolselect__actions}>
                        {checkShowAddSchoolButton() && (
                            <Button fill={false} fontSize='lg' onClick={onAddButtonClick}>
                                <Plus />
                                &nbsp;{labelAddSchool}
                            </Button>
                        )}
                    </div>
                </div>
            )}

            {modeState === 'add' && (
                <Form
                    display='control'
                    validate={onFormValidate}
                    onSubmit={onFormSubmit}
                    onCancel={onFormCancel}
                    labelSubmit={labelSave}
                    showCancel={true}
                    labelCancel={labelBack}
                >
                    <div className={styles.schoolselect__fields}>
                        <InputField
                            label={labelSchoolName}
                            name='name'
                            onChange={onAttributeFieldChange}
                            value={value?.name}
                        />
                        <InputField
                            label={labelAddressStreet}
                            name='street'
                            onChange={onAttributeFieldChange}
                            value={value?.street}
                        />
                        <InputField
                            label={labelAddressNumber}
                            name='number'
                            onChange={onAttributeFieldChange}
                            value={value?.number}
                        />
                        <InputField
                            label={labelAddressCity}
                            name='city'
                            onChange={onAttributeFieldChange}
                            value={value?.city}
                        />
                        <SelectField
                            isSearchable={true}
                            label={labelAddressCountry}
                            labelClear={labelClear}
                            labelNoOptions={labelNoOptions}
                            name='country'
                            options={countryOptions}
                            onChange={(e) => onCountyChange(e)}
                            value={value?.country || null}
                        />
                        <InputField
                            label={labelPhone}
                            name='phone'
                            onChange={onAttributeFieldChange}
                            value={value?.phone}
                        />
                    </div>
                </Form>
            )}
        </fieldset>
    );
};
