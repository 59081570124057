import clsx from 'clsx';
import React, { useId, useState } from 'react';
import Select, {
    ClearIndicatorProps,
    DropdownIndicatorProps,
    OptionProps,
    PlaceholderProps,
    ValueContainerProps,
    components,
} from 'react-select';

import { Spinner } from 'components/Spinner/Spinner';

import { inputChangeEventFactory } from '../../../lib/events/eventFactory';
import { CloseButton } from '../../CloseButton/CloseButton';
import { Chevron } from '../../Icons/Chevron/Chevron';
import { Span } from '../../Typography/Span/Span';
import { FormError } from '../FormError/FormError';
import { ILabeledFieldProps } from '../IFormFieldProps';
import styles from './SelectField.module.css';

export interface IOption {
    /** The (human-readable) label of an option. */
    label: string;

    /** The (machine-readable) value of an option */
    value: string;
}

export interface ISelectFieldProps
    extends ILabeledFieldProps<string | null, CustomEvent> {
    /** Aria-label for button without text. */
    labelClear: string;

    /** Shown when no options are found given the string. */
    labelNoOptions: string;

    /** The options the user can choose from. */
    options: IOption[];

    /** Whether the loading indicator should be shown. */
    isLoading?: boolean;

    /** Whether the field's options should be searchable by text input. */
    isSearchable?: boolean;

    /** Same as `label`, but bold. If both `label` and `labelBold` are set: both are shown separated by a whitespace. (" "). */
    labelBold?: string;
}

export const SelectField: React.FC<ISelectFieldProps> = ({
    disabled,
    errorMessage,
    id,
    invalid,
    isSearchable = false,
    label,
    labelBold,
    labelClear,
    labelNoOptions,
    name,
    onChange,
    options,
    required,
    value,
    isLoading,
    ...props
}) => {
    const fieldId = id || useId();
    const errorId = invalid ? `${fieldId}-errormessage` : undefined;
    const labelId = `${fieldId}-label`;
    const [, setPristineState] = useState<boolean>(true);
    const valueOption = options.find((o) => o.value === value) || null;

    /**
     * Translates react-select onChange event to native-like onChange event.
     * @param newValue The selected option.
     * @event CustomEvent#change Custom change event.
     */
    const onSelectChange = (newValue: IOption | null) => {
        const { value } = newValue ? newValue : { value: '' };
        const event = inputChangeEventFactory(name || '', value);
        // Taiga: #2635 - Onderwijsstraat: op mobiel School-niveau niet te selecteren?
        // Sv: seems to be some sort of race condition causing incorrect data to be picked up.
        // Possibly another event triggering from react-select?
        // ¯\_(ツ)_/¯
        setTimeout(() => {
            onChange && onChange(event);
            setPristineState(false);
        });
    };

    const classNames = {
        container: ({
            isDisabled,
            isFocused,
        }: {
            isDisabled: boolean;
            isFocused: boolean;
        }) => {
            return clsx(styles['selectfield__select-container'], {
                [styles['selectfield__select-container--disabled']]: isDisabled,
                [styles['selectfield__select-container--focused']]: isFocused,
                [styles['selectfield__select-container--invalid']]: invalid,
            });
        },
        control: () => styles['selectfield__select-control'],
        valueContainer: () => styles['selectfield__select-value-container'],
        input: () => styles['selectfield__select-input'],
        menu: () => styles['selectfield__select-menu'],
        menuList: () => styles['selectfield__select-menu-list'],
        menuPortal: () => styles['selectfield__select-menu-portal'],
        indicatorSeparator: () => styles['selectfield__select-indicator-separator'],
        indicatorsContainer: () => styles['selectfield__select-indicators-container'],
        dropdownIndicator: () => styles['selectfield__select-dropdown-indicator'],
        clearIndicator: () => styles['selectfield__select-clear-indicator'],
        loadingIndicator: () => styles['selectfield__select-loading-indicator'],
        placeholder: () => styles['selectfield__select-placeholder'],
        option: ({
            isFocused,
            isSelected,
        }: {
            isFocused: boolean;
            isSelected: boolean;
        }) => {
            return clsx(styles['selectfield__select-option'], {
                [styles['selectfield__select-option--focused']]: isFocused,
                [styles['selectfield__select-option--selected']]: isSelected,
            });
        },
        groupHeading: () => styles['selectfield__select-group-heading'],
        singleValue: () => styles['selectfield__select-single-value'],
        multiValue: () => styles['selectfield__select-multi-value'],
        multiValueLabel: () => styles['selectfield__select-multi-value-label'],
        multiValueRemove: () => styles['selectfield__select-multi-value-remove'],
    };

    const components = {
        ClearIndicator: ({ innerProps }: ClearIndicatorProps) => {
            const { onMouseDown } = innerProps;
            const _onClick = (e: any) => {
                onMouseDown && onMouseDown(e as unknown as any);
            };

            return (
                <CloseButton
                    label={labelClear}
                    onClick={_onClick as any}
                    {...innerProps}
                />
            );
        },
        DropdownIndicator: ({ selectProps }: DropdownIndicatorProps) => (
            <Chevron direction={selectProps.menuIsOpen ? 'down' : 'up'} />
        ),
        Option: SelectOption,
        ValueContainer: SelectValueContainer as React.ComponentType<ValueContainerProps>,
    };

    return (
        <div
            className={styles.selectfield}
            {...props}
            aria-live='polite'
            aria-busy={isLoading}
        >
            <label id={labelId} htmlFor={fieldId}>
                <Select
                    aria-errormessage={errorId}
                    aria-invalid={invalid ? 'true' : 'false'}
                    aria-labelledby={labelId}
                    aria-live='polite'
                    aria-busy={isLoading ? 'true' : 'false'}
                    classNames={classNames}
                    components={components}
                    id={fieldId}
                    isLoading={isLoading}
                    loadingMessage={() => <Spinner />}
                    isClearable={!required}
                    isDisabled={disabled}
                    isSearchable={isSearchable}
                    name={name}
                    noOptionsMessage={() => <Span size='xs'>{labelNoOptions}</Span>}
                    onChange={(value) => onSelectChange(value as IOption)}
                    options={options}
                    placeholder={
                        <>
                            {label && <span>{label}</span>}
                            {label && labelBold && ' '}
                            {labelBold && <strong>{labelBold}</strong>}
                        </>
                    }
                    required={required}
                    value={valueOption}
                />
            </label>
            {invalid && <FormError id={errorId as string}>{errorMessage}</FormError>}
        </div>
    );
};

const { ValueContainer, Placeholder } = components;
const SelectValueContainer: React.FC<ValueContainerProps & PlaceholderProps> = ({
    children,
    ...props
}) => {
    return (
        <ValueContainer {...props}>
            {React.Children.map(children, (child) => {
                return child && (child as React.ReactElement).type !== Placeholder
                    ? child
                    : null;
            })}
            <Placeholder {...props} isFocused={props.isFocused}>
                {props.selectProps.placeholder}
                {props.selectProps.required ? '*' : ''}
            </Placeholder>
        </ValueContainer>
    );
};

const SelectOption: React.FC<OptionProps> = ({ children, data, ...props }) => {
    return (
        <components.Option data={data} {...props}>
            {children}
        </components.Option>
    );
};
