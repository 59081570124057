import React from 'react';

import styles from './Hr.module.css';

export interface IHrProps {
    [index: string]: unknown;
}

export const Hr: React.FC<IHrProps> = ({ ...props }) => {
    return <hr className={styles.hr} {...props} />;
};
