import { ExpositionTimeSlotPicker } from 'containers/ExpositionTimeSlotPicker/ExpositionTimeSlotPicker';
import React from 'react';

import { useOrderContext } from 'lib/api/OrderContext';
import { useStreetContext } from 'lib/api/StreetContext';
import { getMediaURL } from 'lib/api/api';
import { mergeOrderLine } from 'lib/api/context';
import { inputChangeEventFactory } from 'lib/events/eventFactory';
import { formatDate } from 'lib/format/date';
import { IIncompleteOrderLine, IOrderLine, IStreetData } from 'lib/types/api';

import { ProductCard } from 'components/Cards/ProductCard/ProductCard';
import { QuantityField } from 'components/Form/QuantityField/QuantityField';
import { IOption } from 'components/Form/SelectField/SelectField';
import { Span } from 'components/Typography/Span/Span';

export interface ICateringArticleProductCardsProps {
    name: string;
    value?: Array<IOrderLine | IIncompleteOrderLine>;
    onChange?: (e: CustomEvent) => void;
}

/**
 * Simulates (and behaves as) a "form capable field" by utilizing an input-like interface (`name`, `value`, `onChange`).
 * Receives `Array<IOrderLine | IIncompleteOrderLine>` as value and calls `onChange` with updated orderlines on change.
 * @see `Form` component
 * @param name The name to use (as if this component was a form field).
 * @param value The order lines as value of the simulated form field (possibly passed by `Form`).
 * @param onChange The onChange handles (possibly passed by `Form`).
 */
export const CateringArticleProductCards: React.FC<ICateringArticleProductCardsProps> = ({
    name,
    value = [],
    onChange,
}) => {
    const [street] = useStreetContext() as [IStreetData, unknown];
    const translations = street.general_text;
    const [orderContext] = useOrderContext();

    /**
     * Gets called when a quantity is selected.
     * @param e
     */
    const onQuantityFieldChange = (e: CustomEvent<{ name: string; value: number }>) => {
        const orderLine: IIncompleteOrderLine = {
            amount: e.detail.value,
            exposition: street.catering_exposition_id,
            exposition_price: e.detail.name,
            type: 'catering',
        };
        const mergedOrderLines = mergeOrderLine(value, orderLine, 'exposition_price');

        const event = inputChangeEventFactory(name, mergedOrderLines);
        onChange && onChange(event);
    };

    /**
     * Gets called when a time slot is selected.
     * @param e
     */
    const onExpositionTimeSlotPickerChange = (e: CustomEvent<IOption | null>) => {
        const incompleteOrderLine: IIncompleteOrderLine = {
            exposition: street.catering_exposition_id,
            exposition_period_guid: e.detail?.value ? e.detail.value : undefined,
            type: 'catering',
        };

        const mergedOrderLines = mergeOrderLine(value, incompleteOrderLine, 'exposition');

        const event = inputChangeEventFactory(name, mergedOrderLines);
        onChange && onChange(event);
    };

    return (
        <>
            {street.catering_articles.map((c) => (
                <ProductCard
                    key={c.guid}
                    description={c.description}
                    image={getMediaURL(c.image)}
                    labelInfo={translations?.click_more_info || ''}
                    labelClose={'close'}
                    price={Number(c.price)}
                    title={c.title}
                >
                    <QuantityField
                        labelAdd={translations?.add || ''}
                        labelSubtract={translations?.remove || ''}
                        name={c.guid}
                        value={
                            value.find((o) => o.exposition_price === c.guid)?.amount || 0
                        }
                        onChange={(e) => onQuantityFieldChange(e)}
                    ></QuantityField>
                </ProductCard>
            ))}

            <ExpositionTimeSlotPicker
                date={formatDate(
                    orderContext.date ? new Date(orderContext.date) : new Date()
                )}
                expositionGuid={street.catering_exposition_id}
                onChange={onExpositionTimeSlotPickerChange}
                label={translations.pickup_time}
                orderLines={value}
                value={
                    orderContext.exposition_orderlines?.find(
                        (o) => o.exposition === street.catering_exposition_id
                    )?.exposition_period_guid
                }
            />

            <Span align='center' color='gray' size='sm'>
                {translations.pickup_location}
            </Span>
        </>
    );
};
