import { gtmDataLayer } from '../../lib/utils/useGtm';

interface IHandleGtmEnterPageProps {
    documentReferrer: string;
    pageLayout: 'desktop' | 'mobile' | 'tablet';
    languageCode: string;
}

export const handleGtmEnterPage = ({
    documentReferrer,
    pageLayout,
    languageCode,
}: IHandleGtmEnterPageProps) => {
    // TODO: We should probably ask artis if they want the actual document.referrer or if we want to do this check instead
    // Our current implementation has the downside of missing possible referrers that are not in the list but are still relevant
    const getPageReferrer = () => {
        if (documentReferrer.includes('micropia')) {
            return 'micropia';
        } else if (documentReferrer.includes('grootemuseum')) {
            return 'grootemuseum';
        } else if (documentReferrer.includes('artis')) {
            return 'artis';
        } else {
            return documentReferrer;
        }
    };

    const pageReferrer = getPageReferrer();

    const dataLayer = {
        event: 'page_view',
        page_type: 'checkout',
        platform: 'web',
        page_referrer: pageReferrer,
        page_layout: pageLayout,
        environment: 'prod',
        platform_language_code: languageCode,
    };

    gtmDataLayer({
        dataLayer,
    });
};

export const handleGtmClickNext = () => {
    const dataLayer = {
        event: 'checkout',
        checkout_funnel_name: 'ticketstraat_onderwijs',
        checkout_stage_number: '1',
        checkout_stage_name: 'checkout_start',
    };
    gtmDataLayer({
        dataLayer,
    });
};
