import React from 'react';

import { ButtonLink } from '../../components/Button/ButtonLink';
import { Content } from '../../components/Layout/Content/Content';
import { H2 } from '../../components/Typography/H2/H2';
import { HGroup } from '../../components/Typography/HGroup/HGroup';
import { Span } from '../../components/Typography/Span/Span';
import { useStreetContext } from '../../lib/api/StreetContext';
import styles from './ChangeConfirmation.module.css';

export const ChangeConfirmation: React.FC = () => {
    const [street] = useStreetContext();
    const translations = street?.general_text;

    return (
        <main className={styles.changeConfirmation}>
            <Content size='medium'>
                <HGroup>
                    <H2 align='center' size='6xl'>
                        {translations?.order_changed}
                    </H2>
                    <Span align='center' size='3xl'>
                        {translations?.successful_order_change}
                    </Span>
                </HGroup>
                <ButtonLink href={'/'} fontSize='md'>
                    {translations?.go_back_to_homepage}
                </ButtonLink>
            </Content>
        </main>
    );
};
