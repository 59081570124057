import React from 'react';

import { Button } from '../Button/Button';
import { Close } from '../Icons/Close/Close';
import styles from './CloseButton.module.css';

export interface ICloseButtonProps {
    label: string;

    /** onClick callback. */
    onClick?: (e: React.SyntheticEvent) => void;
}

/**
 * Simple generic close button, can be used to dismiss UI elements like modals.
 */
export const CloseButton: React.FC<ICloseButtonProps> = ({
    label,
    onClick = () => undefined,
    ...props
}) => {
    return (
        <Button
            className={styles.closebutton}
            aria-label={label}
            title={label}
            onClick={onClick}
            {...props}
        >
            <Close />
        </Button>
    );
};
